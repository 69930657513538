import { ChangeEventHandler } from "react";

type SettingCheckboxProps = {
  label: string;
  checked: boolean;
  trigger: ChangeEventHandler<HTMLInputElement>;
}

export default function SettingCheckbox({label, checked, trigger}: SettingCheckboxProps) {
  return (
    <label className="flex items-center mx-2">
      <input
        type="checkbox"
        className="form-checkbox border border-teal-500 rounded-md w-6 h-6 mr-1 accent-teal-200"
        checked={checked}
        onChange={trigger}
      />
      <span>{ label }</span>
    </label>
  );
}
