import { User } from "firebase/auth";

type GetRevisionsParamsType = {
  user: User | null;
  baseId: string;
}

export async function getRevisions(params: GetRevisionsParamsType) {
  const {user, baseId} = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/revisions/?base=${baseId}`,
    {
      headers: {
        "Authorization": "" + token,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't fetch revision list");
  }

  return response.json();
}

type DuplicateRevisionParamsType = {
  user: User | null;
  baseId: string | undefined;
  revisionId: string;
};

export async function duplicateRevision(params: DuplicateRevisionParamsType) {
  const { user, baseId, revisionId } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/revisions/${revisionId}/duplicate/?base=${baseId}`,
    {
      method: "POST",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't create turf");
  }

  return response.json();
}

type SetAsDefaultRevisionParamsType = {
  user: User | null;
  baseId: string | undefined;
  revisionId: string;
};

export async function setAsDefaultRevision(params: SetAsDefaultRevisionParamsType) {
  const { user, baseId, revisionId } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/revisions/${revisionId}/set_as_default/?base=${baseId}`,
    {
      method: "PATCH",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't set default revision");
  }

  return response.json();
}

type DeleteRevisionParamsType = {
  user: User | null;
  baseId: string | undefined;
  revisionId: string;
};

export async function deleteRevision(params: DeleteRevisionParamsType) {
  const { user, baseId, revisionId } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/revisions/${revisionId}/?base=${baseId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't delete revision");
  }
}
