export const VALUE_STREAM_TYPE_PRODUCT: number = 1;
const VALUE_STREAM_TYPE_SERVICE: number = 2;
const VALUE_STREAM_TYPE_EVENT: number = 3;
const VALUE_STREAM_TYPE_PROJECT: number = 4;

export const VALUE_STREAM_TYPES = [
  { id: VALUE_STREAM_TYPE_PRODUCT, name: "Product" },
  { id: VALUE_STREAM_TYPE_SERVICE, name: "Service" },
  { id: VALUE_STREAM_TYPE_EVENT, name: "Event" },
  { id: VALUE_STREAM_TYPE_PROJECT, name: "Project" },
];

export const valueStream_type_attributes = {
  [VALUE_STREAM_TYPE_PRODUCT]: {
    color: "#920404",
    icon: "product.svg",
    label: "Product",
    link: "https://unfix.com/product-value-stream",
  },
  [VALUE_STREAM_TYPE_SERVICE]: {
    color: "#4B9204",
    icon: "service.svg",
    label: "Service",
    link: "https://unfix.com/service-value-stream",
  },
  [VALUE_STREAM_TYPE_EVENT]: {
    color: "#049696",
    icon: "event.svg",
    label: "Event",
    link: "https://unfix.com/event-value-stream",
  },
  [VALUE_STREAM_TYPE_PROJECT]: {
    color: "#4E0492",
    icon: "project.svg",
    label: "Project",
    link: "https://unfix.com/project-value-stream",
  },
};
