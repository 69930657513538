const INVESTMENT_HORIZON_MAINTENANCE: number = 1;
const INVESTMENT_HORIZON_IMPROVEMENT: number = 2;
const INVESTMENT_HORIZON_EXTENSION: number = 3;
const INVESTMENT_HORIZON_DISRUPTION: number = 4;

export const INVESTMENT_HORIZONS = [
  { id: INVESTMENT_HORIZON_MAINTENANCE, name: "Maintenance" },
  { id: INVESTMENT_HORIZON_IMPROVEMENT, name: "Improvement" },
  { id: INVESTMENT_HORIZON_EXTENSION, name: "Extension" },
  { id: INVESTMENT_HORIZON_DISRUPTION, name: "Disruption" },
];

export const investment_horizon_attributes = {
  [INVESTMENT_HORIZON_MAINTENANCE]: {
    icon: "investment_horizon_maintenance.png",
    label: "Maintenance",
    link: "https://unfix.com/maintenance",
  },
  [INVESTMENT_HORIZON_IMPROVEMENT]: {
    icon: "investment_horizon_improvement.png",
    label: "Improvement",
    link: "https://unfix.com/improvement",
  },
  [INVESTMENT_HORIZON_EXTENSION]: {
    icon: "investment_horizon_extension.png",
    label: "Extension",
    link: "https://unfix.com/extension",
  },
  [INVESTMENT_HORIZON_DISRUPTION]: {
    icon: "investment_horizon_disruption.png",
    label: "Disruption",
    link: "https://unfix.com/disruption",
  },
};
