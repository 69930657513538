import { User } from "firebase/auth";
import { DBForumType, TeamMemberType } from "../../../../../common/types";
import { shadeHexColor } from "../../../../../lib/pSBC";
import { forum_type_attributes } from "../../../../../common/constants/forumTypes";
import useForumMemberApi from "../../../common/hooks/useForumMemberApi";
import { useDroppable } from "@dnd-kit/core";
import Spinner from "../../../../baseList/Spinner";
import TeamMemberBubble from "./TeamMemberBubble";

type ForumDroppableProps = {
  user: User | null;
  baseId: string;
  forum: DBForumType;
};

export default function ForumDroppable({ user, baseId, forum }: ForumDroppableProps) {

  
  const { forumMembersQuery, createForumMemberMutation, addForumMember, removeForumMember } = useForumMemberApi({ baseId, user, forumId: forum.id, forumMembersGroupBy: "" })
  const {isOver, setNodeRef} = useDroppable({
    id: forum.id,
    data: {
      addTeamMember: addForumMember,
    },
  });

  const bgColor =
    forum.type in forum_type_attributes
      ? forum_type_attributes[forum.type]["color"]
      : "";

  const borderColor = isOver ? "green" : shadeHexColor(bgColor, -0.1);

  return (
    <div ref={setNodeRef} className={"p-1 w-full"}>
      <div className="p-1 flex flex-col items-center border-2 rounded-md" style={{ borderColor: borderColor, backgroundColor: bgColor }}>
        <div className="w-full flex items-center">
          {createForumMemberMutation.isLoading && <Spinner width="w-8" height="h-8" />}
          {forum.name}</div>
        <div className="w-full flex items-center">
          {forumMembersQuery.data?.map((member: TeamMemberType) =>
            <TeamMemberBubble
              key={member.id}
              member={member}
              borderColor={borderColor}
              bgColor={bgColor}
              deleteMember={removeForumMember}
            />
          )}
        </div>
      </div>
    </div>
  )
}
