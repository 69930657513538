import { Formik, Field, Form, ErrorMessage } from "formik";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { FetchedBaseType } from "../../../../common/types";
import { LIFECYCLE_STAGES } from "../../../../common/constants/lifecycleStages";

type RevisionFormPropsType = {
  revision: FetchedBaseType;
  setEditingRevision: (r: null) => void;
  editItem: (revision: FetchedBaseType) => void;
}

type FormErrorsType = {
  revision_name?: string;
  lifecycle_stage?: number;
}

export default function RevisionEditForm({ revision, setEditingRevision, editItem }: RevisionFormPropsType) {

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={revision}
        validate={values => {
          const errors: FormErrorsType = {};
          if (!values.name) {
            errors.revision_name = 'Name is required';
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          // console.log("submit", values);
          editItem(values);
          actions.setSubmitting(false);
          setEditingRevision(null);
          // actions.resetForm();
        }}
      >
        <Form className="flex flex-col border-t mt-2 pt-2">
          <div className="w-full flex items-center justify-between">
            <h2 className="text-xl py-2">Edit revision</h2>
            <XMarkIcon onClick={() => setEditingRevision(null)} className="w-6 h-6" />
          </div>
          <Field type="hidden" name="id" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="revision_name">Name</label>
            <Field name="revision_name" placeholder="Revision name" className="w-full border rounded-md p-1 my-2" />
          </div>
          <ErrorMessage className="text-red-700" name="name" component="div" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="lifecycle_stage">Lifecycle Stage</label>
            <Field name="lifecycle_stage" as="select" className="w-full border rounded-md p-1 my-2">
              <option value={0}>None</option>
              {LIFECYCLE_STAGES.map((lifecycleStage) => <option key={lifecycleStage.id} value={lifecycleStage.id}>{lifecycleStage.name}</option>)}
            </Field>
          </div>
          <ErrorMessage className="text-red-700" name="lifecycle_stage" component="div" />
          <button type="submit" className="p-2 px-4 my-2 bg-teal-500 text-white rounded-md">Save</button>
          <button
            type="button"
            className="p-2 px-4 my-2 border border-teal-500 tex-teal-500 rounded-md"
            onClick={(e) => {e.preventDefault(); setEditingRevision(null)}}
          >
            Cancel
          </button>
        </Form>
      </Formik>
    </div>
  );
}
