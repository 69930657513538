import { Circle, Text } from "react-konva";
import { DBCrewType, TeamMemberType } from "../../../../common/types"
import { getInitials } from "../../../../lib/textTransform";
import { Fragment, useEffect, useState } from "react";
import { LEADER_ROLES, participationLevel_attributes, timeCommitment_attributes } from "../../editor/teamMembers/TeamMember";

import MemberAvatar from "../common/MemberAvatar";
import { KonvaEventObject } from "konva/lib/Node";

type HorizontalCrewMembersProps = {
  crew: DBCrewType;
  members: TeamMemberType[];
  baseX: number;
  handleMemberHover: (e: KonvaEventObject<MouseEvent>, member: TeamMemberType) => void;
  handleMemberHoverOut: () => void;
}

export default function HorizontalCrewMembers({ crew, members, baseX, handleMemberHover, handleMemberHoverOut }: HorizontalCrewMembersProps) {
  const cellWidth = 42;
  const cellRadius = cellWidth / 2
  const roleCircleWidth = cellWidth - 4;
  const participationLevelCircleWith = roleCircleWidth - 4;
  const timeCommitMentCircleWidth = participationLevelCircleWith - 4;

  const [offset, setOffset] = useState(cellWidth);
  const [rows, setRows] = useState(3);

  useEffect(() => {
    setRows(
      Math.min(
        Math.floor((crew.measurements.height - 10) / (cellWidth)),
        members.length
      )
    );
  }, [crew.measurements.height, members.length])

  if (crew.measurements.height < 50) {
    return (<></>);
  }

  const hasLeaderRole = (member: TeamMemberType) => {
    if (member.unfixRoles) {
      for (let role of member.unfixRoles) {
        if (role in LEADER_ROLES) {
          return true;
        }
      }
    }

    return false;
  }

  return (
    <>
      {members.map((member: TeamMemberType, index: number) => (
        <Fragment key={`member_bubble_${member.id}`}>
          <Circle
            onMouseMove={(e) => { handleMemberHover(e, member) }}
            onMouseOut={handleMemberHoverOut}
            key={`role_circle_${member.id}`}
            x={crew.measurements.width - baseX - Math.floor(index / rows) * offset}
            y={(crew.measurements.height / 2) + (rows / 2 * cellWidth) - cellRadius - (index % rows) * offset}
            radius={roleCircleWidth / 2}
            stroke={hasLeaderRole(member) ? "black" : ""}
            strokeWidth={2}
          />
          <Circle
            onMouseMove={(e) => { handleMemberHover(e, member) }}
            onMouseOut={handleMemberHoverOut}
            key={`time_commitment_circle_${member.id}`}
            x={crew.measurements.width - baseX - Math.floor(index / rows) * offset}
            y={(crew.measurements.height / 2) + (rows / 2 * cellWidth) - cellRadius - (index % rows) * offset}
            radius={timeCommitMentCircleWidth / 2}
            stroke={member.timeCommitment ? timeCommitment_attributes[member.timeCommitment]["color"] : "silver"}
            strokeWidth={2}
          />
          <Circle
            onMouseMove={(e) => { handleMemberHover(e, member) }}
            onMouseOut={handleMemberHoverOut}
            key={`filler_circle_${member.id}`}
            x={crew.measurements.width - baseX - Math.floor(index / rows) * offset}
            y={(crew.measurements.height / 2) + (rows / 2 * cellWidth) - cellRadius - (index % rows) * offset}
            radius={(timeCommitMentCircleWidth - 2) / 2}
            fill={member.participationLevel ? participationLevel_attributes[member.participationLevel]["color"] : "#f1f5f9"}
            opacity={member.participationLevel ? 0.4 : 1}
          />
          {!member.avatar && <Text
            onMouseMove={(e) => { handleMemberHover(e, member) }}
            onMouseOut={handleMemberHoverOut}
            key={`member_initials_${member.id}`}
            x={crew.measurements.width - baseX - Math.floor(index / rows) * offset - timeCommitMentCircleWidth / 2}
            y={(crew.measurements.height / 2) + (rows / 2 * cellWidth) - cellRadius - (index % rows) * offset - (timeCommitMentCircleWidth / 4)}
            width={timeCommitMentCircleWidth}
            height={timeCommitMentCircleWidth / 2}
            fontSize={timeCommitMentCircleWidth / 2}
            fill={"black"}
            align="center"
            fontFamily="Proxima Nova, sans-serif"
            text={member.icon ? member.icon : getInitials(member.name)}
          />}
          {member.avatar &&
            <MemberAvatar
              x={crew.measurements.width - baseX - Math.floor(index / rows) * offset - timeCommitMentCircleWidth / 2}
              y={(crew.measurements.height / 2) + (rows / 2 * cellWidth) - cellRadius - (index % rows) * offset - (timeCommitMentCircleWidth / 2)}
              width={timeCommitMentCircleWidth}
              height={timeCommitMentCircleWidth}
              avatar={member.avatar}
              member={member}
              handleMemberHover={handleMemberHover}
              handleMemberHoverOut={handleMemberHoverOut}
            />
          }
        </Fragment>
      ))}
    </>
  )
}

