import { useContext } from "react";

import ValueStreamForm from "./ValueStreamForm";
import { ValueStream } from "./ValueStream";
import { DBValueStreamType } from "../../../../common/types";
// import { ReactSortable } from "react-sortablejs";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import Spinner from "../../../baseList/Spinner";
import useValueStreamApi from "../../common/hooks/useValueStreamApi";

type ValueStreamListPropsType = {
  baseId: string;
}

export default function ValueStreamList({baseId}: ValueStreamListPropsType) {
  const { user } = useContext(AuthContext) as AuthContextType;

  const {
    valueStreamsQuery,
    createValueStreamMutation,
    addValueStream,
    deleteValueStreamMutation,
    removeValueStream
  } = useValueStreamApi({baseId, user});

  return (
    <div className="py-2 md:px-2 w-full md:w-1/2 lg:w-1/3">
      <div className="border rounded-md p-2">
        <div className="flex mb-2">
          {(valueStreamsQuery.isFetching || deleteValueStreamMutation.isLoading || createValueStreamMutation.isLoading) &&
            <Spinner width="w-8" height="h-8" border="border-2" />}
          <h2 className="text-xl">Value Streams</h2>
        </div>

        {valueStreamsQuery.data?.length === 0 && (
          <ul className="my-2">
            <li className="text-slate-500">No items...</li>
          </ul>
        )}

        {valueStreamsQuery.data?.length > 0 && (
          <div>
            {valueStreamsQuery.data?.map((valueStream: DBValueStreamType) => (
              <ValueStream key={valueStream.id} valueStream={valueStream} deleteItem={removeValueStream} />
            ))}
          </div>
        )}
        <ValueStreamForm addItem={addValueStream} />
      </div>
    </div>
  );
}
