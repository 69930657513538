import { Dialog } from '@headlessui/react'
import { User } from 'firebase/auth';
import CanvasSizeForm from '../settings/CanvasSizeForm';
import { CanvasSizeType, DisplayOptionKeyType, DisplayOptionsType } from '../../../../common/types';
import Spinner from '../../../baseList/Spinner';
import { useState } from 'react';

type ResizeCanvasDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  user: User | null;
  baseId: string;
  display_options: DisplayOptionsType;
  setDisplayOption: (attribute: DisplayOptionKeyType, value: boolean | CanvasSizeType) => void;
}

export default function ResizeCanvasDialog({ isOpen, setIsOpen, user, baseId, display_options, setDisplayOption }: ResizeCanvasDialogProps) {

  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel className="mx-auto w-full lg:w-1/2 p-4 rounded bg-white">
          <Dialog.Title className="flex items-center">
            {showSpinner && <Spinner width="w-8" height="h-8" border="border-2" />}
            <span>Resize Chart</span>
          </Dialog.Title>

          <CanvasSizeForm display_options={display_options} baseId={baseId} user={user} setIsOpen={setIsOpen} setShowSpinner={setShowSpinner} />

          <div className="px-2">
            <button
              onClick={() => setIsOpen(false)}
              className="w-full p-2 border border-teal-500 rounded-md"
            >
              Cancel
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}