import { useQuery } from "react-query";
import { useContext, useEffect, useState } from "react";
import { AuthContext, AuthContextType } from "../context/AuthContext";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import Spinner from "../components/baseList/Spinner";
import Breadcrumbs from "../components/baseEditor/common/Breadcrumbs";
import useIsAuthenticated from "../hooks/useIsAuthenticated";
import NewsletterDialog from "../components/newsletter/NewsletterDialog";
import { getCurrentUser } from "../api/userApi";
import { FetchedBaseType } from "../common/types";
import UnfixBaseItem from "../components/baseList/UnfixBaseItem";
import useBaseApi from "../components/baseEditor/common/hooks/useBaseApi";
import DeleteConfirmDialog from "../components/baseList/DeleteConfirmDialog";

export default function BaseList() {
  const [editingBase, setEditingBase] = useState<string>("");
  const [showNewsletterDialog, setShowNewsletterDialog] = useState(false);

  const { user } = useContext(AuthContext) as AuthContextType;

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const removeCB = () => { setEditingBase(""); }
  const {
    basesQuery,
    createBaseMutation,
    deleteBaseMutation,
    addBase,
    removeBase
  } = useBaseApi({ user, removeSuccessCB: removeCB, removeErrorCB: removeCB })

  const userQuery = useQuery(["currentUser"], () => getCurrentUser({ user }), { enabled: !!user })

  useEffect(() => {

    let remindAfter;

    if (userQuery.isSuccess && !userQuery.data?.newsletter?.dontAskMe && !userQuery.data?.newsletter?.email) {
      if (!userQuery.data?.newsletter?.remindAfter) {
        setShowNewsletterDialog(true);
      } else if (userQuery.data?.newsletter?.remindAfter) {
        remindAfter = new Date(userQuery.data?.newsletter?.remindAfter)
        if (remindAfter <= new Date()) {
          setShowNewsletterDialog(true);
        }
      }
    }
  }, [userQuery.data, userQuery.isSuccess]);

  useIsAuthenticated();

  const deleteBaseClicked = (id: string) => {
    setEditingBase(id);
    // removeBase(id);
    setConfirmOpen(true);
  }

  return (
    <>
      <div className="h-screen w-full p-2">
        <Breadcrumbs />
        {user && <div className="flex flex-wrap">
          {showNewsletterDialog && <NewsletterDialog />}
          <div className="p-2 w-1/2 md:w-1/4 lg:w-1/6">
            <div onClick={addBase} className="flex flex-col items-center p-2 border border-teal-500 rounded-md cursor-pointer">
              <div className="w-full h-6">
                <span></span>
              </div>
              {(basesQuery.isLoading || createBaseMutation.isLoading) && (
                <Spinner width="w-40" height="h-40" border="border-8" />
              )}
              {!(basesQuery.isLoading || createBaseMutation.isLoading) && (
                <div>
                  <PlusCircleIcon className="w-40 h-40 text-teal-500" />
                </div>
              )}
              <div className="">
                Add Base
              </div>
            </div>
          </div>
          {
            basesQuery.isSuccess && basesQuery.data.map((base: FetchedBaseType) => (
              <UnfixBaseItem
                key={`unfix_base_item_${base.id}`}
                base={base}
                basesQuery={basesQuery}
                deleteBaseMutation={deleteBaseMutation}
                deleteBaseClicked={deleteBaseClicked}
                editingBase={editingBase}
              />
            ))
          }
        </div>}
      </div>
      <DeleteConfirmDialog
        isOpen={confirmOpen}
        setIsOpen={setConfirmOpen}
        removeBase={removeBase}
        editingBase={editingBase}
        setEditingBase={setEditingBase}
        // confirmCB={deleteBaseClicked}
      />
    </>
  )
}
