import { Formik, Field, Form, ErrorMessage } from "formik";
import { CanvasResizeType } from "../../../../common/types";
import { DisplayOptionsType } from "../../../../common/types";
import { useState } from "react";
import useCanvasElementsApi from "../../common/hooks/useCanvasElementsApi";
import { User } from "firebase/auth";

type FormErrorsType = {
  width?: string;
  height?: string;
}

type CanvasSizeFormProps = {
  baseId: string;
  user: User | null;
  display_options: DisplayOptionsType;
  setIsOpen: (isOpen: boolean) => void;
  setShowSpinner: (showSpinner: boolean) => void;
}

export default function CanvasSizeForm({ baseId, user, display_options, setIsOpen, setShowSpinner }: CanvasSizeFormProps) {

  const initialValues: CanvasResizeType = {
    width: display_options.canvas_size.width,
    height: display_options.canvas_size.height,
    resize_horizontal: false,
    resize_vertical: false,
  }

  const [resizeHorizontalChecked, setresizeHorizontalChecked] = useState<boolean>(false);
  const [resizeVerticalChecked, setresizeVerticalChecked] = useState<boolean>(false);

  const { resizeCanvas, resizeCanvasMutation } = useCanvasElementsApi({ baseId, user })

  return (
    <div className="mt-0">
      <Formik
        initialValues={initialValues}
        validate={values => {
          const errors: FormErrorsType = {};
          if (!values.width || values.width < 400) {
            errors.width = 'Mininmum width is 400';
          }
          if (!values.height || values.height < 400) {
            errors.height = 'Mininmum height is 400';
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          values.height = Number(values.height);
          values.width = Number(values.width);
          setShowSpinner(true);
          resizeCanvas(values, setIsOpen);
          actions.setSubmitting(false);
        }}
      >
        <Form className="flex flex-col border-t mt-2 pt-2">
          <div className="flex items-center">
            <label className="w-1/2" htmlFor="width">Chart width (px)</label>
            <Field type="number" name="width" placeholder="Width" className="border rounded-md p-1 my-2 w-1/2" />
          </div>
          <label className="flex items-center my-2">
            <div className="w-1/2">Resize horizontal elements</div>
            <Field
              type="checkbox"
              name="resize_horizontal"
              className="form-checkbox border border-teal-500 rounded-md w-6 h-6 mr-1 accent-teal-200"
              checked={resizeHorizontalChecked}
              onClick={() => { setresizeHorizontalChecked(!resizeHorizontalChecked); }}
            />
          </label>
          <ErrorMessage className="text-red-700" name="width" component="div" />

          <div className="flex items-center">
            <label className="w-1/2" htmlFor="height">Chart height (px)</label>
            <Field type="number" name="height" placeholder="Height" className="border rounded-md p-1 my-2 w-1/2" />
          </div>
          <label className="flex items-center my-2">
            <div className="w-1/2">Resize vertical elements</div>
            <Field
              type="checkbox"
              name="resize_vertical"
              className="form-checkbox border border-teal-500 rounded-md w-6 h-6 mr-1 accent-teal-200"
              checked={resizeVerticalChecked}
              onClick={() => { setresizeVerticalChecked(!resizeVerticalChecked); }}
            />
          </label>
          <ErrorMessage className="text-red-700" name="height" component="div" />

          <button type="submit" className="p-2 px-4 my-2 bg-teal-500 text-white rounded-md">Save</button>
        </Form>
      </Formik>
    </div>
  );
}
