import { ArrowsPointingOutIcon, ArrowsRightLeftIcon, CheckIcon, ChevronDownIcon, ChevronRightIcon, Cog6ToothIcon, EyeIcon, RectangleGroupIcon, SparklesIcon, Square3Stack3DIcon, WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import DisplaySettings from "../settings/DisplaySettings";
import useBaseApi from "../../common/hooks/useBaseApi";
import { RefObject, useContext, useState } from "react";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import { ScaleType } from "../../../../hooks/useResponsiveCanvas";
import Konva from "konva";
import ResizeCanvasDialog from "./ResizeCanvasDialog";
import useCanvasElementsApi from "../../common/hooks/useCanvasElementsApi";
import Spinner from "../../../baseList/Spinner";
import DownloadButton from "./DownloadButton";
import ToMiroButton from "./ToMiroButton";
import { RadioGroup } from "@headlessui/react";
import { ChartViewType } from "../../../../common/types";

type ChartMenuProps = {
  stageRef: RefObject<Konva.Stage>;
  baseId: string;
  canvasScale: ScaleType;
  showCanvasElementsPanel: boolean;
  setShowCanvasElementsPanel: (show: boolean) => void;
  views: string[];
  view: ChartViewType;
  setView: (view: ChartViewType) => void;
}

export default function ChartMenu({
  baseId,
  stageRef,
  canvasScale,
  showCanvasElementsPanel,
  setShowCanvasElementsPanel,
  views,
  view,
  setView
}: ChartMenuProps) {

  const { user } = useContext(AuthContext) as AuthContextType;
  const { baseQuery, setDisplayOption } = useBaseApi({ user, baseId });

  const [openMenuId, setOpenMenuId] = useState<number>(0);

  const [showResizeCanvasDialog, setShowResizeCanvasDialog] = useState<boolean>(false);

  const { autoLayout, autoLayoutMutation } = useCanvasElementsApi({ baseId, user });

  const autoLayoutClicked = () => {
    if (window.confirm("Are you sure you want to auto-layout all elements?") === true) {
      autoLayout();
    }
  }

  const OPEN_MENU_TOOLBAR = 1;
  const OPEN_MENU_DISPLAY_OPTIONS = 2;
  const OPEN_MENU_VIEW = 3;

  const openMenu = (menuId: number) => {
    if (openMenuId === menuId) {
      setOpenMenuId(0);
    } else {
      setOpenMenuId(menuId);
    }
  }

  return (
    <>
      <div className="border rounded-md p-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center">

            <button
              onClick={() => openMenu(OPEN_MENU_TOOLBAR)}
              className="flex items-center px-2 py-2"
            >
              <WrenchScrewdriverIcon className="mr-1 w-6 h-6" />
              <span className="hidden md:inline-block flex items-center">Toolbar</span>
              {openMenuId === OPEN_MENU_TOOLBAR && <ChevronDownIcon className="w-4 h-4 ml-1" />}
              {openMenuId !== OPEN_MENU_TOOLBAR && <ChevronRightIcon className="w-4 h-4 ml-1" />}
            </button>

            <button
              onClick={() => openMenu(OPEN_MENU_DISPLAY_OPTIONS)}
              className="flex items-center px-2 py-2"
            >
              <Cog6ToothIcon className="mr-1 w-6 h-6" />
              <span className="hidden md:inline-block flex items-center">Settings</span>
              {openMenuId === OPEN_MENU_DISPLAY_OPTIONS && <ChevronDownIcon className="w-4 h-4 ml-1" />}
              {openMenuId !== OPEN_MENU_DISPLAY_OPTIONS && <ChevronRightIcon className="w-4 h-4 ml-1" />}
            </button>

            <button
              onClick={() => openMenu(OPEN_MENU_VIEW)}
              className="flex items-center px-2 py-2"
            >
              <EyeIcon className="mr-1 w-6 h-6" />
              <span className="hidden md:inline-block flex items-center">View</span>
              {openMenuId === OPEN_MENU_VIEW && <ChevronDownIcon className="w-4 h-4 ml-1" />}
              {openMenuId !== OPEN_MENU_VIEW && <ChevronRightIcon className="w-4 h-4 ml-1" />}
            </button>
          </div>

          <div className="flex items-center">
            <button onClick={() => setShowCanvasElementsPanel(!showCanvasElementsPanel)} className={`flex items-center px-4 py-2 rounded-md ${showCanvasElementsPanel ? "bg-teal-100" : "bg-white"}`}>
              <Square3Stack3DIcon className="mr-1 w-6 h-6" />
              <span className="hidden md:inline-block">Layers</span>
            </button>
          </div>
        </div>

        {openMenuId === OPEN_MENU_TOOLBAR && <div className="flex w-full pt-2 border-t">
          <DownloadButton stageRef={stageRef} baseId={baseId} canvasScale={canvasScale} />

          <ToMiroButton />

          {/* <ExportMenu stageRef={stageRef} baseId={baseId} canvasScale={canvasScale} /> */}

          {view === "Base" && <button onClick={() => { setShowResizeCanvasDialog(true) }} className="flex items-center px-4 py-2 ml-1 rounded-md bg-teal-500 text-white">
            <ArrowsPointingOutIcon className="mr-1 w-6 h-6" />
            <span className="hidden md:inline-block">Resize</span>
          </button>}

          {view === "Base" && <button onClick={autoLayoutClicked} className="flex items-center px-4 py-2 ml-1 rounded-md bg-teal-500 text-white">
            {autoLayoutMutation.isLoading && <Spinner border_color="border-gray-50" />}
            {!autoLayoutMutation.isLoading && <SparklesIcon className="mr-1 w-6 h-6" />}
            <span className="hidden md:inline-block">Auto-layout</span>
          </button>}
        </div>}

        {openMenuId === OPEN_MENU_DISPLAY_OPTIONS && baseQuery.data &&
          <DisplaySettings
            view={view}
            display_options={baseQuery.data.display_options}
            setDisplayOption={setDisplayOption}
          />}

        {openMenuId === OPEN_MENU_VIEW && <div className="flex w-full pt-2 border-t">

          <RadioGroup value={view} onChange={setView} className="w-full flex items-center">
            {views.map((viewName: string, i: number) => (
              <RadioGroup.Option key={i} value={viewName} className="flex items-center mr-2 py-2 cursor-pointer">
                {({ checked }) => (
                  <div className={`flex items-center rounded-md px-4 ${checked ? 'bg-teal-200' : ''}`}>
                    <span>{viewName}</span>
                    {checked && <CheckIcon className="w-6 h-6 ml-1" />}
                  </div>
                )}
              </RadioGroup.Option>
            ))}
          </RadioGroup>

        </div>}

      </div>
      <ResizeCanvasDialog
        isOpen={showResizeCanvasDialog}
        setIsOpen={setShowResizeCanvasDialog}
        user={user}
        baseId={baseId}
        display_options={baseQuery.data?.display_options}
        setDisplayOption={setDisplayOption}
      />
    </>
  )
}
