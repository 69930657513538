import { Menu } from "@headlessui/react";
import { TeamMemberType } from "../../../../../common/types"
import { getInitials } from "../../../../../lib/textTransform";
import EditorMemberAvatar from "./EditorMemberAvatar";

type TeamMemberBubbleProps = {
  member: TeamMemberType;
  borderColor: string;
  bgColor: string;
  deleteMember: (id: string) => void;
}

export default function TeamMemberBubble({ member, bgColor, borderColor, deleteMember }: TeamMemberBubbleProps) {
  return (
    <>
      <div className="mr-2 relative">
        <Menu>
          <Menu.Button>
            {member.avatar && <EditorMemberAvatar avatar={member.avatar} />}
            {!member.avatar && member.icon && <div className="flex items-center justify-center border-2 w-8 h-8 border-black rounded-2xl bg-slate-100">
              <span className="text-2xl">{member.icon}</span>
            </div>}
            {!member.avatar && !member.icon && <div className="flex items-center justify-center border-2 w-8 h-8 border-black rounded-2xl bg-slate-100">
              <span className="">{getInitials(member.name)}</span>
            </div>}
          </Menu.Button>
          <Menu.Items as="div" className="flex flex-col absolute right-0 top-5 z-10 bg-white rounded-md border-2 text-black" style={{ borderColor: borderColor }}>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => deleteMember(member.id)}
                  className="py-2 px-4 whitespace-nowrap ui-active:text-white"
                  style={active ? { backgroundColor: bgColor } : {}}
                >
                  Delete
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
    </>
  )
}