import { ErrorMessage, Field, Form, Formik } from "formik";
import { DBCrewType, DBCrewPatchType } from "../../../../common/types";
import { CREW_TYPES } from "../../../../common/constants/crewTypes";
import InvestmentHorizonSelector from "./InvestmentHorizonSelector";
import { LIFECYCLE_STAGES } from "../../../../common/constants/lifecycleStages";
import { TEAMING_OPTIONS } from "../../../../common/constants/teamingOptions";

type CrewEditFormProps = {
  crew: DBCrewType,
  editItem: (crew: DBCrewPatchType) => void;
}

type FormErrorsType = {
  name?: string;
  type?: string;
}

export default function CrewEditForm({ crew, editItem }: CrewEditFormProps) {

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...crew,
          lifecycleStage: crew.lifecycleStage ?? 0,
          teamingOption: crew.teamingOption ?? 0,
        }}
        validate={values => {
          const errors: FormErrorsType = {};
          if (!values.name) {
            errors.name = 'Name is required';
          }
          if (!values.type) {
            errors.type = 'Type is required';
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          values.lifecycleStage = Number(values.lifecycleStage);
          values.teamingOption = Number(values.teamingOption);
          editItem(values);
          actions.setSubmitting(false);
        }}
      >
        <Form className="flex flex-col border-t mt-2 pt-2">
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-4/12 lg:w-4/12" htmlFor="name">Name</label>
            <Field name="name" placeholder="Crew name" className="w-full border rounded-md p-1 my-2" />
          </div>
          <ErrorMessage className="text-red-700" name="name" component="div" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-4/12 lg:w-4/12" htmlFor="type">Type</label>
            <Field name="type" as="select" className="w-full border rounded-md p-1 my-2">
              {CREW_TYPES.map((type) => <option key={type.id} value={type.id}>{type.name}</option>)}
            </Field>
          </div>
          <ErrorMessage className="text-red-700" name="type" component="div" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-4/12 lg:w-4/12" htmlFor="teamingOption">Teaming Option</label>
            <Field name="teamingOption" as="select" className="w-full border rounded-md p-1 my-2">
              <option value={0}>None</option>
              {TEAMING_OPTIONS.map((option) => <option key={option.id} value={option.id}>{option.name}</option>)}
            </Field>
          </div>
          <ErrorMessage className="text-red-700" name="teamingOption" component="div" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-4/12 lg:w-4/12" htmlFor="lifecycleStage">Lifecycle Stage</label>
            <Field name="lifecycleStage" as="select" className="w-full border rounded-md p-1 my-2">
              <option value={0}>None</option>
              {LIFECYCLE_STAGES.map((stage) => <option key={stage.id} value={stage.id}>{stage.name}</option>)}
            </Field>
          </div>
          <ErrorMessage className="text-red-700" name="lifecycleStage" component="div" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-4/12 lg:w-4/12" htmlFor="investmentHorizons">Investment Horizons</label>
            <InvestmentHorizonSelector crewHorizons={crew.investmentHorizons ?? []} />
          </div>
          <div className="flex items-center justify-end">
            <button type="submit" className="w-1/3 md:w-1/4 p-2 px-4 my-2 bg-teal-500 text-white rounded-md">Save</button>
          </div>
          <ErrorMessage className="text-red-700" name="investmentHorizons" component="div" />
        </Form>
      </Formik>
    </div>
  );

}
