import { Circle, Group, Rect, Text, Image } from "react-konva";

import { base_type_attributes } from "../../../common/constants/baseTypes";

import useImage from 'use-image';

type BaseElementProps = {
  base: any
  isRectangular: boolean;
};

export default function BaseElement({
  base,
  isRectangular,
}: BaseElementProps) {
  const [icon] = useImage('/unfix/icons/base.svg');

  const {width: defaultWidth, height: defaultHeight} = base.display_options.canvas_size;

  return (
    <Group x={0} y={0} width={defaultWidth} height={defaultHeight} preventDefault={false}>
      {isRectangular && <Rect
        x={20}
        y={20}
        width={defaultWidth - 40}
        height={defaultHeight - 40}
        stroke="black"
        cornerRadius={200}
        dash={base_type_attributes[ base.type ][ 'dash' ]}
        preventDefault={false}
        />}
      {!isRectangular && <Circle
        x={defaultWidth / 2}
        y={defaultHeight / 2}
        radius={(Math.min(defaultHeight, defaultWidth) / 2) - 20}
        stroke="black"
        dash={base_type_attributes[ base.type ][ 'dash' ]}
        preventDefault={false}
        />}
      <Text
        x={0}
        y={isRectangular? defaultHeight - 80 : (defaultHeight) / 2 + (Math.min(defaultHeight, defaultWidth) / 2) - 80}
        width={defaultWidth}
        height={20}
        align="center"
        text={base.name}
        fontSize={20}
        preventDefault={false}
      />
      <Image
        image={icon}
        alt=""
        x={defaultWidth / 2 - 20}
        y={isRectangular? defaultHeight - 60 : (defaultHeight) / 2 + (Math.min(defaultHeight, defaultWidth) / 2) - 60}
        width={40}
        height={40}
      />
    </Group>
  );
}
