import { useContext, useState } from "react";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import TeamMemberList from "../teamMembers/TeamMemberList";
import Spinner from "../../../baseList/Spinner";
import ForumEditForm from "./ForumEditForm";
import useForumApi from "../../common/hooks/useForumApi";
import useForumMemberApi from "../../common/hooks/useForumMemberApi";
import useBaseMemberApi from "../../common/hooks/useBaseMemberApi";

type ForumDetailsPropsType = {
  baseId: string;
  forumId: string;
}

export default function ForumDetails({ baseId, forumId }: ForumDetailsPropsType) {
  const { user } = useContext(AuthContext) as AuthContextType;
  const [forumMembersGroupBy, setForumMembersGroupBy] = useState("participationLevel");

  const {forumQuery, editForum} = useForumApi({baseId, user, forumId})


  const {
    forumMembersQuery,
    addForumMember,
    removeForumMember,
    editForumMember,
  } = useForumMemberApi({baseId, user, forumId, forumMembersGroupBy});

  const { baseMembersQuery } = useBaseMemberApi({baseId, user});

  return (

    <div id="forum_details" className="w-full">
      <div className="py-2 md:px-2 w-full">
        <div className="border rounded-md p-2">
          <div className="flex mb-2">
            {(forumQuery.isFetching) &&
              <Spinner width="w-8" height="h-8" border="border-2" />}
            <h2 className="text-xl">Forum Details</h2>
          </div>
          
          {forumQuery.isSuccess && forumQuery.data && <ForumEditForm forum={forumQuery.data} editItem={editForum} />}

        </div>
      </div>


      <TeamMemberList
        teamMembersQuery={forumMembersQuery}
        baseMembersQuery={baseMembersQuery}
        addTeamMember={addForumMember}
        removeTeamMember={removeForumMember}
        editTeamMember={editForumMember}
        groupBy={forumMembersGroupBy}
        setGroupBy={setForumMembersGroupBy}
      />
    </div>
  );
}
