import { Formik, Field, Form, ErrorMessage } from "formik";
import { BASE_MEMBER_ROLES, BASE_MEMBER_ROLE_RESOURCE } from "./BaseMember";

import { FormBaseMemberType } from "../../../../common/types";
import { useContext, useState } from "react";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import IconSelector from "../teamMembers/IconSelector";
import ImageCropper from "../common/ImageCropper";
import useAccountApi from "../../common/hooks/useAccountApi";

type BaseMemberFormProps = {
  baseId: string;
  addItem: (baseMember: FormBaseMemberType, avatarImage?: string) => void;
}

type FormErrorsType = {
  name?: string;
  email?: string;
  role?: string;
}

export default function BaseMemberAddForm({ addItem, baseId }: BaseMemberFormProps) {
  const { user } = useContext(AuthContext) as AuthContextType;
  const { basePaidStatusQuery } = useAccountApi({ user, baseId })

  const [croppedImage, setCroppedImage] = useState<string>("");

  const initialValues: FormBaseMemberType = {
    name: "",
    email: "",
    role: BASE_MEMBER_ROLE_RESOURCE,
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validate={values => {
          const errors: FormErrorsType = {};
          if (!values.name) {
            errors.name = 'Name is required';
          }
          if (!values.email && Number(values.role) !== BASE_MEMBER_ROLE_RESOURCE) {
            errors.email = 'Email is required';
          }
          if (!values.role) {
            errors.role = 'Role is required';
          }

          if (!basePaidStatusQuery.data?.email && Number(values.role) !== BASE_MEMBER_ROLE_RESOURCE) {
            errors.role = "Sharing a Base requires a Premium tier subscription"
          }

          return errors;
        }}
        onSubmit={(values, actions) => {
          values.role = Number(values.role);
          addItem(values, croppedImage);
          actions.setSubmitting(false);
          actions.resetForm();
          setCroppedImage("");
        }}
      >
        <Form className="flex flex-col border-t mt-2 pt-2">
          <div className="w-full">
            <h2 className="text-xl py-2">Add member</h2>
          </div>
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="name">Name</label>
            <Field name="name" placeholder="Member name" className="w-full border rounded-md p-1 my-2" />
          </div>
          <ErrorMessage className="text-red-700" name="name" component="div" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="email">Email</label>
            <Field name="email" placeholder="Member email" className="w-full border rounded-md p-1 my-2" />
          </div>
          <ErrorMessage className="text-red-700" name="email" component="div" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="role">Role</label>
            <Field name="role" as="select" className="w-full border rounded-md p-1 my-2">
              {BASE_MEMBER_ROLES.map((role) => <option key={role.id} value={role.id}>{role.name}</option>)}
            </Field>
          </div>
          <ErrorMessage className="text-red-700" name="role" component="div" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="icon">Icon</label>
            <IconSelector memberIcon={""} />
          </div>
          <ErrorMessage className="text-red-700" name="icon" component="div" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="avatar">Avatar</label>
            <ImageCropper croppedImage={croppedImage} setCroppedImage={setCroppedImage} />
          </div>
          <ErrorMessage className="text-red-700" name="avatar" component="div" />
          {basePaidStatusQuery.data && <div className="flex items-center justify-end">
            <button type="submit" className="w-1/3 md:w-1/4 p-2 px-4 my-2 bg-teal-500 text-white rounded-md">Add</button>
          </div>}
        </Form>
      </Formik>
    </div>
  );
}
