import { useState } from "react";
import { SelectedElementType } from "../UnfixChart"
import { Menu } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import useCrewApi from "../../common/hooks/useCrewApi";
import { User } from "firebase/auth";
import useForumApi from "../../common/hooks/useForumApi";
import useTurfApi from "../../common/hooks/useTurfApi";
import EditCrewDialog from "./EditCrewDialog";
import EditForumDialog from "./EditForumDialog";
import { Link } from "react-router-dom";
import EditTurfDialog from "./EditTurfDialog";
import useValueStreamApi from "../../common/hooks/useValueStreamApi";
import EditValueStreamDialog from "./EditValueStreamDialog";
import { DBStoreItemType } from "../../../../common/types";
import useCanvasElementsApi from "../../common/hooks/useCanvasElementsApi";

type EditSelectedItemMenuProps = {
  selectedElement: SelectedElementType;
  selectElement: (selectedElement: SelectedElementType) => void;
  orderedElements: DBStoreItemType[];
  setOrderedElements: (elements: DBStoreItemType[]) => void;
  baseId: string;
  user: User | null;
}

export default function EditSelectedElementMenu({
  selectedElement,
  selectElement,
  orderedElements,
  setOrderedElements,
  baseId,
  user
}: EditSelectedItemMenuProps) {
  const [isEditCrewDialogOpen, setIsEditCrewDialogOpen] = useState(false);
  const [isEditForumDialogOpen, setIsEditForumDialogOpen] = useState(false);
  const [isEditTurfDialogOpen, setIsEditTurfDialogOpen] = useState(false);
  const [isEditValueStreamDialogOpen, setIsEditValueStreamDialogOpen] = useState(false);

  const removeSuccessCB = () => {
    selectElement({ type: null })
  }

  const { removeCrew } = useCrewApi({ baseId, user, removeSuccessCB });
  const { removeForum } = useForumApi({ baseId, user, removeSuccessCB });
  const { removeTurf } = useTurfApi({ baseId, user, removeSuccessCB });
  const { removeValueStream } = useValueStreamApi({ baseId, user, removeSuccessCB });

  const { reorderElements } = useCanvasElementsApi({baseId: baseId, user: user});

  const handleDeleteClick = () => {
    if (!selectedElement.id) {
      return;
    }

    switch (selectedElement.type) {
      case "crew":
        removeCrew(selectedElement.id);
        break;
      case "forum":
        removeForum(selectedElement.id);
        break;
      case "turf":
        removeTurf(selectedElement.id);
        break;
      case "value_stream":
        removeValueStream(selectedElement.id);
        break;
    }
  };

  const handleEditClick = () => {
    switch (selectedElement.type) {
      case "crew":
        setIsEditCrewDialogOpen(true);
        break;
      case "forum":
        setIsEditForumDialogOpen(true);
        break;
      case "turf":
        setIsEditTurfDialogOpen(true);
        break;
      case "value_stream":
        setIsEditValueStreamDialogOpen(true);
        break;
    }

  }

  const handleBringToFrontClick = () => {
    const newOrderedElements = orderedElements.filter((element) => element.id !== selectedElement.id);
    const currentElement = orderedElements.find((element) => element.id === selectedElement.id);

    if (!currentElement) {
      return;
    }

    setOrderedElements([...newOrderedElements, currentElement]);
    reorderElements([...newOrderedElements, currentElement]);
  }

  const handleSendToBackClick = () => {
    const newOrderedElements = orderedElements.filter((element) => element.id !== selectedElement.id);
    const currentElement = orderedElements.find((element) => element.id === selectedElement.id);

    if (!currentElement) {
      return;
    }

    setOrderedElements([currentElement, ...newOrderedElements]);
    reorderElements([currentElement, ...newOrderedElements]);
  }

  return (
    <>
      <div className="relative mr-2">
        <Menu>
          <Menu.Button className="flex items-center px-4 py-2 bg-teal-500 text-white rounded-md">
            Actions
            <ChevronUpIcon className="ml-1 w-6 h-6" />
          </Menu.Button>
          <Menu.Items as="div" className="flex flex-col absolute bottom-full origin-top-right right-0 bottom-12 z-10 bg-white rounded-md border-2 border-teal-500 text-black">
            {selectedElement.type && selectedElement.id && <Menu.Item>
              <button
                onClick={handleBringToFrontClick}
                className="py-2 px-4 whitespace-nowrap ui-active:bg-teal-500 ui-active:text-white text-left"
              >
                Bring to Front
              </button>
            </Menu.Item>}
            {selectedElement.type && selectedElement.id && <Menu.Item>
              <button
                onClick={handleSendToBackClick}
                className="py-2 px-4 whitespace-nowrap ui-active:bg-teal-500 ui-active:text-white text-left"
              >
                Send to Back
              </button>
            </Menu.Item>}
            {selectedElement.type && selectedElement.id && <Menu.Item>
              <Link
                to={`/base/${baseId}/${selectedElement.type}/${selectedElement.id}`}
                className="py-2 px-4 whitespace-nowrap ui-active:bg-teal-500 ui-active:text-white text-left"
              >
                Details
              </Link>
            </Menu.Item>}
            {selectedElement.type && selectedElement.id && <Menu.Item>
              <button
                onClick={handleEditClick}
                className="py-2 px-4 whitespace-nowrap ui-active:bg-teal-500 ui-active:text-white text-left"
              >
                Edit
              </button>
            </Menu.Item>}
            <Menu.Item>
              <button
                onClick={handleDeleteClick}
                className="py-2 px-4 whitespace-nowrap ui-active:bg-teal-500 ui-active:text-white text-left"
              >
                Delete
              </button>
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
      {selectedElement.type === "crew" && selectedElement.id && <EditCrewDialog
        isOpen={isEditCrewDialogOpen}
        setIsOpen={setIsEditCrewDialogOpen}
        baseId={baseId}
        user={user}
        crewId={selectedElement.id}
      />}
      {selectedElement.type === "forum" && selectedElement.id && <EditForumDialog
        isOpen={isEditForumDialogOpen}
        setIsOpen={setIsEditForumDialogOpen}
        baseId={baseId}
        user={user}
        forumId={selectedElement.id}
      />}
      {selectedElement.type === "turf" && selectedElement.id && <EditTurfDialog
        isOpen={isEditTurfDialogOpen}
        setIsOpen={setIsEditTurfDialogOpen}
        baseId={baseId}
        user={user}
        turfId={selectedElement.id}
      />}
      {selectedElement.type === "value_stream" && selectedElement.id && <EditValueStreamDialog
        isOpen={isEditValueStreamDialogOpen}
        setIsOpen={setIsEditValueStreamDialogOpen}
        baseId={baseId}
        user={user}
        valueStreamId={selectedElement.id}
      />}
    </>
  );
}
