import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { useFormikContext } from "formik";
import { Fragment, useEffect, useState } from "react";
import { TEAM_MEMBER_ROLES } from "./TeamMember";

type RoleSelectorPropsType = {
  memberRoles: number[];
}

export default function RoleSelector({ memberRoles }: RoleSelectorPropsType) {
  const formikProps = useFormikContext();
  const [selectedRoles, setSelectedRoles] = useState<number[]>([...memberRoles]);
  const [rolePlaceholder, setRolePlaceholder] = useState("");

  useEffect(() => {
    setSelectedRoles([...memberRoles]);
    formikProps.setFieldValue("unfixRoles", [...memberRoles]);
  }, [memberRoles]);

  useEffect(() => {
    let placeholder;
    if (selectedRoles.length > 0) {
      placeholder = TEAM_MEMBER_ROLES.filter(
        (role) => selectedRoles.includes(role.id)
      ).map((role) => role.name).join(', ');
    } else {
      placeholder = "None";
    }
    
    setRolePlaceholder(placeholder);
  }, [selectedRoles])

  const rolesSelected = (roles: number[]) => {
    setSelectedRoles(roles);
    formikProps.setFieldValue("unfixRoles", roles);
  }

  return (
    <div className="relative w-full">
      <Listbox value={selectedRoles} onChange={rolesSelected} multiple>
        <Listbox.Button as="div" className="flex items-center justify-between w-full my-2">
          <span className="w-full p-1 border border-grey-300 rounded-md">
            {rolePlaceholder}
          </span>
          <ChevronUpDownIcon className="w-4 h-4 ml-1" />
        </Listbox.Button>
        <Listbox.Options className="absolute z-10 flex flex-col right-0 top-10 max-h-60 w-full overflow-auto rounded-md border-2 border-teal-500">
          {TEAM_MEMBER_ROLES.map((role) => (
            <Listbox.Option key={role.id} value={role.id} as={Fragment}>
              {({active, selected}) => (
                <li className={`flex items-center p-1 ${active ? 'bg-teal-500 text-white': 'bg-white text-black'}`}>
                  {selected && <CheckIcon className={`w-4 h-4 mr-2 stroke-[4px] ${active ? 'text-white': 'text-teal-500'}`} />}
                  {role.name}
                </li>
              )}

              {/* {role.name} */}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  )

}
