import { useContext, useEffect } from "react";
import { AuthContext, AuthContextType } from "../context/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

type SignInType = {
  email: string;
  password: string;
}

export default function SignIn() {
  const navigate = useNavigate();
  const { user, googleLogin, emailSignIn } = useContext(AuthContext) as AuthContextType;
  let [searchParams, setSearchParams] = useSearchParams();

  const handleLogin = async () => {
    try {
      googleLogin();
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    if (user) {
      let redirect_to = searchParams.get("redirect_to");
      if (!redirect_to) {
        redirect_to = "/";
      }
      navigate(redirect_to);
    }
  }, [user, navigate]);


  const initialValues: SignInType = {
    email: "",
    password: "",
  };

  const SignInSchema = Yup.object().shape({
    email: Yup.string().email("Email must be a valid email address").required("Email is required"),

    password: Yup.string()
      .required("Password is required")
      .min(6, "Password is too short - should be 6 chars minimum"),
  });


  return (
    <div className="w-full flex justify-center">
      <div className="flex flex-col py-2 md:px-2 w-full md:w-1/2 lg:w-1/3 m-2 p-2">
      {/* <img src="https://images.squarespace-cdn.com/content/v1/61af660e0ccda12fd7774c2a/6ef11888-a6fd-45e4-ab83-a61d3a4f9f00/logo-symbol-white.png?format=300w" /> */}
        <div className="text-2xl mt-4">Sign in</div>
        <Formik
          initialValues={initialValues}
          validationSchema={SignInSchema}
          onSubmit={(values, actions) => {
            emailSignIn(values.email, values.password).catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
            });
            actions.setSubmitting(false);
          }}
        >
          <Form className="flex flex-col border-t mt-2 pt-2">
            <div className="flex flex-col">
              <label htmlFor="email">Email</label>
              <Field type="email" name="email" placeholder="email@example.com" className="border rounded-md p-1 my-2" />
              <ErrorMessage className="text-red-700" name="email" component="div" />
            </div>
            <div className="flex flex-col">
              <label htmlFor="password">Password</label>
              <Field type="password" name="password" className="border rounded-md p-1 my-2" />
              <ErrorMessage className="text-red-700" name="password" component="div" />
            </div>
            <button
              type="submit"
              className="p-2 px-4 my-4 bg-teal-500 text-white rounded-md"
            >
              Sign in
            </button>
          </Form>
        </Formik>
        <div className="flex flex-col pt-2">
          <div className="mb-2">
            <span>I have </span>
            <Link className="text-teal-500" to="/forgotten_password">forgotten my password.</Link>
          </div>
          <div className="">
            <span>Don't have an account? </span>
            <Link className="text-teal-500" to="/sign_up">Sign up</Link>
          </div>
          <div className="mt-2 text-sm text-slate-700">By clicking Sign up or registering through a third party you accept the <a className="text-teal-500" target="_blank" rel="noreferrer" href="https://unfix.com/terms-and-conditions">Terms of use</a> and acknowledge the <a className="text-teal-500" target="_blank" rel="noreferrer" href="https://unfix.com/plotter/privacy-policy">Privacy Policy</a></div>
          <div className="border-t my-4 py-4">
            <div>Sign in with</div>
            <button role="button" onClick={handleLogin} className="border border-teal-500 rounded-md px-4 py-2 mt-2">Sign in with Google</button>
          </div>
        </div>
      </div>
    </div>
  );
}