import Spinner from "../baseList/Spinner";
import { useContext } from "react";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import useAccountApi from "../baseEditor/common/hooks/useAccountApi";

export default function Credits() {
  const { user } = useContext(AuthContext) as AuthContextType;
  const { accountQuery } = useAccountApi({ user });

  return (
    <div className="border rounded-md p-2 mb-4">
      <div className="flex mb-2">
        {(accountQuery.isFetching) && <Spinner width="w-8" height="h-8" border="border-2" />}
        <h2 className="text-xl">Credits</h2>
      </div>
      <div className="border-t mt-2 pt-2">
        <div className="my-2">
          {!accountQuery?.data && <li className="text-slate-500">Loading...</li>}
        </div>
        <div className="my-2">
          {accountQuery?.data && accountQuery.data?.credits > 0 && <span>You have €{accountQuery.data?.credits} credits, which will be used for your next invoice(s).</span>}
          {accountQuery?.data && accountQuery.data?.credits <= 0 && <span>If you decrease the number of seats in your subscription, you will recieve the remaining amount in credits. These credits will be used to pay your later invoice(s).</span>}
        </div>
      </div>
    </div>
  );
}
