import { Formik, Field, Form, ErrorMessage } from "formik";

import { FormTeamMemberType, BaseMemberType } from "../../../../common/types";
import MemberCombobox from "./MemberCombobox";
import { useEffect, useState } from "react";
import { CREW_MEMBER_PARTICIPATION_LEVELS, CREW_MEMBER_TIME_COMMITMENTS } from "./TeamMember";
import RoleSelector from "./RoleSelector";
import IconSelector from "./IconSelector";
import ImageCropper from "../common/ImageCropper";
import { XMarkIcon } from "@heroicons/react/24/outline";

type TeamMemberFormType = {
  baseMembers: BaseMemberType[];
  addItem: (crewMember: FormTeamMemberType, avatarImage?: string) => void;
  setShowAddForm: (showAddForm: boolean) => void;
}

type FormErrorsType = {
  name?: string;
  participationLevel?: string;
  timeCommitment?: string;
}

export default function TeamMemberAddForm({ baseMembers, addItem, setShowAddForm }: TeamMemberFormType) {
  const [selectedMember, setSelectedMember] = useState<BaseMemberType | null>(null);
  const [query, setQuery] = useState('');
  const [icon, setIcon] = useState('');

  const [croppedImage, setCroppedImage] = useState<string>("");

  const initialValues: FormTeamMemberType = {
    baseMemberId: "",
    email: "",
    name: "",
    participationLevel: 0,
    timeCommitment: 0,
    unfixRoles: [],
  };

  useEffect(() => {
    if (selectedMember?.icon) {
      setIcon(selectedMember.icon);
    }
  }, [selectedMember])

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validate={values => {
          const errors: FormErrorsType = {};
          if (!values.name) {
            errors.name = 'Name is required';
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          // console.log("submit", values);
          values.participationLevel = Number(values.participationLevel);
          values.timeCommitment = Number(values.timeCommitment);
          addItem(values, croppedImage);
          actions.setSubmitting(false);
          actions.resetForm();
          setSelectedMember(null);
          actions.setFieldValue("baseMemberId", "", false);
          actions.setFieldValue("name", "", false);
          actions.setFieldValue("email", "", false);
          setCroppedImage("");
          setQuery("");
        }}
      >
        <Form className="flex flex-col border-t mt-2 pt-2">
          <div className="w-full flex items-center justify-between">
            <h2 className="text-xl py-2">Add member</h2>
            <XMarkIcon onClick={() => setShowAddForm(false)} className="w-6 h-6" />
          </div>
          <Field type="hidden" name="baseMemberId" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="type">Name</label>
            <MemberCombobox
              baseMembers={baseMembers}
              selectedMember={selectedMember}
              setSelectedMember={setSelectedMember}
              query={query}
              setQuery={setQuery}
            />
          </div>
          <ErrorMessage className="text-red-700" name="name" component="div" />
          {/* <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="email">Email</label>
            <Field name="email" placeholder="Member email" className="w-full border rounded-md p-1 my-2" />
          </div>
          <ErrorMessage className="text-red-700" name="email" component="div" /> */}
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="unfixRoles">Role Attribute</label>
            <RoleSelector memberRoles={[]} />
          </div>
          <ErrorMessage className="text-red-700" name="unfixRoles" component="div" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="participationLevel">Participation Level</label>
            <Field name="participationLevel" as="select" className="w-full border rounded-md p-1 my-2">
              <option value={0}>None</option>
              {CREW_MEMBER_PARTICIPATION_LEVELS.map((participationLevel) => <option key={participationLevel.id} value={participationLevel.id}>{participationLevel.name}</option>)}
            </Field>
          </div>
          <ErrorMessage className="text-red-700" name="participationLevel" component="div" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="timeCommitment">Time Commitment</label>
            <Field name="timeCommitment" as="select" className="w-full border rounded-md p-1 my-2">
              <option value={0}>None</option>
              {CREW_MEMBER_TIME_COMMITMENTS.map((timeCommitment) => <option key={timeCommitment.id} value={timeCommitment.id}>{timeCommitment.name}</option>)}
            </Field>
          </div>
          <ErrorMessage className="text-red-700" name="timeCommitment" component="div" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="icon">Icon</label>
            <IconSelector memberIcon={icon} />
          </div>
          <ErrorMessage className="text-red-700" name="icon" component="div" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="avatar">Avatar</label>
            <ImageCropper croppedImage={croppedImage} setCroppedImage={setCroppedImage} />
          </div>
          <ErrorMessage className="text-red-700" name="avatar" component="div" />
          <div className="flex items-center justify-end">
            <button
              type="button"
              className="w-1/3 md:w-1/4 p-2 px-4 my-2 border border-teal-500 tex-teal-500 rounded-md"
              onClick={(e) => { e.preventDefault(); setShowAddForm(false) }}
            >
              Cancel
            </button>
            <button type="submit" className="w-1/3 md:w-1/4 p-2 px-4 my-2 bg-teal-500 text-white rounded-md ml-2">Add</button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}
