import { Formik, Field, Form, ErrorMessage } from "formik";
import { FORUM_TYPES, FUNCTIONAL_FORUM_TYPE } from "../../../../common/constants/forumTypes";
import { FormForumType } from "../../../../common/types";

interface IForumFormProps {
  addItem: (forum: FormForumType) => void;
}

type FormErrorsType = {
  name?: string;
  type?: string;
}

export default function ForumForm({addItem}: IForumFormProps) {
  const initialValues: FormForumType = {
    name: "",
    type: FUNCTIONAL_FORUM_TYPE,
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validate={values => {
          const errors: FormErrorsType = {};
          if (!values.name) {
            errors.name = 'Name is required';
          }
          if (!values.type) {
            errors.type = 'Type is required';
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          addItem(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }}
      >
        <Form className="flex flex-col border-t mt-2 pt-2">
          <Field name="name" placeholder="Forum name" className="border rounded-md p-1 my-2" />
          <ErrorMessage className="text-red-700" name="name" component="div" />
          <Field name="type" as="select" className="border rounded-md p-1 my-2">
            {FORUM_TYPES.map((type) => <option key={type.id} value={type.id}>{type.name}</option>)}
          </Field>
          <ErrorMessage className="text-red-700" name="type" component="div" />
          <div className="flex justify-end items-center">
            <button type="submit" className="w-1/3 p-2 px-4 my-2 bg-teal-500 text-white rounded-md">Add</button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}
