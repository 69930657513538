import { useContext, useEffect, useState } from "react";
import { AuthContext, AuthContextType } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

type SignUpType = {
  name: string;
  email: string;
  password: string;
  terms: boolean;
}

export default function SignUp() {
  const navigate = useNavigate();
  const { user, googleLogin, emailSignUp } = useContext(AuthContext) as AuthContextType;
  const [isDuplicateEmail, setDuplicateEmail] = useState(false);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  const handleLogin = async () => {
    try {
      await googleLogin();
    } catch (error) {
      console.log("error", error);
    }
  }

  const initialValues: SignUpType = {
    name: "",
    email: "",
    password: "",
    terms: false
  };

  const SignInSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email().required("Email is required"),

    password: Yup.string()
      .required("Password is required")
      .min(6, "Password is too short - should be 6 chars minimum"),

    terms: Yup.boolean().oneOf([true], "Approval is required"),
  });


  return (
    <div className="w-full flex justify-center">
      <div className="flex flex-col py-2 md:px-2 w-full md:w-1/2 lg:w-1/3 m-2 p-2">
        <div className="text-2xl">Sign up</div>
        <Formik
          initialValues={initialValues}
          validationSchema={SignInSchema}
          onSubmit={(values, actions) => {
            setDuplicateEmail(false);
            emailSignUp(values.name, values.email, values.password).catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;

              if (error.code === "auth/email-already-in-use") {
                setDuplicateEmail(true);
              }
            });
            actions.setSubmitting(false);
          }}
        >
          <Form className="flex flex-col border-t mt-2 pt-2">
            <div className="flex flex-col">
              <label htmlFor="name">Name</label>
              <Field name="name" placeholder="Your Name" className="border rounded-md p-1 my-2" />
              <ErrorMessage className="text-red-700" name="name" component="div" />
            </div>
            <div className="flex flex-col">
              <label htmlFor="email">Email</label>
              <Field type="email" name="email" placeholder="email@example.com" className="border rounded-md p-1 my-2" />
              <ErrorMessage className="text-red-700" name="email" component="div" />
              {isDuplicateEmail && <div className="text-red-700">Email address is already in use</div>}
            </div>
            <div className="flex flex-col">
              <label htmlFor="password">Password</label>
              <Field type="password" name="password" className="border rounded-md p-1 my-2" />
              <ErrorMessage className="text-red-700" name="password" component="div" />
            </div>
            <div>
              <Field type="checkbox" name="terms" className="border rounded-md p-1 my-2 mr-2 w-4 h-4" />
              <label className="" htmlFor="terms">I accept the accept the <a className="text-teal-500" target="_blank" rel="noreferrer" href="https://unfix.com/terms-and-conditions">Terms of use</a> and acknowledge the <a className="text-teal-500" target="_blank" rel="noreferrer" href="https://unfix.com/plotter/privacy-policy">Privacy Policy</a></label>
              <ErrorMessage className="text-red-700" name="terms" component="div" />
            </div>
            <button
              type="submit"
              className="p-2 px-4 my-4 bg-teal-500 text-white rounded-md"
            >
              Sign up
            </button>
          </Form>
        </Formik>
        <div className="flex flex-col">
          <div className="text-sm text-slate-700">By clicking Sign up or registering through a third party you accept the <a className="text-teal-500" target="_blank" rel="noreferrer" href="https://unfix.com/terms-and-conditions">Terms of use</a> and acknowledge the <a className="text-teal-500" target="_blank" rel="noreferrer" href="https://unfix.com/plotter/privacy-policy">Privacy Policy</a></div>
          <div className="border-t my-4 py-4">
            <div>Register with</div>
            <button onClick={handleLogin} className="border border-teal-500 rounded-md px-4 py-2 mt-2">Sign up with Google</button>
          </div>
        </div>
      </div>
    </div>
  );
}
