import { DBStoreItemType } from "../../../../common/types";
import { DndContext, DragEndEvent, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { useContext, useEffect, useState } from "react";
import useCanvasElementsApi from "../../common/hooks/useCanvasElementsApi";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext"
import CanvasElementsPanelItem from "./CanvasElementsPanelItem";
import Spinner from "../../../baseList/Spinner";

type CanvasElementsPanelProps = {
  baseId: string;
  orderedElements: DBStoreItemType[];
  setOrderedElements: (elements: DBStoreItemType[]) => void;
}

export default function CanvasElementsPanel({ baseId, orderedElements, setOrderedElements }: CanvasElementsPanelProps) {

  const [sortableItems, setSortableItems] = useState<string[]>([]);

  const { user } = useContext(AuthContext) as AuthContextType
  const { reorderElements, reorderMutation, setVisibilityMutation } = useCanvasElementsApi({ baseId: baseId, user: user });

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: { distance: 5 },
    }),
    useSensor(TouchSensor, {
      activationConstraint: { distance: 5 },
    })
  );

  useEffect(() => {
    setSortableItems(orderedElements.map((element: DBStoreItemType) => element.id));
  }, [orderedElements])

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    const activeId: string = active.id as string;
    const overId: string = over?.id as string;

    if (activeId !== overId) {
      const oldIndex = sortableItems.indexOf(activeId);
      const newIndex = sortableItems.indexOf(overId);

      const sortedOrderedElements = arrayMove(orderedElements, oldIndex, newIndex);
      setOrderedElements([...sortedOrderedElements]);
      reorderElements(sortedOrderedElements);
    }
  }

  return (
    <div className="relative w-full">
      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <SortableContext items={sortableItems}>
          <div className="absolute z-10 flex flex-col top-0 right-0 w-5/6 md:w-1/2 lg:w-1/3 border-2 bg-white border-teal-500 rounded-md p-2">
            <div className="flex items-center justify-between">
              <div className="font-medium flex items-center">
                {(setVisibilityMutation.isLoading || reorderMutation.isLoading) && <Spinner />}
                <span className="mb-1">Elements</span>
              </div>
              <span>Visible</span>
            </div>
            {orderedElements.map((element: DBStoreItemType) => (
              <CanvasElementsPanelItem key={element.id} baseId={baseId} element={element} />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  )
}
