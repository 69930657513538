import { useParams } from "react-router-dom";
import Spinner from "../components/baseList/Spinner";
import ValueStreamDetails from "../components/baseEditor/editor/valueStream/ValueStreamDetails";
import Breadcrumbs from "../components/baseEditor/common/Breadcrumbs";
import { useContext } from "react";
import { AuthContext, AuthContextType } from "../context/AuthContext";
import useIsAuthenticated from "../hooks/useIsAuthenticated";
import usePaidAccountRequired from "../hooks/usePaidAccountRequired";
import TabLayout from "../components/layout/TabLayout";

export default function ValueStreamEditor() {
  const { baseId, valueStreamId } = useParams();

  const { user } = useContext(AuthContext) as AuthContextType;

  useIsAuthenticated();
  usePaidAccountRequired(baseId);

  return (
    <div className="w-full h-screen p-2">
      <Breadcrumbs />
      {user && <>
        {!(baseId && valueStreamId) && <Spinner width="w-8" height="h-8" border="border-2" />}
        {baseId && valueStreamId && <TabLayout baseId={baseId} firstLabel="Value Stream">
          <ValueStreamDetails baseId={baseId} valueStreamId={valueStreamId} />
        </TabLayout>}
      </>}
    </div>
  );
}
