import { useMutation, useQuery, useQueryClient } from 'react-query';
import { CrewDependencyType, FormCrewDependencyType } from '../../../../common/types';
import { User } from 'firebase/auth';
import { getAllDependecies, getCrewDependecies, getDependency, createDependency, patchDependency, deleteDependency } from '../../../../api/crewDependencyApi';
import ts from 'typescript';
import { useEffect } from 'react';

type useDependencyApiParams = {
  baseId: string;
  user: User | null;
  crewId?: string;
  addSuccessCB?: () => void;
  removeSuccessCB?: () => void;
  patchSuccessCB?: () => void;
  dependencyId?: string;
}

export default function useDependencyApi({
  baseId,
  user,
  crewId,
  addSuccessCB,
  removeSuccessCB,
  patchSuccessCB,
  dependencyId
}: useDependencyApiParams) {
  const queryClient = useQueryClient();
  const dependencyQuery = useQuery(["dependency", baseId, dependencyId],
    // @ts-ignore
    () => getDependency({ user, baseId, dependencyId }),
    { enabled: !!user && !!baseId && !!dependencyId });
  const allDependenciesQuery = useQuery(["dependencies", baseId], () => getAllDependecies({ user, baseId }), { enabled: !!user && !!baseId });
  //@ts-ignore
  const crewDependenciesQuery = useQuery(["dependencies", baseId, crewId], () => getCrewDependecies({ user, baseId, crewId }), { enabled: !!user && !!baseId && !!crewId });
  const createDependencyMutation = useMutation(createDependency);
  const deleteDependencyMutation = useMutation(deleteDependency);
  const patchDependencyMutation = useMutation(patchDependency);

  const addDependency = (formData: FormCrewDependencyType) => {
    createDependencyMutation.mutate({ user, baseId, dependency: formData }, {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["dependencies", baseId]);
        if (crewId) {
          queryClient.invalidateQueries(["dependencies", baseId, crewId]);
        }

        if (addSuccessCB) {
          addSuccessCB();
        }
      },
    });
  }

  const removeDependency = (dependencyId: string) => {
    if (window.confirm("Are you sure you want to delete this Dependency?") === true) {
      deleteDependencyMutation.mutate({ user, baseId, dependencyId }, {
        onSuccess: () => {
          queryClient.invalidateQueries(["dependencies", baseId]);
          if (crewId) {
            queryClient.invalidateQueries(["dependencies", baseId, crewId]);
          }
  
          if (removeSuccessCB) {
            removeSuccessCB();
          }
        }
      });
    }
  }

  const editDependency = (dependency: CrewDependencyType) => {
    if (!dependencyId) {
      return;
    }

    const toSave = {
      ...dependency,
      id: dependencyId,
    };
    patchDependencyMutation.mutate({user, baseId, dependency: toSave}, {
      onSuccess: () => {
        queryClient.invalidateQueries(["dependency", baseId, dependencyId]);
        queryClient.invalidateQueries(["dependencies", baseId]);
        if (crewId) {
          queryClient.invalidateQueries(["dependencies", baseId, crewId]);
        }

        if (patchSuccessCB) {
          patchSuccessCB();
        }
      }
    })
  }

  return {
    dependencyQuery,
    allDependenciesQuery,
    crewDependenciesQuery,
    createDependencyMutation,
    addDependency,
    deleteDependencyMutation,
    removeDependency,
    patchDependencyMutation,
    editDependency,
  };
}
