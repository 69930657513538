import { Group, Image, Rect, Text } from "react-konva";
import { DBForumType, MeasurementType, FetchedBaseType, TeamMemberType } from "../../../../common/types";
import useImage from "use-image";

import { forum_type_attributes } from "../../../../common/constants/forumTypes";
import { useContext, useRef, useState } from "react";
import Konva from "konva";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import ForumMembers from "./ForumMembers";
import useForumApi from "../../common/hooks/useForumApi";
import useForumMemberApi from "../../common/hooks/useForumMemberApi";
import { TooltipContext, TooltipContextType } from "../context/TooltipContext";
import { KonvaEventObject } from "konva/lib/Node";

type ForumElementProps = {
  forum: DBForumType;
  baseId: string;
  base: FetchedBaseType;
  handleMemberHover: (e: KonvaEventObject<MouseEvent>, member: TeamMemberType) => void;
  handleMemberHoverOut: () => void;
};

export default function ForumElement({
  forum,
  baseId,
  base,
  handleMemberHover,
  handleMemberHoverOut
}: ForumElementProps) {

  const { user } = useContext(AuthContext) as AuthContextType;
  const [isTransforming, setTransforming] = useState(false);

  const { editForum } = useForumApi({ baseId: base.id, user, forumId: forum.id });
  const { forumMembersQuery } = useForumMemberApi({ baseId, user, forumId: forum.id, forumMembersGroupBy: "" });

  const { setTooltip } = useContext(TooltipContext) as TooltipContextType;

  const onElementClicked = () => {
    setTooltip(<p>
      This element is a(n) <a target="_blank" href={`${forum_type_attributes[forum.type]["link"]}`} className="text-teal-500">
        {`${forum_type_attributes[forum.type]["label"]}`}
      </a></p>
    )
  }

  // const forumMembersQuery = useQuery(["forum_members", baseId, forum.id],
  //   () => getForumMembers({ user, baseId, forumId: forum.id, groupBy: "" }),
  //   { enabled: !!user && !!baseId && !!forum.id });

  // icon
  const iconFile = forum_type_attributes[forum.type]["icon"];
  const [icon] = useImage(`/unfix/icons/forum/${iconFile}`);

  // transformer
  const shapeRef = useRef<any>();
  const trRef = useRef<any>();

  return (
    <Group
      name="chart_element forum_element"
      id={`forum_${forum.id}`}
      x={forum.measurements.x}
      y={forum.measurements.y}
      width={forum.measurements.width}
      height={forum.measurements.height}
      draggable
      onClick={onElementClicked}
      onTap={onElementClicked}
      ref={shapeRef}
      onDragEnd={(e) => {
        const canvas_width = base.display_options?.canvas_size.width ?? 1280;
        const canvas_height = base.display_options?.canvas_size.height ?? 720;
        let newX = (e.target.x() < 0) ? 50 : (e.target.x() > canvas_width - 50) ? canvas_width - 50 : e.target.x();
        let newY = (e.target.y() < 0) ? 50 : (e.target.y() > canvas_height - 50) ? canvas_height - 50 : e.target.y();

        const measurements: MeasurementType = {
          x: newX,
          y: newY,
          width: forum.measurements.width,
          height: forum.measurements.height,
        };
        editForum({
          ...forum,
          measurements,
        });
      }}
      onTransformStart={(e) => {
        setTransforming(true);
      }}
      onTransformEnd={(e) => {
        setTransforming(false);
        // transformer is changing scale of the node
        // and NOT its width or height
        // but in the store we have only width and height
        // to match the data better we will reset scale on transform end
        const node: Konva.Group = shapeRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        // we will reset it back
        node.scaleX(1);
        node.scaleY(1);

        const measurements: MeasurementType = {
          x: node.x(),
          y: node.y(),
          width: forum.measurements.width * scaleX,
          height: forum.measurements.height * scaleY,
        };
        editForum({
          ...forum,
          measurements,
        });
      }}
    >
      <Rect
        x={0}
        y={0}
        width={forum.measurements.width}
        height={forum.measurements.height}
        fill={forum_type_attributes[forum.type]["color"]}
        cornerRadius={30}
        stroke={"black"}
      />
      <Rect
        x={10}
        y={10}
        width={forum.measurements.width - 20}
        height={forum.measurements.height - 20}
        fill={"white"}
        cornerRadius={30}
        stroke={"black"}
      />
      <Text
        x={0}
        y={20}
        width={forum.measurements.width}
        height={forum.measurements.height * 0.8}
        align="center"
        fontSize={20}
        fill={"black"}
        text={forum.name}
      />
      {
        forumMembersQuery.isSuccess && !isTransforming && (
          <ForumMembers
            baseY={90}
            forum={forum}
            members={forumMembersQuery.data}
            handleMemberHover={handleMemberHover}
            handleMemberHoverOut={handleMemberHoverOut}
          />
        )
      }
      <Image
        image={icon}
        alt=""
        x={forum.measurements.width / 2 - 20}
        y={forum.measurements.height - 60}
        width={40}
        height={40}
      />
    </Group>
  );
}
