import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createCrew, deleteCrew, getCrew, getCrews, patchCrew } from '../../../../api/crewApi';
import { crew_type_attributes } from '../../../../common/constants/crewTypes';
import { DBCrewPatchType, DBCrewType, FetchedCrewType, FormCrewType } from '../../../../common/types';
import { User } from 'firebase/auth';
import { getHorizontalCrewDefaultMeasurements, getVerticalCrewDefaultMeasurements } from '../../canvas/lib/measurements';

type useCrewApiParams = {
  baseId: string;
  user: User | null;
  addSuccessCB?: () => void;
  removeSuccessCB?: () => void;
  patchSuccessCB?: () => void;
  crewId?: string;
}

export default function useCrewApi({
  baseId,
  user,
  crewId,
  addSuccessCB,
  removeSuccessCB,
  patchSuccessCB,
}: useCrewApiParams) {
  const queryClient = useQueryClient();

  const crewQuery = useQuery(["crew", baseId, crewId],
    // @ts-ignore
    () => getCrew({ user, baseId, crewId }),
    { enabled: !!user && !!baseId && !!crewId });

  const crewsQuery = useQuery(["crews", baseId], () => getCrews({ user, baseId }), { enabled: !!user && !!baseId });
  const createCrewMutation = useMutation(createCrew);
  const deleteCrewMutation = useMutation(deleteCrew);
  const patchCrewMutation = useMutation(patchCrew);

  const addCrew = (formData: FormCrewType) => {
    let measurements;
    if (crew_type_attributes[formData.type]["vertical"]) {
      const crewCnt = crewsQuery.data?.filter(
        (crew: FetchedCrewType) => crew_type_attributes[crew.type]["vertical"]
      ).length;
      measurements = getVerticalCrewDefaultMeasurements(crewCnt, crewCnt + 1, 1280, 720);
    } else {
      const crewCnt = crewsQuery.data?.filter(
        (crew: FetchedCrewType) => !crew_type_attributes[crew.type]["vertical"]
      ).length;
      measurements = getHorizontalCrewDefaultMeasurements(crewCnt, crewCnt + 1, 1280, 720);
    }

    let crew: DBCrewType = {
      ...formData,
      id: "",
      base: baseId,
      measurements,
    };

    createCrewMutation.mutate({ user, baseId, crew }, {
      onSuccess: (data) => {
        crew["id"] = data.id;
        let newList = [...crewsQuery.data, crew];
        queryClient.setQueryData(["crews", baseId], newList);
        queryClient.invalidateQueries(["bases", baseId]);

        if (addSuccessCB) {
          addSuccessCB();
        }
        // queryClient.invalidateQueries(["crews", baseId]);
      },
    });
  }

  const removeCrew = (crewId: string) => {
    if (window.confirm("Are you sure you want to delete this Crew?") === true) {
      deleteCrewMutation.mutate({ user, baseId, crewId }, {
        onSuccess: () => {
          const newList = crewsQuery.data.filter((element: DBCrewType) => element.id !== crewId);
          queryClient.setQueryData(["crews", baseId], newList);
          queryClient.invalidateQueries(["bases", baseId]);
          // queryClient.invalidateQueries(["crews", baseId]);
          queryClient.invalidateQueries(["dependencies", baseId], {refetchInactive: true});
          if (crewId) {
            queryClient.invalidateQueries(["dependencies", baseId, crewId], {refetchInactive: true});
          }

          if (removeSuccessCB) {
            removeSuccessCB();
          }
        }
      });
    }
  }

  const editCrew = (crew: DBCrewPatchType) => {
    if (!crewId) {
      return;
    }

    const toSave = {
      ...crew,
      id: crewId,
    };

    patchCrewMutation.mutate({user, baseId, crew: toSave}, {
      onSuccess: () => {
        queryClient.invalidateQueries(["crew", baseId, crewId]);
        queryClient.invalidateQueries(["crews", baseId]);

        if (patchSuccessCB) {
          patchSuccessCB();
        }
      }
    })
  }

  return {
    crewQuery,
    crewsQuery,
    createCrewMutation,
    addCrew,
    deleteCrewMutation,
    removeCrew,
    patchCrewMutation,
    editCrew,
  };
}
