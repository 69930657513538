import useAccountApi from "../baseEditor/common/hooks/useAccountApi";
import { useContext } from "react";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import { Link, useParams } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function BasePaidStatus() {

  const { user } = useContext(AuthContext) as AuthContextType;
  const { baseId } = useParams();
  const { basePaidStatusQuery, hideNotificationClick } = useAccountApi({ user, baseId });

  return (
    <>
      {basePaidStatusQuery.isSuccess && !basePaidStatusQuery.data?.hide_account_notification &&
        <div className="flex items-center justify-between bg-teal-100 p-2 mb-1 rounded-md">
          {!basePaidStatusQuery.data?.email && <div>
            <span className="mr-1">This Base is in Free tier, therefore it is limited to 1 admin member.</span>
            <Link to="/account" className="underline">Upgrade to Premium now to avoid limitations!</Link>
          </div>}
          {basePaidStatusQuery.data?.email && <div>
            <span className="mr-1">
              This Base is in Premium tier. The owner of the subscription
              is {basePaidStatusQuery.data.name} ({basePaidStatusQuery.data.email}).
            </span>
          </div>}
          {baseId && <div><XMarkIcon onClick={() => { hideNotificationClick(baseId)}} className="h-6 w-6" /></div>}
        </div>
      }
    </>
  )
}
