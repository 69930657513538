import { User } from "firebase/auth";
import { BaseMemberType } from "../common/types";

type GetBaseMembersParamsType = {
  user: User | null;
  baseId: string | undefined;
}

export async function getBaseMembers(params: GetBaseMembersParamsType) {
  const {user, baseId} = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/base_members/?base=${baseId}`,
    {
      headers: {
        "Authorization": "" + token,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't fetch base member list");
  }

  return response.json();
}

type CreateBaseMemberParamsType = {
  user: User | null;
  baseId: string | undefined;
  baseMember: BaseMemberType;
  avatarImage: string | null;
};

export async function createBaseMember(params: CreateBaseMemberParamsType) {
  const { user, baseId, baseMember, avatarImage } = params;

  const data = {
    name: baseMember.name,
    email: baseMember.email,
    role: baseMember.role,
    icon: baseMember.icon ?? "",
    base: baseId,
    avatarImage: avatarImage,
  };

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/base_members/?base=${baseId}`,
    {
      method: "POST",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't create base member");
  }

  return response.json();
}

export async function patchBaseMember(params: CreateBaseMemberParamsType) {
  const { user, baseId, baseMember, avatarImage } = params;

  const flattenedBaseMember = {
    name: baseMember.name,
    email: baseMember.email,
    role: baseMember.role,
    icon: baseMember.icon ?? "",
    frontendUserId: baseMember.frontendUserId ?? "",
    base: baseId,
    avatarImage: avatarImage,
  };

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/base_members/${baseMember.id}/?base=${baseId}`,
    {
      method: "PATCH",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...flattenedBaseMember }),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't patch base member");
  }

  return response.json();
}

type DeleteBaseMemberParamsType = {
  user: User | null;
  baseId: string | undefined;
  baseMemberId: string;
};

export async function deleteBaseMember(params: DeleteBaseMemberParamsType) {
  const { user, baseId, baseMemberId } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/base_members/${baseMemberId}/?base=${baseId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't delete base member");
  }
}
