import { User } from "firebase/auth";
import { TeamMemberType } from "../common/types";

type GetForumMembersParamsType = {
  user: User | null;
  baseId: string;
  forumId: string;
  groupBy: string;
}

export async function getForumMembers(params: GetForumMembersParamsType) {
  const {user, baseId, forumId, groupBy} = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/forum_members/?base=${baseId}&forum=${forumId}&group=${groupBy}`,
    {
      headers: {
        "Authorization": "" + token,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't fetch forum member list");
  }

  return response.json();
}

type CreateForumMemberParamsType = {
  user: User | null;
  baseId: string | undefined;
  forumId: string;
  forumMember: TeamMemberType;
  avatarImage: string | null;
};

export async function createForumMember(params: CreateForumMemberParamsType) {
  const { user, baseId, forumId, forumMember, avatarImage } = params;

  const data = {
    baseMemberId: forumMember.baseMemberId ?? "",
    name: forumMember.name,
    email: forumMember.email,
    timeCommitment: forumMember.timeCommitment ?? 0,
    participationLevel: forumMember.participationLevel ?? 0,
    unfixRoles: forumMember.unfixRoles ?? [],
    icon: forumMember.icon ?? "",
    avatarImage: avatarImage,
    base: baseId,
    forum: forumId,
    teamType: 2,
    teamId: forumId,
};

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/forum_members/?base=${baseId}&forum=${forumId}`,
    {
      method: "POST",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't create forum member");
  }

  return response.json();
}

export async function patchForumMember(params: CreateForumMemberParamsType) {
  const { user, baseId, forumId, forumMember, avatarImage } = params;

  const flattenedForumMember = {
    baseMemberId: forumMember.baseMemberId ?? "",
    name: forumMember.name,
    timeCommitment: forumMember.timeCommitment ?? 0,
    participationLevel: forumMember.participationLevel ?? 0,
    unfixRoles: forumMember.unfixRoles ?? [],
    icon: forumMember.icon ?? "",
    avatarImage: avatarImage,
    base: baseId,
    forum: forumId,
    teamType: 2,
    teamId: forumId,
  };

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/forum_members/${forumMember.id}/?base=${baseId}&forum=${forumId}`,
    {
      method: "PATCH",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...flattenedForumMember }),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't patch forum member");
  }

  return response.json();
}

type DeleteForumMemberParamsType = {
  user: User | null;
  baseId: string | undefined;
  forumId: string;
  forumMemberId: string;
};

export async function deleteForumMember(params: DeleteForumMemberParamsType) {
  const { user, baseId, forumId, forumMemberId } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/forum_members/${forumMemberId}/?base=${baseId}&forum=${forumId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't delete forum member");
  }
}
