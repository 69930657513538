import { User } from "firebase/auth";

type UserAsParamType = {
  user: User | null;
}

export async function getCurrentUserAccount(params: UserAsParamType) {
  const {user} = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/accounts/me/`,
    {
      headers: {
        "Authorization": "" + token
      }
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't fetch account");
  }

  return response.json();
}

type GetBasePaidStatusParamsType = {
  user: User | null;
  baseId: string | undefined;
}

export async function getBasePaidStatus(params: GetBasePaidStatusParamsType) {
  const { user, baseId } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/accounts/me/paid_status?base=${baseId}`,
    {
      headers: {
        "Authorization": "" + token
      }
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't fetch account");
  }

  return response.json();
}

type UpgradeBaseParamType = {
  user: User | null;
  baseId: string;
}

export async function upgradeBase(params: UpgradeBaseParamType) {
  const { user, baseId } = params;

  const token = await user?.getIdToken();

  const data = {
    "base_id": baseId,
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/accounts/me/upgrade_base/`,
    {
      method: "POST",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );

  
  if (!response.ok) {
    new Error("Couldn't upgrade Base");
  }
}

type DowngradeBaseParamType = {
  user: User | null;
  baseId: string;
}

export async function downgradeBase(params: DowngradeBaseParamType) {
  const { user, baseId } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/accounts/me/downgrade_base/?base=${baseId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't downgrade Base");
  }
}

type HideNotificationParamType = {
  user: User | null;
  baseId: string;
}

export async function hideNotification(params: HideNotificationParamType) {
  const { user, baseId } = params;

  const token = await user?.getIdToken();

  const data = {
    "base_id": baseId,
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/accounts/me/hide_notification/`,
    {
      method: "POST",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );

  
  if (!response.ok) {
    new Error("Couldn't hide notification");
  }
}

type CheckoutSessionParamType = {
  user: User | null;
  redirect_to: string;
}

export async function createCheckoutSession(params: CheckoutSessionParamType) {
  const { user, redirect_to } = params;

  const token = await user?.getIdToken();

  const data = {
    "redirect_to": redirect_to,
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/accounts/me/checkout_session/`,
    {
      method: "POST",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );

  
  if (!response.ok) {
    new Error("Couldn't create checkout session");
  }

  return response.json();
}
