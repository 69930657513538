import { ChangeEventHandler, FocusEventHandler, useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import Spinner from "../../../baseList/Spinner";
import useBaseApi from "../../common/hooks/useBaseApi";
import { BASE_TYPES } from "../../../../common/constants/baseTypes";

type BasePropsType = {
  baseId: string;
}

export default function Base({ baseId }: BasePropsType) {
  const [isTitleEditing, setTitleEditing] = useState(false);

  const [baseName, setBaseName] = useState("");

  const { user } = useContext(AuthContext) as AuthContextType;

  const queryClient = useQueryClient();

  const {
    baseQuery,
    editBaseMutation,
    editBaseName,
    editBaseType,
  } = useBaseApi({ user, baseId });

  useEffect(() => {

    if (baseQuery.data?.name && !isTitleEditing) {
      setBaseName(baseQuery.data.name);
    }
  }, [baseQuery.data, isTitleEditing]);

  const onTitleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setBaseName(e.target.value);
  };

  const onTitleBlur: FocusEventHandler<HTMLInputElement> = (e) => {

    const newName = e.target.value;

    if (!newName) {
      queryClient.invalidateQueries(["bases", baseId]);
    } else {
      editBaseName(newName);
    }
  }

  const onTypeChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const newType: number = Number(e.target.value);
    editBaseType(newType);
  };

  return (
    <div className="p-2 flex flex-col">
      <div id="base-name" className="mb-2" onBlur={() => setTitleEditing(false)}>
        {!isTitleEditing && (
          <div className="flex">
            {(baseQuery.isFetching || editBaseMutation.isLoading) && <>
              <Spinner width="w-8" height="h-8" border="border-2" />
              <h1 className="text-2xl">Loading...</h1>
            </>}
            {!(baseQuery.isFetching || editBaseMutation.isLoading) &&
              <h1 className="text-2xl w-fit" onClick={() => setTitleEditing(true)}>
                {baseQuery.data?.name}
              </h1>
            }
          </div>
        )}
        {isTitleEditing && (
          <input
            type="text"
            autoFocus
            className="border rounded-md p-1"
            value={baseName}
            onChange={onTitleChange}
            onBlur={onTitleBlur}
          />
        )}
      </div>
      <div id="base-type" className="mb-1">
        <>
          <span>Type: </span>
          <select
            className="border p-1 rounded-md"
            value={baseQuery.data?.type}
            onChange={onTypeChange}
          >
            {BASE_TYPES.map((type) => (
              <option key={type.id} value={type.id}>{type.name}</option>
            ))}
          </select>
        </>
      </div>
    </div>
  );
}
