import { useMutation, useQuery, useQueryClient } from 'react-query';
import { User } from 'firebase/auth';
import { createCrewMember, deleteCrewMember, getCrewMembers, patchCrewMember } from '../../../../api/crewMemberApi';
import { BaseMemberType, FormBaseMemberType, FormTeamMemberType, TeamMemberType } from '../../../../common/types';
import { createBaseMember, deleteBaseMember, getBaseMembers, patchBaseMember } from '../../../../api/baseMemberApi';

type useBaseMemberApiParams = {
  baseId: string;
  user: User | null;
  addSuccessCB?: () => void;
  removeSuccessCB?: () => void;
  patchSuccessCB?: () => void;
}

export default function useBaseMemberApi({
  baseId,
  user,
  addSuccessCB,
  removeSuccessCB,
  patchSuccessCB,
}: useBaseMemberApiParams) {
  const queryClient = useQueryClient();

  const baseMembersQuery = useQuery(["base_members", baseId], () => getBaseMembers({ user, baseId }), { enabled: !!user && !!baseId });
  const createBaseMemberMutation = useMutation(createBaseMember);
  const deleteBaseMemberMutation = useMutation(deleteBaseMember);
  const patchBaseMemberMutation = useMutation(patchBaseMember);

  const addBaseMember = (baseMemberData: FormBaseMemberType, avatarImage: string = "") => {
    let baseMember: BaseMemberType = {
      ...baseMemberData,
      id: "",
    };

    createBaseMemberMutation.mutate({ user, baseId, baseMember, avatarImage }, {
      onSuccess: () => {
        queryClient.invalidateQueries(["base_members", baseId]);
      },
    });
  }


  const removeBaseMember = (baseMemberId: string) => {
    if (window.confirm("Are you sure you want to delete this member?") === true) {
      deleteBaseMemberMutation.mutate({ user, baseId, baseMemberId }, {
        onSuccess: () => {
          queryClient.invalidateQueries(["crews", baseId]);
          queryClient.invalidateQueries(["forums", baseId]);
          queryClient.invalidateQueries(["base_members"]);
          queryClient.invalidateQueries({queryKey: ["crew_members", baseId]});
          queryClient.invalidateQueries({queryKey: ["forum_members", baseId]});
        }
      });
    }
  }

  const editBaseMember = (baseMember: BaseMemberType, avatarImage: string = "") => {
    patchBaseMemberMutation.mutate({user, baseId, baseMember, avatarImage}, {
      onSuccess: () => {
        queryClient.invalidateQueries(["base_members", baseId]);
      }
    })
  }

  return {
    baseMembersQuery,
    createBaseMemberMutation,
    addBaseMember,
    deleteBaseMemberMutation,
    removeBaseMember,
    patchBaseMemberMutation,
    editBaseMember,
  };
}
