import Konva from "konva"
import { RefObject, useContext } from "react"
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import useBaseApi from "../../common/hooks/useBaseApi";
import { ScaleType } from "../../../../hooks/useResponsiveCanvas";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";

type ExportMenuProps = {
  stageRef: RefObject<Konva.Stage>;
  baseId: string;
  canvasScale: ScaleType;
}

export default function DownloadButton({ stageRef, baseId, canvasScale }: ExportMenuProps) {
  const { user } = useContext(AuthContext) as AuthContextType;

  const { baseQuery } = useBaseApi({ user, baseId });

  const downloadURI = (uri: string, name: string) => {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  const handleExport = () => {
    if (stageRef.current && baseQuery.data) {
      const uri = stageRef.current.toDataURL({ pixelRatio: 1 / canvasScale.x });
      const fileName =
        "base_" + baseQuery.data.name.replace(/[^a-zA-Z0-9]/g, "") + ".png";

      downloadURI(uri, fileName);
    }
  };

  return (
    <button
      onClick={handleExport}
      className="flex items-center py-2 px-4 bg-teal-500 text-white rounded-md"
    >
      <ArrowDownTrayIcon className="h-6 w-6 mr-1" />
      <span className="hidden md:inline-block">Download</span>
    </button>
  );
}
