import { Formik, Field, Form, ErrorMessage } from "formik";
import { FormTurfType } from "../../../../common/types";
import { TURF_TYPES, TURF_TYPE_TEAM_OF_TEAMS } from "../../../../common/constants/turfTypes";

interface ITurfFormProps {
  addItem: (turf: FormTurfType) => void;
}

type FormErrorsType = {
  name?: string;
  type?: string;
}

export default function TurfForm({addItem}: ITurfFormProps) {
  const initialValues: FormTurfType = {
    name: "",
    type: TURF_TYPE_TEAM_OF_TEAMS,
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validate={values => {
          const errors: FormErrorsType = {};
          if (!values.name) {
            errors.name = 'Name is required';
          }
          if (!values.type) {
            errors.type = 'Type is required';
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          addItem(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }}
      >
        <Form className="flex flex-col border-t mt-2 pt-2">
          <Field name="name" placeholder="Turf name" className="border rounded-md p-1 my-2" />
          <ErrorMessage className="text-red-700" name="name" component="div" />
          <Field name="type" as="select" className="border rounded-md p-1 my-2">
            {TURF_TYPES.map((type) => <option key={type.id} value={type.id}>{type.name}</option>)}
          </Field>
          <ErrorMessage className="text-red-700" name="type" component="div" />
          <div className="flex justify-end items-center">
            <button type="submit" className="w-1/3 p-2 px-4 my-2 bg-teal-500 text-white rounded-md">Add</button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}
