export const LIFECYCLE_STAGE_INITIATION: number = 1;
const LIFECYCLE_STAGE_EXPEDITION: number = 2;
const LIFECYCLE_STAGE_FORMATION: number = 3;
const LIFECYCLE_STAGE_VALIDATION: number = 4;
const LIFECYCLE_STAGE_STABILIZATION: number = 5;
const LIFECYCLE_STAGE_ACCELERATION: number = 6;
const LIFECYCLE_STAGE_CRYSTALLIZATION: number = 7;
const LIFECYCLE_STAGE_EXPANSION: number = 8;
const LIFECYCLE_STAGE_CONSERVATION: number = 9;
const LIFECYCLE_STAGE_TERMINATION: number = 10;

export const LIFECYCLE_STAGES = [
  {id: LIFECYCLE_STAGE_INITIATION, name: "Initiation"},
  {id: LIFECYCLE_STAGE_EXPEDITION, name: "Expedition"},
  {id: LIFECYCLE_STAGE_FORMATION, name: "Formation"},
  {id: LIFECYCLE_STAGE_VALIDATION, name: "Validation"},
  {id: LIFECYCLE_STAGE_STABILIZATION, name: "Stabilization"},
  {id: LIFECYCLE_STAGE_ACCELERATION, name: "Acceleration"},
  {id: LIFECYCLE_STAGE_CRYSTALLIZATION, name: "Crystallization"},
  {id: LIFECYCLE_STAGE_EXPANSION, name: "Expansion"},
  {id: LIFECYCLE_STAGE_CONSERVATION, name: "Conservation"},
  {id: LIFECYCLE_STAGE_TERMINATION, name: "Termination"},
]

export const lifecycle_stage_attributes = {
  [LIFECYCLE_STAGE_INITIATION]: {
    color: "#610348",
    label: "Initiation",
    icon: "initiation.svg",
    link: "https://unfix.com/initiation-stage",
  },
  [LIFECYCLE_STAGE_EXPEDITION]: {
    color: "#460361",
    label: "Expedition",
    icon: "expedition.svg",
    link: "https://unfix.com/expedition-stage",
  },
  [LIFECYCLE_STAGE_FORMATION]: {
    color: "#100361",
    label: "Formation",
    icon: "formation.svg",
    link: "https://unfix.com/formation-stage",
  },
  [LIFECYCLE_STAGE_VALIDATION]: {
    color: "#032B61",
    label: "Validation",
    icon: "validation.svg",
    link: "https://unfix.com/validation-stage",
  },
  [LIFECYCLE_STAGE_STABILIZATION]: {
    color: "#036161",
    label: "Stabilization",
    icon: "stabilization.svg",
    link: "https://unfix.com/stabilization-stage",
  },
  [LIFECYCLE_STAGE_ACCELERATION]: {
    color: "#03611C",
    label: "Acceleration",
    icon: "acceleration.svg",
    link: "https://unfix.com/acceleration-stage",
  },
  [LIFECYCLE_STAGE_CRYSTALLIZATION]: {
    color: "#206103",
    label: "Crystallization",
    icon: "crystallization.svg",
    link: "https://unfix.com/crystallization-stage",
  },
  [LIFECYCLE_STAGE_EXPANSION]: {
    color: "#566103",
    label: "Expansion",
    icon: "expansion.svg",
    link: "https://unfix.com/expansion-stage",
  },
  [LIFECYCLE_STAGE_CONSERVATION]: {
    color: "#613903",
    label: "Conservation",
    icon: "conservation.svg",
    link: "https://unfix.com/conservation-stage",
  },
  [LIFECYCLE_STAGE_TERMINATION]: {
    color: "#610303",
    label: "Termination",
    icon: "termination.svg",
    link: "https://unfix.com/termination-stage",
  },
}
