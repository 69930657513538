import { Dialog, Switch } from '@headlessui/react'
import useAccountApi from '../baseEditor/common/hooks/useAccountApi';
import { useContext, useState } from 'react';
import { AuthContext, AuthContextType } from '../../context/AuthContext';
import Spinner from './Spinner';

type AddCrewDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  removeBase: (id: string) => void;
  editingBase: string;
  setEditingBase: (id: string) => void;
}

export default function DeleteConfirmDialog({ isOpen, setIsOpen, removeBase, editingBase, setEditingBase }: AddCrewDialogProps) {

  const { user } = useContext(AuthContext) as AuthContextType;
  const [cancelSubscription, setCancelSubscription] = useState<boolean>(false);

  const { basePaidStatusQuery } = useAccountApi({ user, baseId: editingBase});

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        setEditingBase("");
      }}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel className="mx-auto w-full lg:w-1/2 p-4 rounded bg-white">
          <Dialog.Title className="flex items-center">
            {basePaidStatusQuery.isFetching && <Spinner />}
            <span className="text-lg">Delete Base?</span>
          </Dialog.Title>

          <div className="flex flex-col border-t mt-2 pt-2">
            <p className="w-full">Are you sure you want to delete this Base?</p>

            {!!basePaidStatusQuery.data?.email && <div className="flex items-center mt-2">
              <span className="mr-2">Also cancel subscription?</span>
              <Switch
                checked={cancelSubscription}
                onChange={setCancelSubscription}
                className={`${cancelSubscription ? 'bg-teal-100' : 'bg-gray-200'
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span
                  className={`${cancelSubscription ? 'translate-x-6' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-teal-500 transition`}
                />
              </Switch>
            </div>}


            {basePaidStatusQuery.isSuccess && <div className="flex flex-wrap mt-2">
              <div className="w-full md:w-1/2 p-2">
                <button
                  onClick={() => {
                    removeBase(editingBase);
                    setIsOpen(false);
                  }}
                  className="w-full p-2 border bg-teal-500 text-white rounded-md"
                >
                  Yes
                </button>
              </div>
              <div className="w-full md:w-1/2 p-2">
                <button
                  onClick={() => {
                    setEditingBase("");
                    setIsOpen(false);
                  }}
                  className="w-full p-2 border border-teal-500 rounded-md"
                >
                  Cancel
                </button>
              </div>
            </div>}
            </div>

        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
