import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { useFormikContext } from "formik";
import { Fragment, useEffect, useState } from "react";
import { INVESTMENT_HORIZONS } from "../../../../common/constants/investmentHorizons";

type HorizonSelectorPropsType = {
  crewHorizons: number[];
}

export default function InvestmentHorizonSelector({ crewHorizons }: HorizonSelectorPropsType) {
  const formikProps = useFormikContext();
  const [selectedHorizons, setSelectedHorizons] = useState<number[]>([...crewHorizons]);
  const [horizonPlaceholder, setHorizonPlaceholder] = useState("");

  useEffect(() => {
    setSelectedHorizons([...crewHorizons]);
    formikProps.setFieldValue("investmentHorizons", [...crewHorizons]);
  }, [crewHorizons]);

  useEffect(() => {
    let placeholder;
    if (selectedHorizons.length > 0) {
      placeholder = INVESTMENT_HORIZONS.filter(
        (horizon) => selectedHorizons.includes(horizon.id)
      ).map((horizon) => horizon.name).join(', ');
    } else {
      placeholder = "None";
    }
    
    setHorizonPlaceholder(placeholder);
  }, [selectedHorizons])

  const horizonsSelected = (horizions: number[]) => {
    setSelectedHorizons(horizions);
    formikProps.setFieldValue("investmentHorizons", horizions);
  }

  return (
    <div className="relative w-full">
      <Listbox value={selectedHorizons} onChange={horizonsSelected} multiple>
        <Listbox.Button as="div" className="flex items-center justify-between w-full my-2">
          <span className="w-full p-1 border border-grey-300 rounded-md">
            {horizonPlaceholder}
          </span>
          <ChevronUpDownIcon className="w-4 h-4 ml-1" />
        </Listbox.Button>
        <Listbox.Options className="absolute z-10 flex flex-col right-0 top-10 max-h-60 w-full overflow-auto rounded-md border-2 border-teal-500">
          {INVESTMENT_HORIZONS.map((horizon) => (
            <Listbox.Option key={horizon.id} value={horizon.id} as={Fragment}>
              {({active, selected}) => (
                <li className={`flex items-center p-1 ${active ? 'bg-teal-500 text-white': 'bg-white text-black'}`}>
                  {selected && <CheckIcon className={`w-4 h-4 mr-2 stroke-[4px] ${active ? 'text-white': 'text-teal-500'}`} />}
                  {horizon.name}
                </li>
              )}

            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  )

}
