import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext, AuthContextType } from "../context/AuthContext";

export default function useIsAuthenticated() {

  const { user } = useContext(AuthContext) as AuthContextType;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let timer = setTimeout(() => {
      let redirect_url = "/sign_in";
      redirect_url = redirect_url + "?redirect_to=" + encodeURIComponent(location.pathname + location.search);

      if (!user) {
        navigate(redirect_url);
      }
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, [user, navigate]);
}
