export default function Spinner({ width = "w-8", height = "h-8", border = "border-2", border_color="border-teal-500", text = "" }) {
  return (
    <>
      <div className={`${height} ${width} p-2 flex justify-center items-center`}>
        <div className={`w-full h-full animate-spin border-t-transparent border-solid rounded-full ${border_color} ${border}`}></div>
        {text && (<span>{text}</span>)}
      </div>
    </>
  );
};
