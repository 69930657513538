import { User } from "firebase/auth";
import { auth } from "../firebase";

type UserAsParamType = {
  user: User | null;
}

export async function getCurrentUser(params: UserAsParamType) {
  const {user} = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/users/me/`,
    {
      headers: {
        "Authorization": "" + token
      }
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't fetch user");
  }

  return response.json();

}

type NewUserType = {
  name: string | null;
  email: string | null;
  slack_id: string | null;
};

export async function saveUser(newUser: NewUserType) {
  const token = await auth.currentUser?.getIdToken();
  
  const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/users/`, {
    method: "POST",
    headers: {
      "Authorization": "" + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newUser),
  });

  if (!response.ok) {
    throw new Error("Couldn't save user");
  }  
}

type PatchUserParams = {
  user: User | null;
  data: any
};

export async function patchUser(params: PatchUserParams) {
  const { user, data } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/users/me/`,
    {
      method: "PATCH",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't patch user");
  }

  return response.json();
}

export async function newsletterRemindMeLater(params: UserAsParamType) {
  const { user } = params;

  const data = {
    "newsletter.remindAfter": new Date().getTime() + (7 * 24 * 3600 * 1000),
  }

  return patchUser({user, data});
}

export async function newsletterDontAskMe(params: UserAsParamType) {
  const { user } = params;

  const data = {
    "newsletter.dontAskMe": true,
  }

  return patchUser({user, data});
}

type NewsletterSubscribeParamsType = {
  user: User | null;
  email: string;
}

export async function newsletterSubscribe(params: NewsletterSubscribeParamsType) {
  const { user, email } = params;

  const data = {
    "newsletter.subscribedAt": new Date().getTime(),
    "newsletter.email": email,
  }

  return patchUser({user, data});
}

export async function verifyEmail(params: UserAsParamType) {
  const { user } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/users/me/verify_email/`,
    {
      method: "PATCH",
      headers: {
        Authorization: "" + token,
      },
      body: JSON.stringify({}),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't patch user");
  }

}