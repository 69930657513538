import { useMutation, useQuery, useQueryClient } from 'react-query';
import { User } from 'firebase/auth';
import { downgradeBase, getBasePaidStatus, getCurrentUserAccount, hideNotification, upgradeBase } from '../../../../api/accountApi';

type useAccountApiParams = {
  user: User | null;
  baseId?: string;
  upgradeSuccessCB?: () => void;
  downgradeSuccessCB?: () => void;
}

export default function useAccountApi({
  user,
  baseId,
  upgradeSuccessCB,
  downgradeSuccessCB,
}: useAccountApiParams) {
  const queryClient = useQueryClient();

  const accountQuery = useQuery(['currentUserAccount'], () => getCurrentUserAccount({ user }), { enabled: !!user })
  const basePaidStatusQuery = useQuery(['basePaidStatus', baseId], () => getBasePaidStatus({ user, baseId }), { enabled: !!user && !!baseId })
  const downgradeBaseMutation = useMutation(downgradeBase);
  const upgradeBaseMutation = useMutation(upgradeBase);
  const hideNotificationMutation = useMutation(hideNotification);

  const upgradeBaseClick = (baseId: string) => {
    upgradeBaseMutation.mutate({ user, baseId }, {
      onSuccess: () => {
        queryClient.invalidateQueries(["bases"]);
        queryClient.invalidateQueries(["currentUserAccount"]);
        queryClient.invalidateQueries(["basePaidStatus", baseId]);
        if (upgradeSuccessCB) {
          upgradeSuccessCB();
        }
      }
    });
  }

  const downgradeBaseClick = (baseId: string) => {
    if (window.confirm("Are you sure you want to downgrade this Base?") === true) {
      downgradeBaseMutation.mutate({ user, baseId }, {
        onSuccess: () => {
          queryClient.invalidateQueries(["currentUserAccount"]);
          queryClient.invalidateQueries(["basePaidStatus", baseId]);
          if (downgradeSuccessCB) {
            downgradeSuccessCB();
          }
        }
      });
    }
  }

  const hideNotificationClick = (baseId: string) => {
    hideNotificationMutation.mutate({ user, baseId }, {
      onSuccess: () => {
        queryClient.invalidateQueries(['basePaidStatus', baseId]);
      }
    })
  }

  return {
    accountQuery,
    basePaidStatusQuery,
    upgradeBaseClick,
    downgradeBaseClick,
    hideNotificationClick,
  };
}
