import { Circle, Image, Text } from "react-konva";
import { lifecycle_stage_attributes } from "../../../../common/constants/lifecycleStages";
import useImage from "use-image";
import { useContext } from "react";
import { TooltipContext, TooltipContextType } from "../context/TooltipContext";

type CrewLifecycleStageProps = {
  x: number;
  y: number;
  lifecycleStage: number;
}

export default function CrewLifecycleStage({x, y, lifecycleStage}: CrewLifecycleStageProps) {
  // icon
  const lifecycleStageIconFile = lifecycle_stage_attributes[lifecycleStage]["icon"];
  const [lifecycleStageIcon] = useImage(`/unfix/icons/lifecycle_stage/${lifecycleStageIconFile}`);

  const {setTooltip} = useContext(TooltipContext) as TooltipContextType;

  const onElementClicked = (e: any) => {
    e.cancelBubble = true;

    setTooltip(<p>
      This icon is the <a target="_blank" href={`${lifecycle_stage_attributes[ lifecycleStage ]["link"]}`} className="text-teal-500">
        {`${lifecycle_stage_attributes[ lifecycleStage ]["label"]}`} Lifecycle Stage
      </a></p>
    )
  }

  return (
    <>
      <Circle
        onClick={onElementClicked}
        onTap={onElementClicked}
        x={x}
        y={y}
        radius={20}
        fill={lifecycle_stage_attributes[lifecycleStage]["color"]}
        stroke={lifecycle_stage_attributes[lifecycleStage]["color"]}
      />
      <Text
        onClick={onElementClicked}
        onTap={onElementClicked}
        x={x - 20}
        y={y - 10}
        width={40}
        fontSize={20}
        fill="white"
        text={`${lifecycleStage}`}
        align="center"
        verticalAlign="center"
      />
      <Circle
        onClick={onElementClicked}
        onTap={onElementClicked}
        x={(x) + 20 * Math.sin(Math.PI / 6 * lifecycleStage)}
        y={y - 20 * Math.cos(Math.PI / 6 * lifecycleStage)}
        radius={10}
        fill="white"
        stroke={lifecycle_stage_attributes[lifecycleStage]["color"]}
      />
      <Image
        onClick={onElementClicked}
        onTap={onElementClicked}
        image={lifecycleStageIcon}
        alt=""
        x={(x - 10) + 20 * Math.sin(Math.PI / 6 * lifecycleStage)}
        y={y - 20 * Math.cos(Math.PI / 6 * lifecycleStage) - 10}
        width={20}
        height={20}
      />
    </>

  )
}