import { User } from "firebase/auth";
import { useMutation, useQueryClient } from "react-query";
import { canvasAutoLayout, reorderCanvasElements, resizeCanvasAndElements, setCanvasElementVisibility } from "../../../../api/canvasElementApi";
import { CanvasElementType, CanvasResizeType, DBStoreItemType } from "../../../../common/types";

type useCanvasElementsApiParamas = {
  user: User | null;
  baseId: string | undefined;
}

export default function useCanvasElementsApi({ baseId, user }: useCanvasElementsApiParamas) {
  const reorderMutation = useMutation(reorderCanvasElements);
  const setVisibilityMutation = useMutation(setCanvasElementVisibility);
  const resizeCanvasMutation = useMutation(resizeCanvasAndElements);
  const autoLayoutMutation = useMutation(canvasAutoLayout);
  const queryClient = useQueryClient();

  const reorderElements = (elements: DBStoreItemType[]) => {

    // @ts-ignore
    const canvasElements: CanvasElementType[] = elements.filter((element: DBStoreItemType) => element.elementType).map((element: DBStoreItemType) => {
      return {
        id: element.id,
        type: element.elementType,
        visible: element.visible ?? true,
      }
    })

    reorderMutation.mutate({ user, baseId, elements: canvasElements }, {
      onSuccess: () => {
        queryClient.invalidateQueries(["bases", baseId]);
      }
    });
  }

  const setVisibility = (element: CanvasElementType) => {
    setVisibilityMutation.mutate({ user, baseId, element }, {
      onSuccess: () => {
        queryClient.invalidateQueries(["bases", baseId]);
      }
    });
  }

  const resizeCanvas = (data: CanvasResizeType, setIsOpen: (isOpen: boolean) => void) => {
    resizeCanvasMutation.mutate({ user, baseId, data }, {
      onSuccess: () => {

        queryClient.invalidateQueries(["bases", baseId]);

        if (data.resize_horizontal) {
          queryClient.invalidateQueries(["crews", baseId]);
          queryClient.invalidateQueries(["value_streams", baseId]);
        }
        if (data.resize_vertical) {
          queryClient.invalidateQueries(["crews", baseId]);
          queryClient.invalidateQueries(["forums", baseId]);
          queryClient.invalidateQueries(["turfs", baseId]);
        }

        setIsOpen(false);
      }
    });
  }

  const autoLayout = () => {
    autoLayoutMutation.mutate({ user, baseId }, {
      onSuccess: () => {
        queryClient.invalidateQueries(["bases", baseId]);
        queryClient.invalidateQueries(["crews", baseId]);
        queryClient.invalidateQueries(["forums", baseId]);
        queryClient.invalidateQueries(["turfs", baseId]);
        queryClient.invalidateQueries(["value_streams", baseId]);
      }
    });
  }

  return {
    reorderElements,
    reorderMutation,
    setVisibility,
    setVisibilityMutation,
    resizeCanvas,
    resizeCanvasMutation,
    autoLayout,
    autoLayoutMutation,
  }
}
