import { useMutation, useQuery, useQueryClient } from "react-query";
import { User } from 'firebase/auth';
import { createBase, deleteBase, getBase, getBases, patchBase } from "../../../../api/baseApi";
import { CanvasSizeType, DisplayOptionKeyType } from "../../../../common/types";

type useBaseApiParams = {
  user: User | null;
  baseId?: string;
  addSuccessCB?: () => void;
  patchSuccessCB?: () => void;
  removeSuccessCB?: () => void;
  removeErrorCB?: () => void;
}

export default function useBaseApi({ user, baseId, addSuccessCB, removeSuccessCB, removeErrorCB }: useBaseApiParams) {
  const queryClient = useQueryClient();
  const basesQuery = useQuery(["bases"], () => getBases({ user }), { enabled: !!user });
  // @ts-ignore
  const baseQuery = useQuery(["bases", baseId], () => getBase({ user: user, baseId: baseId }), { enabled: !!baseId && !!user });
  const createBaseMutation = useMutation(createBase);
  const deleteBaseMutation = useMutation(deleteBase);
  const editBaseMutation = useMutation(patchBase);

  const addBase = () => {
    createBaseMutation.mutate(undefined,
      {
        onSuccess: (data, variables, context) => {
          queryClient.invalidateQueries(["bases"]);

          if (addSuccessCB) {
            addSuccessCB();
          }
        },
        onError: () => {
          alert("Could not save base. Please try again later!");
        },
      })
  }

  const removeBase = (baseId: string, cancelSubscription: number = 0) => {
    // if (window.confirm("Are you sure you want to delete this Base?") === true) {
      deleteBaseMutation.mutate({user, baseId, cancelSubscription},
        {
          onSuccess: (data, variables, context) => {
            // console.log("base deleted");
            queryClient.invalidateQueries(["bases"]);

            if (removeSuccessCB) {
              removeSuccessCB();
            }
          },
          onError: () => {
            alert("Could not delete base. Please try again later!");

            if (removeErrorCB) {
              removeErrorCB();
            }
          },
        }
      )
    // }
  }

  const editBase = (values: any) => {
    if (!baseId) {
      return;
    }

    editBaseMutation.mutate({ user, baseId, values }, {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["bases"]);
        queryClient.invalidateQueries(["bases", baseId]);
        if (data.revision_ids) {
          for (let revision_id of data.revision_ids) {
            queryClient.invalidateQueries(["bases", revision_id]);
          }
        }
      }
    });

  }

  const setDisplayOption = (attribute: DisplayOptionKeyType, value: boolean | CanvasSizeType) => {

    if (!baseId) {
      return;
    }

    const key = "display_options." + attribute;

    editBaseMutation.mutate({
      user, baseId, values: {
        [key]: value
      }
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries(["bases", baseId]);
      }
    });
  }

  const editBaseName = (newName: string) => {
    editBase({
      name: newName
    })
  }

  const editBaseType = (newType: number) => {
    editBase({
      type: newType
    });
  }

  return {
    basesQuery,
    baseQuery,
    createBaseMutation,
    addBase,
    editBaseMutation,
    editBaseName,
    editBaseType,
    setDisplayOption,
    deleteBaseMutation,
    removeBase,
  }

}
