import { User } from "firebase/auth";
import { DBTurfPatchType, DBTurfType } from "../common/types";

type GetTurfsParamsType = {
  user: User | null;
  baseId: string;
}

export async function getTurfs(params: GetTurfsParamsType) {
  const {user, baseId} = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/turfs/?base=${baseId}`,
    {
      headers: {
        "Authorization": "" + token,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't fetch turf list");
  }

  return response.json();
}

type GetTurfParamsType = {
  user: User | null;
  baseId: string;
  turfId: string;
};

export async function getTurf(params: GetTurfParamsType) {
  const { user, baseId, turfId } = params;
  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/turfs/${turfId}/?base=${baseId}`,
    {
      headers: {
        "Authorization": "" + token
      }
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't fetch turf");
  }

  return response.json();
}

type CreateTurfParamsType = {
  user: User | null;
  baseId: string | undefined;
  turf: DBTurfType;
};

export async function createTurf(params: CreateTurfParamsType) {
  const { user, baseId, turf } = params;

  const flattenedTurf = {
    name: turf.name,
    type: turf.type,
    base: turf.base,
    measurements: turf.measurements,
  };

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/turfs/?base=${baseId}`,
    {
      method: "POST",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...flattenedTurf }),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't create turf");
  }

  return response.json();
}

type PatchTurfParamsType = {
  user: User | null;
  baseId: string | undefined;
  turf: DBTurfPatchType;
};

export async function patchTurf(params: PatchTurfParamsType) {
  const { user, baseId, turf } = params;

  const flattenedTurf = {
    name: turf.name,
    type: turf.type,
    base: baseId,
    measurements: turf.measurements,
  };

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/turfs/${turf.id}/?base=${baseId}`,
    {
      method: "PATCH",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...flattenedTurf }),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't patch turf");
  }

  return response.json();
}

type DeleteTurfParamsType = {
  user: User | null;
  baseId: string | undefined;
  turfId: string;
};

export async function deleteTurf(params: DeleteTurfParamsType) {
  const { user, baseId, turfId } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/turfs/${turfId}/?base=${baseId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't delete turf");
  }
}
