import { useContext } from "react";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import Spinner from "../../../baseList/Spinner";
import useValueStreamApi from "../../common/hooks/useValueStreamApi";
import ValueStreamEditForm from "./ValueStreamEditForm";

type ValueStreamDetailsPropsType = {
  baseId: string;
  valueStreamId: string;
}

export default function ValueStreamDetails({ baseId, valueStreamId }: ValueStreamDetailsPropsType) {
  const { user } = useContext(AuthContext) as AuthContextType;

  const {valueStreamQuery, editValueStream} = useValueStreamApi({baseId, user, valueStreamId})


  return (

    <div id="value_stream_details" className="w-full">
      <div className="py-2 md:px-2 w-full">
        <div className="border rounded-md p-2">
          <div className="flex mb-2">
            {(valueStreamQuery.isFetching) &&
              <Spinner width="w-8" height="h-8" border="border-2" />}
            <h2 className="text-xl">Value Stream Details</h2>
          </div>
          
          {valueStreamQuery.isSuccess && valueStreamQuery.data && <ValueStreamEditForm valueStream={valueStreamQuery.data} editItem={editValueStream} />}

        </div>
      </div>
    </div>
  );
}
