import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import { BaseMemberType } from "../../../../common/types";

import { ref, getDownloadURL } from "firebase/storage";
import { avatarStorage } from "../../../../firebase";
import { useEffect, useState } from "react";
import { Menu } from "@headlessui/react";
import { useDraggable } from "@dnd-kit/core";

type BaseMemberProps = {
  baseMember: BaseMemberType;
  setEditingMember: (baseMember: BaseMemberType) => void;
  deleteItem: (memberId: string) => void;
  isTeamsPanelOpen: boolean;
};

export const BASE_MEMBER_ROLE_RESOURCE: number = 1;
// const BASE_MEMBER_ROLE_VIEWER: number = 2;
// const BASE_MEMBER_ROLE_EDITOR: number = 3;
export const BASE_MEMBER_ROLE_ADMIN: number = 4;

export const BASE_MEMBER_ROLES = [
  { id: BASE_MEMBER_ROLE_RESOURCE, name: "Resource (no access)" },
  // { id: BASE_MEMBER_ROLE_VIEWER, name: "Viewer" },
  // { id: BASE_MEMBER_ROLE_EDITOR, name: "Editor" },
  { id: BASE_MEMBER_ROLE_ADMIN, name: "Admin (editor access)" },
];

const role_attributes = {
  [BASE_MEMBER_ROLE_RESOURCE]: {
    color: "#FAD45A",
  },
  // [BASE_MEMBER_ROLE_VIEWER]: {
  //   color: "#5ABDFA",
  // },
  // [BASE_MEMBER_ROLE_EDITOR]: {
  //   color: "#5AFA84",
  // },
  [BASE_MEMBER_ROLE_ADMIN]: {
    color: "#715AFA",
  },
};

export default function BaseMember({ baseMember, setEditingMember, deleteItem, isTeamsPanelOpen }: BaseMemberProps) {
  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: baseMember.id,
    data: {
      baseMember,
    },
    disabled: !isTeamsPanelOpen,
  });

  const [imageUrl, setImageUrl] = useState<string>("");

  let borderColor =
    baseMember.role in role_attributes
      ? role_attributes[baseMember.role]["color"]
      : "";

  useEffect(() => {
    if (baseMember.avatar) {
      const imgRef = ref(avatarStorage, baseMember.avatar);

      getDownloadURL(imgRef).then((url) => {
        setImageUrl(url);
      });
    }

  })

  return (
    <span
      className={`p-1 w-full ${isTeamsPanelOpen ? "lg:w-1/2" : "md:w-1/2 lg:w-1/3"}`}
    >
      <span className={`p-1 flex items-center justify-between bg-slate-100 border-2 rounded-md`} style={{ borderColor: borderColor }}>
        <div
          ref={setNodeRef} {...listeners} {...attributes}
          className={`w-full ${isTeamsPanelOpen ? "cursor-grab touch-none" : ""}`}
        >
          {baseMember.name}
        </div>
        <span className="flex items-center">
          {imageUrl && <div className="w-4 h-4 mr-1 rounded-lg border bg-contain bg-center bg-no-repeat bg-clip-content" style={{ backgroundImage: `url("${imageUrl}")` }} />}
          {!imageUrl && baseMember.icon && <div className="w-4 h-4 mr-1 flex items-center justify-center">{baseMember.icon}</div>}

          <div className="relative">
            <Menu>
              <Menu.Button className="flex items-center">
                <AdjustmentsHorizontalIcon className="ml-1 w-6 h-6" />
              </Menu.Button>
              <Menu.Items as="div" className="flex flex-col absolute right-0 top-5 z-10 bg-white rounded-md border-2 text-black" style={{ borderColor: borderColor }}>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => setEditingMember(baseMember)}
                      className="py-2 px-4 whitespace-nowrap ui-active:text-white"
                      style={active ? { backgroundColor: borderColor } : {}}
                    >
                      Edit
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => deleteItem(baseMember.id)}
                      className="py-2 px-4 whitespace-nowrap ui-active:bg-teal-500 ui-active:text-white"
                      style={active ? { backgroundColor: borderColor } : {}}
                    >
                      Delete
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </div>

          {/* <PencilIcon className="w-4 h-4 mr-1 cursor-pointer" onClick={() => setEditingMember(baseMember)} />
          <TrashIcon className="w-4 h-4 cursor-pointer" onClick={() => deleteItem(baseMember.id)} /> */}
        </span>
      </span>
    </span>
  );
}
