import { Group, Image, Rect, Text } from "react-konva";
import { DBTurfType, FetchedBaseType, MeasurementType } from "../../../../common/types";
import useImage from "use-image";
import { useContext, useRef } from "react";
import Konva from "konva";
import { turf_type_attributes } from "../../../../common/constants/turfTypes";
import useTurfApi from "../../common/hooks/useTurfApi";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import { TooltipContext, TooltipContextType } from "../context/TooltipContext";

type TurfElementProps = {
  turf: DBTurfType;
  base: FetchedBaseType;
};

export default function TurfElement({
  turf,
  base,
}: TurfElementProps) {

  const { user } = useContext(AuthContext) as AuthContextType;
  const { editTurf } = useTurfApi({ baseId: base.id, user, turfId: turf.id });

  const { setTooltip } = useContext(TooltipContext) as TooltipContextType;

  const onElementClicked = () => {
    setTooltip(<p>
      This element is a(n) <a target="_blank" href={`${turf_type_attributes[turf.type]["link"]}`} className="text-teal-500">
        {`${turf_type_attributes[turf.type]["label"]}`}
      </a></p>
    )
  }

  // icon
  const iconPath = turf.type ? `/unfix/icons/turf/${turf_type_attributes[turf.type]["icon"]}` : "/unfix/icons/turf.svg";
  const [icon] = useImage(iconPath);

  // transformer
  const shapeRef = useRef<any>();

  return (
    <Group
      name="chart_element turf_element"
      id={`turf_${turf.id}`}
      x={turf.measurements.x}
      y={turf.measurements.y}
      width={turf.measurements.width}
      height={turf.measurements.height}
      draggable
      onClick={onElementClicked}
      onTap={onElementClicked}
      ref={shapeRef}
      onDragEnd={(e) => {
        const canvas_width = base.display_options?.canvas_size.width ?? 1280;
        const canvas_height = base.display_options?.canvas_size.height ?? 720;
        let newX = (e.target.x() < 0) ? 50 : (e.target.x() > canvas_width - 50) ? canvas_width - 50 : e.target.x();
        let newY = (e.target.y() < 0) ? 50 : (e.target.y() > canvas_height - 50) ? canvas_height - 50 : e.target.y();

        const measurements: MeasurementType = {
          x: newX,
          y: newY,
          width: turf.measurements.width,
          height: turf.measurements.height,
        };
        // editMeasurements("turfs", turf, measurements);
        editTurf({
          ...turf,
          measurements: measurements,
        });
      }}
      onTransformEnd={(e) => {
        // transformer is changing scale of the node
        // and NOT its width or height
        // but in the store we have only width and height
        // to match the data better we will reset scale on transform end
        const node: Konva.Group = shapeRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        // we will reset it back
        node.scaleX(1);
        node.scaleY(1);

        const measurements: MeasurementType = {
          x: node.x(),
          y: node.y(),
          width: turf.measurements.width * scaleX,
          height: turf.measurements.height * scaleY,
        };
        // editMeasurements("turfs", turf, measurements);
        editTurf({
          ...turf,
          measurements: measurements,
        })
      }}
    >
      <Rect
        x={0}
        y={0}
        width={turf.measurements.width}
        height={turf.measurements.height}
        fill={"#f3f4f6"}
        // stroke={"#78716c"}
        stroke={turf.type ? turf_type_attributes[turf.type]["color"] : "#78716c"}
      />
      <Text
        x={20}
        y={10}
        width={turf.measurements.width - 40}
        height={20}
        fontSize={20}
        fill={"black"}
        align="left"
        text={turf.name}
      />
      <Image
        image={icon}
        alt=""
        x={turf.measurements.width / 2 - 20}
        y={turf.measurements.height - 60}
        width={40}
        height={40}
      />
    </Group>
  );
}
