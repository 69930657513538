import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import { TeamMemberType } from "../../../../common/types";

// import { ReactComponent as CaptainRoleAttributeIcon } from './../../common/role_attribute/captain.svg'
// import { ReactComponent as ChairRoleAttributeIcon } from './../../common/role_attribute/chair.svg';
// import { ReactComponent as ChiefRoleAttributeIcon } from './../../common/role_attribute/chief.svg';

import * as RoleAttributeIcons from "../../common/RoleAttributeIcons";
import { MouseEvent, useEffect, useState } from "react";
import { ref, getDownloadURL } from "firebase/storage";
import { avatarStorage } from "../../../../firebase";
import { Menu } from "@headlessui/react";

type TeamMemberProps = {
  teamMember: TeamMemberType;
  deleteItem: (memberId: string) => void;
  setEditingMember: (teamMember: TeamMemberType|null) => void;
  setShowAddForm: (show: boolean) => void;
};

export const CREW_MEMBER_TIME_COMMITMENT_FIXED: number = 1;
const CREW_MEMBER_TIME_COMMITMENT_FLEXIBLE: number = 2;
const CREW_MEMBER_TIME_COMMITMENT_TEMPORARY: number = 3;
const CREW_MEMBER_TIME_COMMITMENT_TURBULENT: number = 4;

export const CREW_MEMBER_TIME_COMMITMENTS = [
  { id: CREW_MEMBER_TIME_COMMITMENT_FIXED, name: "Fixed" },
  { id: CREW_MEMBER_TIME_COMMITMENT_FLEXIBLE, name: "Flexible" },
  { id: CREW_MEMBER_TIME_COMMITMENT_TEMPORARY, name: "Temporary" },
  { id: CREW_MEMBER_TIME_COMMITMENT_TURBULENT, name: "Turbulent" },
];

export const timeCommitment_attributes = {
  [CREW_MEMBER_TIME_COMMITMENT_FIXED]: {
    color: "#E03006",
    label: "Fixed",
  },
  [CREW_MEMBER_TIME_COMMITMENT_FLEXIBLE]: {
    color: "#4ADF07",
    label: "Flexible",
  },
  [CREW_MEMBER_TIME_COMMITMENT_TEMPORARY]: {
    color: "#33D3F9",
    label: "Temporary",
  },
  [CREW_MEMBER_TIME_COMMITMENT_TURBULENT]: {
    color: "#C033F9",
    label: "Turbulent",
  },
};

export const CREW_MEMBER_PARTICIPATION_LEVEL_LEADER: number = 1;
const CREW_MEMBER_PARTICIPATION_LEVEL_CONTRIBUTOR: number = 2;
const CREW_MEMBER_PARTICIPATION_LEVEL_ATTENDEE: number = 3;
const CREW_MEMBER_PARTICIPATION_LEVEL_REGULAR: number = 4;
const CREW_MEMBER_PARTICIPATION_LEVEL_VISITOR: number = 5;
const CREW_MEMBER_PARTICIPATION_LEVEL_STRANGER: number = 6;

export const CREW_MEMBER_PARTICIPATION_LEVELS = [
  { id: CREW_MEMBER_PARTICIPATION_LEVEL_LEADER, name: "Leader" },
  { id: CREW_MEMBER_PARTICIPATION_LEVEL_CONTRIBUTOR, name: "Contributor" },
  { id: CREW_MEMBER_PARTICIPATION_LEVEL_ATTENDEE, name: "Attendee" },
  { id: CREW_MEMBER_PARTICIPATION_LEVEL_REGULAR, name: "Regular" },
  { id: CREW_MEMBER_PARTICIPATION_LEVEL_VISITOR, name: "Visitor" },
  { id: CREW_MEMBER_PARTICIPATION_LEVEL_STRANGER, name: "Stranger" },
];

export const participationLevel_attributes = {
  [CREW_MEMBER_PARTICIPATION_LEVEL_LEADER]: {
    color: "#9B33F9",
    label: "Leader",
  },
  [CREW_MEMBER_PARTICIPATION_LEVEL_CONTRIBUTOR]: {
    color: "#F9CA33",
    label: "Contributor",
  },
  [CREW_MEMBER_PARTICIPATION_LEVEL_ATTENDEE]: {
    color: "#33D3F9",
    label: "Attendee",
  },
  [CREW_MEMBER_PARTICIPATION_LEVEL_REGULAR]: {
    color: "#F9339F",
    label: "Regular",
  },
  [CREW_MEMBER_PARTICIPATION_LEVEL_VISITOR]: {
    color: "#BCF933",
    label: "Visitor",
  },
  [CREW_MEMBER_PARTICIPATION_LEVEL_STRANGER]: {
    color: "#7F7F7F",
    label: "Stranger",
  },
};

export const TEAM_MEMBER_ROLE_CHIEF: number = 1;
const TEAM_MEMBER_ROLE_CAPTAIN: number = 2;
const TEAM_MEMBER_ROLE_CHAIR: number = 3;
const TEAM_MEMBER_ROLE_ENABLER: number = 4;
const TEAM_MEMBER_ROLE_SCRIBE: number = 5;
const TEAM_MEMBER_ROLE_ENVOY: number = 6;
const TEAM_MEMBER_ROLE_AGENT: number = 7;
const TEAM_MEMBER_ROLE_GUIDE: number = 8;
const TEAM_MEMBER_ROLE_PRODUCER: number = 9;
const TEAM_MEMBER_ROLE_DIRECTOR: number = 10;
const TEAM_MEMBER_ROLE_GURU: number = 11;
const TEAM_MEMBER_ROLE_CREATOR: number = 12;
const TEAM_MEMBER_ROLE_PERFORMER: number = 13;
const TEAM_MEMBER_ROLE_MAVEN: number = 14;
const TEAM_MEMBER_ROLE_CUSTODIAN: number = 15;
const TEAM_MEMBER_ROLE_INSPECTOR: number = 16;

export const TEAM_MEMBER_ROLES = [
  { id: TEAM_MEMBER_ROLE_CHIEF, name: "Chief" },
  { id: TEAM_MEMBER_ROLE_CAPTAIN, name: "Captain" },
  { id: TEAM_MEMBER_ROLE_CHAIR, name: "Chair" },
  { id: TEAM_MEMBER_ROLE_ENABLER, name: "Enabler" },
  { id: TEAM_MEMBER_ROLE_SCRIBE, name: "Scribe" },
  { id: TEAM_MEMBER_ROLE_ENVOY, name: "Envoy" },
  { id: TEAM_MEMBER_ROLE_AGENT, name: "Agent" },
  { id: TEAM_MEMBER_ROLE_GUIDE, name: "Guide" },
  { id: TEAM_MEMBER_ROLE_PRODUCER, name: "Producer" },
  { id: TEAM_MEMBER_ROLE_DIRECTOR, name: "Director" },
  { id: TEAM_MEMBER_ROLE_GURU, name: "Guru" },
  { id: TEAM_MEMBER_ROLE_CREATOR, name: "Creator" },
  { id: TEAM_MEMBER_ROLE_PERFORMER, name: "Performer" },
  { id: TEAM_MEMBER_ROLE_MAVEN, name: "Maven" },
  { id: TEAM_MEMBER_ROLE_CUSTODIAN, name: "Custodian" },
  { id: TEAM_MEMBER_ROLE_INSPECTOR, name: "Inspector" },
];

export const LEADER_ROLES = [
  TEAM_MEMBER_ROLE_CAPTAIN,
  TEAM_MEMBER_ROLE_CHAIR,
  TEAM_MEMBER_ROLE_CHIEF
];

export const unfixRole_attributes = {
  [TEAM_MEMBER_ROLE_CHIEF]: {
    color: "#920404",
    label: "Chief",
  },
  [TEAM_MEMBER_ROLE_CAPTAIN]: {
    color: "#669204",
    label: "Captain",
  },
  [TEAM_MEMBER_ROLE_CHAIR]: {
    color: "#04925F",
    label: "Chair"
  },
  [TEAM_MEMBER_ROLE_ENABLER]: {
    color: "#040B92",
    label: "Enabler"
  },
  [TEAM_MEMBER_ROLE_SCRIBE]: {
    color: "#92046D",
    label: "Scribe"
  },
  [TEAM_MEMBER_ROLE_ENVOY]: {
    color: "#925504",
    label: "Envoy"
  },
  [TEAM_MEMBER_ROLE_AGENT]: {
    color: "#159204",
    label: "Agent"
  },
  [TEAM_MEMBER_ROLE_GUIDE]: {
    color: "#047792",
    label: "Guide"
  },
  [TEAM_MEMBER_ROLE_PRODUCER]: {
    color: "#4E0492",
    label: "Producer"
  },
  [TEAM_MEMBER_ROLE_DIRECTOR]: {
    color: "#92041C",
    label: "Director"
  },
  [TEAM_MEMBER_ROLE_GURU]: {
    color: "#819204",
    label: "Guru"
  },
  [TEAM_MEMBER_ROLE_CREATOR]: {
    color: "#049244",
    label: "Creator"
  },
  [TEAM_MEMBER_ROLE_PERFORMER]: {
    color: "#042692",
    label: "Performer"
  },
  [TEAM_MEMBER_ROLE_MAVEN]: {
    color: "#920488",
    label: "Maven"
  },
  [TEAM_MEMBER_ROLE_CUSTODIAN]: {
    color: "#923A04",
    label: "Custodian"
  },
  [TEAM_MEMBER_ROLE_INSPECTOR]: {
    color: "#309204",
    label: "Inspector"
  },
};

type RoleAttributeIconProps = {
  roleAttributes: number[];
}

function RoleAttributeIcon({ roleAttributes }: RoleAttributeIconProps) {

  const classAttr = "w-6 h-6";

  if (!roleAttributes || roleAttributes.length <= 0) {
    return (
      <span className="w-6 h-6"></span>
    )
  }

  if (roleAttributes.length > 1) {
    return (
      <span className="w-6 h-6 flex items-center justify-center">{roleAttributes.length}</span>
    )
  }

  let roleAttribute = roleAttributes[0];

  return (
    <>
      {(roleAttribute === TEAM_MEMBER_ROLE_CHIEF) && <RoleAttributeIcons.ChiefRoleAttributeIcon className={classAttr} />}
      {(roleAttribute === TEAM_MEMBER_ROLE_CAPTAIN) && <RoleAttributeIcons.CaptainRoleAttributeIcon className={classAttr} />}
      {(roleAttribute === TEAM_MEMBER_ROLE_CHAIR) && <RoleAttributeIcons.ChairRoleAttributeIcon className={classAttr} />}
      {(roleAttribute === TEAM_MEMBER_ROLE_ENABLER) && <RoleAttributeIcons.EnablerRoleAttributeIcon className={classAttr} />}
      {(roleAttribute === TEAM_MEMBER_ROLE_SCRIBE) && <RoleAttributeIcons.ScribeRoleAttributeIcon className={classAttr} />}
      {(roleAttribute === TEAM_MEMBER_ROLE_ENVOY) && <RoleAttributeIcons.EnvoyRoleAttributeIcon className={classAttr} />}
      {(roleAttribute === TEAM_MEMBER_ROLE_AGENT) && <RoleAttributeIcons.AgentRoleAttributeIcon className={classAttr} />}
      {(roleAttribute === TEAM_MEMBER_ROLE_GUIDE) && <RoleAttributeIcons.GuideRoleAttributeIcon className={classAttr} />}
      {(roleAttribute === TEAM_MEMBER_ROLE_PRODUCER) && <RoleAttributeIcons.ProducerRoleAttributeIcon className={classAttr} />}
      {(roleAttribute === TEAM_MEMBER_ROLE_DIRECTOR) && <RoleAttributeIcons.DirectorRoleAttributeIcon className={classAttr} />}
      {(roleAttribute === TEAM_MEMBER_ROLE_GURU) && <RoleAttributeIcons.GuruRoleAttributeIcon className={classAttr} />}
      {(roleAttribute === TEAM_MEMBER_ROLE_CREATOR) && <RoleAttributeIcons.CreatorRoleAttributeIcon className={classAttr} />}
      {(roleAttribute === TEAM_MEMBER_ROLE_PERFORMER) && <RoleAttributeIcons.PerformerRoleAttributeIcon className={classAttr} />}
      {(roleAttribute === TEAM_MEMBER_ROLE_MAVEN) && <RoleAttributeIcons.MavenRoleAttributeIcon className={classAttr} />}
      {(roleAttribute === TEAM_MEMBER_ROLE_CUSTODIAN) && <RoleAttributeIcons.CustodianRoleAttributeIcon className={classAttr} />}
      {(roleAttribute === TEAM_MEMBER_ROLE_INSPECTOR) && <RoleAttributeIcons.InspectorRoleAttributeIcon className={classAttr} />}
    </>
  );
}

export default function TeamMember({ teamMember, deleteItem, setEditingMember, setShowAddForm }: TeamMemberProps) {
  const [imageUrl, setImageUrl] = useState<string>("");

  const borderColor =
    teamMember.timeCommitment in timeCommitment_attributes
      ? timeCommitment_attributes[teamMember.timeCommitment]["color"]
      : "#AAAAAA";

  const bgColor =
    teamMember.participationLevel in participationLevel_attributes
      ? participationLevel_attributes[teamMember.participationLevel]["color"] + "22"
      : "#f1f5f9";

  useEffect(() => {
    // console.log(teamMember);
    if (teamMember.avatar) {
      // console.log("avatar", teamMember);
      const imgRef = ref(avatarStorage, teamMember.avatar);

      getDownloadURL(imgRef).then((url) => {
        setImageUrl(url);
      });
    }

  })

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    var element = event.target as HTMLElement;
    if (element.tagName === "DIV" || element.tagName === "SPAN") {
      setEditingMember(teamMember);
      setShowAddForm(false);
    }
  };

  return (
    <div className="p-1 w-full md:w-1/2 lg:w-1/3 cursor-pointer" onClick={handleClick}>
      <div className={`p-1 flex items-center justify-between border-2 rounded-md ${teamMember.duplicate ? 'border-dotted' : ''}`} style={{ borderColor: borderColor, backgroundColor: bgColor }}>
        <span className="flex items-center">
          <RoleAttributeIcon roleAttributes={teamMember.unfixRoles} />
          <span className="mx-2">{teamMember.name}</span>
        </span>
        <span className="flex items-center">
          {imageUrl && <div className="w-4 h-4 mr-1 rounded-lg border bg-contain bg-center bg-no-repeat bg-clip-content" style={{ backgroundImage: `url("${imageUrl}")` }} />}
          {!imageUrl && teamMember.icon && <div className="w-4 h-4 mr-1 flex items-center justify-center">{teamMember.icon}</div>}

          <div className="relative">
            <Menu>
              <Menu.Button className="flex items-center">
                <AdjustmentsHorizontalIcon className="ml-1 w-6 h-6" />
              </Menu.Button>
              <Menu.Items as="div" className="flex flex-col absolute right-0 top-5 z-10 bg-white rounded-md border-2 text-black" style={{ borderColor: borderColor }}>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => deleteItem(teamMember.id)}
                      className="py-2 px-4 whitespace-nowrap ui-active:bg-teal-500"
                      style={active ? { backgroundColor: bgColor } : {}}
                    >
                      Delete
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </div>
        </span>
      </div>
    </div>
  );
}
