import SettingCheckbox from "./SettingCheckbox";
import { DisplayOptionsType, DisplayOptionKeyType, ChartViewType } from "../../../../common/types";
import CanvasSizeForm from "./CanvasSizeForm";
import { CanvasSizeType } from "../../../../common/types";

type DisplaySettingsProps = {
  view: ChartViewType;
  display_options: DisplayOptionsType;
  setDisplayOption: (attribute: DisplayOptionKeyType, value: boolean | CanvasSizeType) => void;
}

export default function DisplaySettings({ view, display_options, setDisplayOption }: DisplaySettingsProps) {

  return (
    <>
      <div
        id="settings"
        className="border-t"
      >

        {view === "Base" && <>
          <div className="my-2 font-medium">Base style</div>
          <div className="flex flex-wrap justify-start items-center gap-y-2 my-4">
            <SettingCheckbox
              label="Rectangular Base"
              checked={display_options.rectangular_base}
              trigger={() => {
                setDisplayOption(
                  "rectangular_base",
                  !display_options.rectangular_base
                );
              }}
            />
          </div>
          <div className="my-2 font-medium">Element visibility</div>
          <div className="flex flex-wrap justify-start items-center gap-y-2 my-4">
            <SettingCheckbox
              label="Value Streams"
              checked={display_options.value_streams_visible}
              trigger={() => {
                setDisplayOption(
                  "value_streams_visible",
                  !display_options.value_streams_visible
                );
              }}
            />
            <SettingCheckbox
              label="Turfs"
              checked={display_options.turfs_visible}
              trigger={() => {
                setDisplayOption(
                  "turfs_visible",
                  !display_options.turfs_visible
                );
              }}
            />
            <SettingCheckbox
              label="Forums"
              checked={display_options.forums_visible}
              trigger={() => {
                setDisplayOption(
                  "forums_visible",
                  !display_options.forums_visible
                );
              }}
            />
            <SettingCheckbox
              label="Crews"
              checked={display_options.crews_visible}
              trigger={() => {
                setDisplayOption(
                  "crews_visible",
                  !display_options.crews_visible
                );
              }}
            />
          </div>
          <div className="my-2 font-medium">Fit chart to window</div>
          <div className="flex flex-wrap justify-start items-center gap-y-2 mt-4">
            <SettingCheckbox
              label="Fit horizontally"
              checked={display_options.fit_container_x}
              trigger={() => {
                setDisplayOption(
                  "fit_container_x",
                  !display_options.fit_container_x
                );
              }}
            />
            <SettingCheckbox
              label="Fit vertically"
              checked={display_options.fit_container_y}
              trigger={() => {
                setDisplayOption(
                  "fit_container_y",
                  !display_options.fit_container_y
                );
              }}
            />
          </div>
        </>}

        <div className="my-2 font-medium">Page settings</div>
        <div className="flex flex-wrap justify-start items-center gap-y-2 my-4">
          <SettingCheckbox
            label="Show info panel"
            checked={!!display_options.show_tooltip}
            trigger={() => {
              setDisplayOption(
                "show_tooltip",
                !(!!display_options.show_tooltip)
              );
            }}
          />
        </div>
      </div>
    </>
  );
}
