import { useContext } from "react";

import TurfForm from "./TurfForm";
import { Turf } from "./Turf";
import { DBTurfType } from "../../../../common/types";
// import { ReactSortable } from "react-sortablejs";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import Spinner from "../../../baseList/Spinner";
import useTurfApi from "../../common/hooks/useTurfApi";

type TurfListPropsType = {
  baseId: string;
}

export default function TurfList({baseId}: TurfListPropsType) {
  const { user } = useContext(AuthContext) as AuthContextType;

  const {
    turfsQuery,
    createTurfMutation,
    addTurf,
    deleteTurfMutation,
    removeTurf
  } = useTurfApi({baseId, user});

  return (
    <div className="py-2 md:px-2 w-full md:w-1/2 lg:w-1/3">
      <div className="border rounded-md p-2">
        <div className="flex mb-2">
          {(turfsQuery.isFetching || deleteTurfMutation.isLoading || createTurfMutation.isLoading) &&
            <Spinner width="w-8" height="h-8" border="border-2" />}
          <h2 className="text-xl">Turfs</h2>
        </div>

        {turfsQuery.data?.length === 0 && (
          <ul className="my-2">
            <li className="text-slate-500">No items...</li>
          </ul>
        )}

        {turfsQuery.data?.length > 0 && (
          <div>
            {turfsQuery.data?.map((turf: DBTurfType) => (
              <Turf key={turf.id} turf={turf} deleteItem={removeTurf} />
            ))}
          </div>
        )}
        <TurfForm addItem={addTurf} />
      </div>
    </div>
  );
}
