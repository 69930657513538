import { User } from "firebase/auth";
import { DBCrewType, DBCrewPatchType } from "../common/types";

type GetCrewsParamsType = {
  user: User | null;
  baseId: string;
}

export async function getCrews(params: GetCrewsParamsType) {
  const { user, baseId } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/crews/?base=${baseId}`,
    {
      headers: {
        "Authorization": "" + token,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't fetch crew list");
  }

  return response.json();
}

type GetCrewParamsType = {
  user: User | null;
  baseId: string;
  crewId: string;
};

export async function getCrew(params: GetCrewParamsType) {
  const { user, baseId, crewId } = params;
  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/crews/${crewId}/?base=${baseId}`,
    {
      headers: {
        "Authorization": "" + token
      }
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't fetch crew");
  }

  return response.json();
}

type CreateCrewParamsType = {
  user: User | null;
  baseId: string | undefined;
  crew: DBCrewType;
};

export async function createCrew(params: CreateCrewParamsType) {
  const { user, baseId, crew } = params;

  const data = {
    name: crew.name,
    type: crew.type,
    base: baseId,
    measurements: crew.measurements,
  };

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/crews/?base=${baseId}`,
    {
      method: "POST",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't create crew");
  }

  return response.json();
}

type PatchCrewParamsType = {
  user: User | null;
  baseId: string | undefined;
  crew: DBCrewPatchType;
}

export async function patchCrew(params: PatchCrewParamsType) {
  const { user, baseId, crew } = params;

  const flattenedCrew = {
    name: crew.name,
    type: crew.type,
    base: baseId,
    investmentHorizon: crew.investmentHorizon,
    investmentHorizons: crew.investmentHorizons ?? [],
    lifecycleStage: crew.lifecycleStage ?? 0,
    teamingOption: crew.teamingOption ?? 0,
    measurements: crew.measurements,
  };

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/crews/${crew.id}/?base=${baseId}`,
    {
      method: "PATCH",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...flattenedCrew }),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't patch crew");
  }

  return response.json();
}

type DeleteCrewParamsType = {
  user: User | null;
  baseId: string | undefined;
  crewId: string;
};

export async function deleteCrew(params: DeleteCrewParamsType) {
  const { user, baseId, crewId } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/crews/${crewId}/?base=${baseId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't delete crew");
  }
}
