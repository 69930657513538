export const VALUE_STREAM_CREW_TYPE: number = 1;
const GOVERNANCE_CREW_TYPE: number = 2;
const PLATFORM_CREW_TYPE: number = 3;
const FACILITATION_CREW_TYPE: number = 4;
const CAPABILITY_CREW_TYPE: number = 5;
const EXPERIENCE_CREW_TYPE: number = 6;
const PARTNERSHIP_CREW_TYPE: number = 7;

export const CREW_TYPES = [
  { id: VALUE_STREAM_CREW_TYPE, name: "Value Stream Crew" },
  { id: GOVERNANCE_CREW_TYPE, name: "Governance Crew" },
  { id: PLATFORM_CREW_TYPE, name: "Platform Crew" },
  { id: FACILITATION_CREW_TYPE, name: "Facilitation Crew" },
  { id: CAPABILITY_CREW_TYPE, name: "Capability Crew" },
  { id: EXPERIENCE_CREW_TYPE, name: "Experience Crew" },
  { id: PARTNERSHIP_CREW_TYPE, name: "Partnership Crew" },
];

export const crew_type_attributes = {
  [VALUE_STREAM_CREW_TYPE]: {
    color: "#FAD45A",
    icon: "value_stream.svg",
    label: "Value Stream Crew",
    link: "https://unfix.com/value-stream-crew",
    vertical: false,
  },
  [GOVERNANCE_CREW_TYPE]: {
    color: "#5ABDFA",
    icon: "governance.svg",
    label: "Governance Crew",
    link: "https://unfix.com/governance-crew",
    vertical: false,
  },
  [PLATFORM_CREW_TYPE]: {
    color: "#5AFA84",
    icon: "platform.svg",
    label: "Platform Crew",
    link: "https://unfix.com/platform-crew",
    vertical: false,
  },
  [FACILITATION_CREW_TYPE]: {
    color: "#715AFA",
    icon: "facilitation.svg",
    label: "Facilitation Crew",
    link: "https://unfix.com/facilitation-crew",
    vertical: true,
  },
  [CAPABILITY_CREW_TYPE]: {
    color: "#FA5A5A",
    icon: "capability.svg",
    label: "Capability Crew",
    link: "https://unfix.com/capability-crew",
    vertical: true,
  },
  [EXPERIENCE_CREW_TYPE]: {
    color: "#FA5AD0",
    icon: "experience.svg",
    label: "Experience Crew",
    link: "https://unfix.com/experience-crew",
    vertical: true,
  },
  [PARTNERSHIP_CREW_TYPE]: {
    color: "#E7FA5A",
    icon: "partnership.svg",
    label: "Partnership Crew",
    link: "https://unfix.com/partnership-crew",
    vertical: true,
  },
};
