import { User } from "firebase/auth";
import { DBCrewType, TeamMemberType } from "../../../../../common/types";
import { shadeHexColor } from "../../../../../lib/pSBC";
import { crew_type_attributes } from "../../../../../common/constants/crewTypes";
import useCrewMemberApi from "../../../common/hooks/useCrewMemberApi";
import { useDroppable } from "@dnd-kit/core";
import Spinner from "../../../../baseList/Spinner";
import TeamMemberBubble from "./TeamMemberBubble";

type CrewDroppableProps = {
  user: User | null;
  baseId: string;
  crew: DBCrewType;
};

export default function CrewDroppable({ user, baseId, crew }: CrewDroppableProps) {


  const { crewMembersQuery, createCrewMemberMutation, addCrewMember, removeCrewMember } = useCrewMemberApi({ baseId, user, crewId: crew.id, crewMembersGroupBy: "" })
  const { isOver, setNodeRef } = useDroppable({
    id: crew.id,
    data: {
      addTeamMember: addCrewMember,
    },
  });

  const bgColor =
    crew.type in crew_type_attributes
      ? crew_type_attributes[crew.type]["color"]
      : "";

  const borderColor = isOver ? "green" : shadeHexColor(bgColor, -0.1);

  return (
    <div ref={setNodeRef} className={"p-1 w-full"}>
      <div className="p-1 flex flex-col items-center border-2 rounded-md" style={{ borderColor: borderColor, backgroundColor: bgColor }}>
        <div className="w-full flex items-center">
          {createCrewMemberMutation.isLoading && <Spinner width="w-8" height="h-8" />}
          {crew.name}</div>
        <div className="w-full flex items-center">
          {crewMembersQuery.data?.map((member: TeamMemberType) =>
            <TeamMemberBubble
              key={member.id}
              member={member}
              borderColor={borderColor}
              bgColor={bgColor}
              deleteMember={removeCrewMember}
            />
          )}
        </div>
      </div>
    </div>
  )
}
