import { useMutation, useQuery, useQueryClient } from 'react-query';
import { User } from 'firebase/auth';
import { createForumMember, deleteForumMember, getForumMembers, patchForumMember } from '../../../../api/forumMemberApi';
import { FormTeamMemberType, TeamMemberType } from '../../../../common/types';

type useForumMemberApiParams = {
  baseId: string;
  user: User | null;
  forumId?: string;
  forumMembersGroupBy: string;
  addSuccessCB?: () => void;
  removeSuccessCB?: () => void;
  patchSuccessCB?: () => void;
}

export default function useForumMemberApi({
  baseId,
  user,
  forumId,
  forumMembersGroupBy,
  addSuccessCB,
  removeSuccessCB,
  patchSuccessCB,
}: useForumMemberApiParams) {
  const queryClient = useQueryClient();

  const forumMembersQuery = useQuery(["forum_members", baseId, forumId, forumMembersGroupBy],
    // @ts-ignore
    () => getForumMembers({ user, baseId, forumId, groupBy: forumMembersGroupBy }),
    { enabled: !!user && !!baseId && !!forumId });
  const createForumMemberMutation = useMutation(createForumMember);
  const deleteForumMemberMutation = useMutation(deleteForumMember);
  const patchForumMemberMutation = useMutation(patchForumMember);

  const addForumMember = (forumMemberData: FormTeamMemberType, avatarImage: string = "") => {
    if (!forumId) {
      return;
    }

    let forumMember: TeamMemberType = {
      ...forumMemberData,
      id: "",
    };

    createForumMemberMutation.mutate({ user, baseId, forumId, forumMember, avatarImage }, {
      onSuccess: () => {
        queryClient.invalidateQueries(["forums", baseId]);
        queryClient.invalidateQueries(["forum_members", baseId, forumId]);
        queryClient.invalidateQueries(["base_members", baseId]);
      },
    });
  }


  const removeForumMember = (forumMemberId: string) => {
    if (!forumId) {
      return;
    }
    
    if (window.confirm("Are you sure you want to delete this member?") === true) {
      deleteForumMemberMutation.mutate({ user, baseId, forumId, forumMemberId }, {
        onSuccess: () => {
          queryClient.invalidateQueries(["forums", baseId]);
          queryClient.invalidateQueries(["forum_members", baseId, forumId]);
          queryClient.invalidateQueries(["base_members", baseId]);
        }
      });
    }
  }

  const editForumMember = (forumMember: TeamMemberType, avatarImage: string = "") => {
    if (!forumId) {
      return;
    }
    
    patchForumMemberMutation.mutate({user, baseId, forumId, forumMember, avatarImage}, {
      onSuccess: () => {
        queryClient.invalidateQueries(["forum_members", baseId, forumId]);
        queryClient.invalidateQueries(["base_members", baseId]);
      }
    })
  }

  return {
    forumMembersQuery,
    createForumMemberMutation,
    addForumMember,
    deleteForumMemberMutation,
    removeForumMember,
    patchForumMemberMutation,
    editForumMember,
  };
}
