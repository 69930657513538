export const TEAMING_OPTION_STEADY_TEAM: number = 1;
const TEAMING_OPTION_DYNAMIC_TEAM: number = 2;
const TEAMING_OPTION_MISSION_TEAM: number = 3;
const TEAMING_OPTION_LIQUID_TEAM: number = 4;

export const TEAMING_OPTIONS = [
  { id: TEAMING_OPTION_STEADY_TEAM, name: "Steady Team" },
  { id: TEAMING_OPTION_DYNAMIC_TEAM, name: "Dynamic Team" },
  { id: TEAMING_OPTION_MISSION_TEAM, name: "Mission Team" },
  { id: TEAMING_OPTION_LIQUID_TEAM, name: "Liquid Team" },
];

export const teaming_option_attributes = {
  [TEAMING_OPTION_STEADY_TEAM]: {
    icon: "steady.svg",
    label: "Steady Team",
    link: "https://unfix.com/steady-team",
  },
  [TEAMING_OPTION_DYNAMIC_TEAM]: {
    icon: "dynamic.svg",
    label: "Dynamic Team",
    link: "https://unfix.com/dynamic-team",
  },
  [TEAMING_OPTION_MISSION_TEAM]: {
    icon: "mission.svg",
    label: "Mission Team",
    link: "https://unfix.com/mission-team",
  },
  [TEAMING_OPTION_LIQUID_TEAM]: {
    icon: "liquid.svg",
    label: "Liquid Team",
    link: "https://unfix.com/liquid-team",
  },
}
