export const FUNCTIONAL_FORUM_TYPE: number = 1;
const PRODUCT_FORUM_TYPE: number = 2;
const MARKET_FORUM_TYPE: number = 3;
const CHANNEL_FORUM_TYPE: number = 4;
const BUSINESS_MODEL_FORUM_TYPE: number = 5;
const CUSTOMER_JOURNEY_FORUM_TYPE: number = 6;
const REGIONAL_FORUM_TYPE: number = 7;
const TECHNOLOGICAL_FORUM_TYPE: number = 8;
const SEASONAL_FORUM_TYPE: number = 9;
const ORGANIZATIONAL_FORUM_TYPE: number = 10;

export const FORUM_TYPES = [
  { id: FUNCTIONAL_FORUM_TYPE, name: "Functional Forum" },
  { id: PRODUCT_FORUM_TYPE, name: "Product Forum" },
  { id: MARKET_FORUM_TYPE, name: "Market Forum" },
  { id: CHANNEL_FORUM_TYPE, name: "Channel Forum" },
  { id: BUSINESS_MODEL_FORUM_TYPE, name: "Business Model Forum" },
  { id: CUSTOMER_JOURNEY_FORUM_TYPE, name: "Customer Journey Forum" },
  { id: REGIONAL_FORUM_TYPE, name: "Regional Forum" },
  { id: TECHNOLOGICAL_FORUM_TYPE, name: "Technological Forum" },
  { id: SEASONAL_FORUM_TYPE, name: "Seasonal Forum" },
  { id: ORGANIZATIONAL_FORUM_TYPE, name: "Organizational Forum" },
]

export const forum_type_attributes = {
  [FUNCTIONAL_FORUM_TYPE]: {
    color: "#FDEDBB",
    icon: "functional.svg",
    label: "Functional Forum",
    link: "https://unfix.com/functional-forum",
  },
  [PRODUCT_FORUM_TYPE]: {
    color: "#FDD4BB",
    icon: "product.svg",
    label: "Product Forum",
    link: "https://unfix.com/product-forum",
  },
  [MARKET_FORUM_TYPE]: {
    color: "#BBE4FD",
    icon: "market.svg",
    label: "Market Forum",
    link: "https://unfix.com/market-forum",
  },
  [CHANNEL_FORUM_TYPE]: {
    color: "#FDBBBB",
    icon: "channel.svg",
    label: "Channel Forum",
    link: "https://unfix.com/channel-forum",
  },
  [BUSINESS_MODEL_FORUM_TYPE]: {
    color: "#BBFDFB",
    icon: "business_model.svg",
    label: "Business Model Forum",
    link: "https://unfix.com/business-model-forum",
  },
  [CUSTOMER_JOURNEY_FORUM_TYPE]: {
    color: "#C4BBFD",
    icon: "customer_journey.svg",
    label: "Customer Journey Forum",
    link: "https://unfix.com/customer-journey-forum",
  },
  [REGIONAL_FORUM_TYPE]: {
    color: "#C3FDBB",
    icon: "regional.svg",
    label: "Regional Forum",
    link: "https://unfix.com/regional-forum",
  },
  [TECHNOLOGICAL_FORUM_TYPE]: {
    color: "#FDBBEC",
    icon: "technological.svg",
    label: "Technological Forum",
    link: "https://unfix.com/technological-forum",
  },
  [SEASONAL_FORUM_TYPE]: {
    color: "#F5FDBB",
    icon: "seasonal.svg",
    label: "Seasonal Forum",
    link: "https://unfix.com/seasonal-forum",
  },
  [ORGANIZATIONAL_FORUM_TYPE]: {
    color: "#EDC5FD",
    icon: "organizational.svg",
    label: "Organizational Forum",
    link: "https://unfix.com/organizational-forum",
  },
}
