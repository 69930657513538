import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { DBStoreItemType } from "../../../../common/types";
import { forum_type_attributes } from "../../../../common/constants/forumTypes";
import { crew_type_attributes } from "../../../../common/constants/crewTypes";
import { valueStream_type_attributes } from "../../../../common/constants/valueStreamTypes";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import useCanvasElementsApi from "../../common/hooks/useCanvasElementsApi";
import { useContext } from "react";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";

type CanvasElementsPanelItemProps = {
  baseId: string;
  element: DBStoreItemType;
}

export default function CanvasElementsPanelItem({ baseId, element }: CanvasElementsPanelItemProps) {

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: element.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { user } = useContext(AuthContext) as AuthContextType;
  const { setVisibility } = useCanvasElementsApi({ baseId: baseId, user: user });

  const getBgColor = (elementType: string | undefined, type: number) => {
    let bgColor = "";

    switch (elementType) {
      case "turf":
        bgColor = "#D6D3D1";

        break;
      case "forum":
        if (type in forum_type_attributes) {
          bgColor = forum_type_attributes[type]["color"];
        }

        break;
      case "crew":
        if (type in crew_type_attributes) {
          bgColor = crew_type_attributes[type]["color"];
        }
        break;
      case "value_stream":
        if (type in valueStream_type_attributes) {
          bgColor = valueStream_type_attributes[type]["color"];
        }
        break;
      default:
        bgColor = "#D6D3D1";

        break;
    }

    return bgColor;
  }

  return (
    <div ref={setNodeRef} {...attributes} {...listeners} className="flex items-center w-full justify-between mb-1" style={style}>
      <div className="flex items-center w-full mr-2 p-1 rounded-md cursor-grab" style={{ backgroundColor: getBgColor(element.elementType, element.type) }}>
        <span>{element.name}</span>
      </div>
      <div onClick={() => { setVisibility({ id: element.id, type: element.elementType ?? "", visible: !element.visible }) }}>
        {!!element.visible && <EyeIcon className="w-6 h-6 cursor-default" />}
        {!element.visible && <EyeSlashIcon className="w-6 h-6 cursor-default" />}
      </div>
      {/* <SettingCheckbox
        label=""
        checked={element.visible ?? false}
        trigger={() => {setVisibility({ id: element.id, type: element.elementType ?? "", visible: !element.visible })}}
      /> */}
    </div>

  )
}
