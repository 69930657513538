import { useContext } from "react";
import { AuthContext, AuthContextType } from "../../../../../context/AuthContext";
import useCrewApi from "../../../common/hooks/useCrewApi";
import useForumApi from "../../../common/hooks/useForumApi";
import { DBCrewType, DBForumType } from "../../../../../common/types";
import CrewDroppable from "./CrewDroppable";
import ForumDroppable from "./ForumDroppable";

type TeamsPanelPropsType = {
  baseId: string;
}

export default function TeamsPanel({ baseId }: TeamsPanelPropsType) {
  const { user } = useContext(AuthContext) as AuthContextType;

  const { crewsQuery } = useCrewApi({ baseId, user });
  const { forumsQuery } = useForumApi({ baseId, user });

  return (
    <div className="hidden sm:block w-1/2 h-full">
        <div className="flex flex-col">
          <div className="p-1">Drag and drop members to the Crews / Forums.</div>
          <div className="p-1 font-medium">Crews</div>
          {crewsQuery.isSuccess && crewsQuery.data?.map((crew: DBCrewType) => (
            <CrewDroppable
              key={crew.id}
              user={user}
              baseId={baseId}
              crew={crew}
            />
          ))}
          <div className="p-1 font-medium">Forums</div>
          {forumsQuery.isSuccess && forumsQuery.data?.map((forum: DBForumType) => (
            <ForumDroppable
              key={forum.id}
              user={user}
              baseId={baseId}
              forum={forum}
            />
          ))}
        </div>
    </div>
  )
}
