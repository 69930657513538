import { Menu } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import AddCrewDialog from "./AddCrewDialog";
import { User } from "firebase/auth";
import AddForumDialog from "./AddForumDialog";
import AddTurfDialog from "./AddTurfDialog";
import AddValueStreamDialog from "./AddValueStreamDialog";

type AddItemsMenuProps = {
  baseId: string;
  user: User | null;
}

export default function AddItemsMenu({baseId, user}: AddItemsMenuProps) {
  const [isAddCrewDialogOpen, setIsAddCrewDialogOpen] = useState(false);
  const [isAddForumDialogOpen, setIsAddForumDialogOpen] = useState(false);
  const [isAddTurfDialogOpen, setIsAddTurfDialogOpen] = useState(false);
  const [isAddValueStreamDialogOpen, setIsAddValueStreamDialogOpen] = useState(false);

  return (
    <>
      <div className="relative">

        <Menu>
          <Menu.Button className="flex items-center px-4 py-2 bg-teal-500 text-white rounded-md">
            Add
            <ChevronUpIcon className="ml-1 w-6 h-6" />
          </Menu.Button>
          <Menu.Items as="div" className="flex flex-col absolute bottom-full origin-top-right right-0 bottom-12 z-10 bg-white rounded-md border-2 border-teal-500 text-black">
            <Menu.Item>
              <button
                onClick={() => setIsAddCrewDialogOpen(true)}
                className="py-2 px-4 whitespace-nowrap ui-active:bg-teal-500 ui-active:text-white text-left"
              >
                Crew
              </button>
            </Menu.Item>
            <Menu.Item>
              <button
                onClick={() => setIsAddForumDialogOpen(true)}
                className="py-2 px-4 whitespace-nowrap ui-active:bg-teal-500 ui-active:text-white text-left"
              >
                Forum
              </button>
            </Menu.Item>
            <Menu.Item>
              <button
                onClick={() => setIsAddTurfDialogOpen(true)}
                className="py-2 px-4 whitespace-nowrap ui-active:bg-teal-500 ui-active:text-white text-left"
              >
                Turf
              </button>
            </Menu.Item>
            <Menu.Item>
              <button
                onClick={() => setIsAddValueStreamDialogOpen(true)}
                className="py-2 px-4 whitespace-nowrap ui-active:bg-teal-500 ui-active:text-white text-left"
              >
                Value Stream
              </button>
            </Menu.Item>
          </Menu.Items>
        </Menu>


      </div>
      <AddCrewDialog
        isOpen={isAddCrewDialogOpen}
        setIsOpen={setIsAddCrewDialogOpen}
        baseId={baseId}
        user={user}
      />
      <AddForumDialog
        isOpen={isAddForumDialogOpen}
        setIsOpen={setIsAddForumDialogOpen}
        baseId={baseId}
        user={user}
      />
      <AddTurfDialog
        isOpen={isAddTurfDialogOpen}
        setIsOpen={setIsAddTurfDialogOpen}
        baseId={baseId}
        user={user}
      />
      <AddValueStreamDialog
        isOpen={isAddValueStreamDialogOpen}
        setIsOpen={setIsAddValueStreamDialogOpen}
        baseId={baseId}
        user={user}
      />
    </>
  );
}
