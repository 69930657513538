import { Group, Image } from "react-konva";
import useImage from "use-image";
import { useEffect, useState } from "react";
import { ref, getDownloadURL } from "firebase/storage";
import { avatarStorage } from "../../../../firebase";
import { KonvaEventObject } from "konva/lib/Node";
import { TeamMemberType } from "../../../../common/types";

type MemberAvatarProps = {
  x: number;
  y: number;
  width: number;
  height: number;
  avatar: string;
  member: TeamMemberType;
  handleMemberHover: (e: KonvaEventObject<MouseEvent>, member: TeamMemberType) => void;
  handleMemberHoverOut: () => void;
}

export default function MemberAvatar({ x, y, width, height, avatar, member, handleMemberHover, handleMemberHoverOut }: MemberAvatarProps) {
  const [imageUrl, setImageUrl] = useState<string>("");
  const [avatarImg] = useImage(imageUrl, "anonymous");

  const radius = Math.min(width, height) / 2;

  const clipFunc = (ctx: any) => {
    ctx.beginPath();
    ctx.arc(x + radius, y + radius, radius, 0, 2 * Math.PI, false);
    ctx.closePath();
  };

  useEffect(() => {
    // console.log(teamMember);
    if (avatar) {
      // console.log("avatar", teamMember);
      const imgRef = ref(avatarStorage, avatar);

      getDownloadURL(imgRef).then((url) => {
        setImageUrl(url);
      });
    }
  })

  return (
    <Group
      clipFunc={clipFunc}
      onMouseMove={(e) => { handleMemberHover(e, member) }}
      onMouseOut={handleMemberHoverOut}
    >
      <Image
        image={avatarImg}
        alt=""
        x={x}
        y={y}
        width={width}
        height={height}
      />
    </Group>

  )
}