import { useEffect, useState } from "react";
import { ref, getDownloadURL } from "firebase/storage";
import { avatarStorage } from "../../../../../firebase";
import Spinner from "../../../../baseList/Spinner";

type EditorMemberAvatarProps = {
  avatar: string;
}

export default function EditorMemberAvatar({avatar}: EditorMemberAvatarProps) {
  const [imageUrl, setImageUrl] = useState<string>("");

  useEffect(() => {
    // console.log(teamMember);
    if (avatar) {
      // console.log("avatar", teamMember);
      const imgRef = ref(avatarStorage, avatar);

      getDownloadURL(imgRef).then((url) => {
        setImageUrl(url);
      });
    }
  }, [avatar]);

  return (
    <div className="flex items-center justify-center w-8 h-8 rounded-2xl border-2 border-black bg-slate-100">
        {imageUrl && <div className="w-6 h-6 bg-contain bg-center bg-no-repeat bg-clip-content rounded-xl" style={{ backgroundImage: `url("${imageUrl}")` }} />}
        {!imageUrl && <Spinner height="h-6" width="w-6" />}
    </div>
  )
}
