import { Menu } from "@headlessui/react";
import { DBTurfType } from "../../../../common/types";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { turf_type_attributes } from "../../../../common/constants/turfTypes";

type TurfProps = {
  turf: DBTurfType;
  deleteItem: (turfId: string) => void;
};

function Turf({ turf, deleteItem }: TurfProps) {
  let borderColor = turf.type in turf_type_attributes ? turf_type_attributes[turf.type]['color'] : "#a8a29e";

  return (
    <li className="p-1 mb-1 bg-stone-300 border rounded-md flex items-center justify-between cursor-grab" style={{ borderColor: borderColor }}>
      <span className="">{turf.name}</span>

      <div className="relative">
        <Menu>
          <Menu.Button className="flex items-center">
            <AdjustmentsHorizontalIcon className="ml-1 w-6 h-6" />
          </Menu.Button>
          <Menu.Items as="div" className="flex flex-col absolute right-0 top-5 z-10 bg-white rounded-md border-2 text-black"  style={{ borderColor: borderColor }}>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to={`/base/${turf.base}/turf/${turf.id}`}
                  className={`py-2 px-4 whitespace-nowrap ui-active:bg-stone-300 ui-active:text-white`}
                >
                  Details
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              <button
                onClick={() => deleteItem(turf.id)}
                className="py-2 px-4 whitespace-nowrap ui-active:bg-stone-300 ui-active:text-white"
              >
                Delete
              </button>
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>

      {/* <TrashIcon className="w-4 h-4 cursor-pointer" onClick={() => deleteItem(turf.id)} /> */}
    </li>
  );
}

export { Turf };
