import { useContext, useEffect, useState } from "react";
import { CanvasElementType, DBCrewType, DBForumType, DBStoreItemType, DBTurfType, DBValueStreamType } from "../common/types";
import { AuthContext, AuthContextType } from "../context/AuthContext";
import useBaseApi from "../components/baseEditor/common/hooks/useBaseApi";
import useCrewApi from "../components/baseEditor/common/hooks/useCrewApi";
import useForumApi from "../components/baseEditor/common/hooks/useForumApi";
import useTurfApi from "../components/baseEditor/common/hooks/useTurfApi";
import useValueStreamApi from "../components/baseEditor/common/hooks/useValueStreamApi";

export default function useOrderedElements(baseId: string) {

  const { user } = useContext(AuthContext) as AuthContextType;
  const { baseQuery } = useBaseApi({ user, baseId });
  const { crewsQuery } = useCrewApi({ baseId, user });
  const { forumsQuery } = useForumApi({ baseId, user });
  const { turfsQuery } = useTurfApi({ baseId, user });
  const { valueStreamsQuery } = useValueStreamApi({ baseId, user });

  const [orderedElements, setOrderedElements] = useState<DBStoreItemType[]>([]);

  useEffect(() => {
    if (baseQuery.data && baseQuery.data.canvas_elements && baseQuery.data.canvas_elements.length > 0) {
      const elements: DBStoreItemType[] = [];

      baseQuery.data?.canvas_elements.forEach((element: CanvasElementType) => {
        // skip not visible elements
        // if (!element.visible) {
        //   return;
        // }

        switch (element.type) {
          case "forum":
            const forum: DBForumType | null | undefined = forumsQuery.data?.find((forum: DBForumType) => forum.id === element.id);
            if (forum) {
              forum.elementType = "forum";
              forum.visible = element.visible;
              elements.push(forum);
            }
            break;
          case "crew":
            const crew: DBCrewType | null | undefined = crewsQuery.data?.find((crew: DBCrewType) => crew.id === element.id);
            if (crew) {
              crew.elementType = "crew";
              crew.visible = element.visible;
              elements.push(crew);
            }
            break;
          case "turf":
            const turf: DBTurfType | null | undefined = turfsQuery.data?.find((turf: DBTurfType) => turf.id === element.id);
            if (turf) {
              turf.elementType = "turf";
              turf.visible = element.visible;
              elements.push(turf);
            }
            break;
          case "value_stream":
            const valueStream: DBValueStreamType | null | undefined = valueStreamsQuery.data?.find((valueStream: DBValueStreamType) => valueStream.id === element.id);
            if (valueStream) {
              valueStream.elementType = "value_stream";
              valueStream.visible = element.visible;
              elements.push(valueStream);
            }
            break;
          default:
            break;
        }
      });

      setOrderedElements(elements);
    }
  }, [baseQuery.data, crewsQuery.data, forumsQuery.data, turfsQuery.data, valueStreamsQuery.data]);

  return { orderedElements, setOrderedElements }
}
