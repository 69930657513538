import { Formik, Field, Form, ErrorMessage } from "formik";

import { CrewDependencyType, DBCrewType, FormCrewDependencyType } from "../../../../common/types";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";

type TeamMemberFormType = {
  dependency: CrewDependencyType;
  crewId: string;
  crews: DBCrewType[];
  editItem: (dependency: CrewDependencyType) => void;
  setEditingDependency: (dependency: null) => void;
}

type FormErrorsType = {
  toCrew?: string;
  relation?: string;
}

export default function CrewDependencyEditForm({ dependency, crewId, crews, editItem, setEditingDependency }: TeamMemberFormType) {

  useEffect(() => {
    console.log("dependency", dependency);
  }, [dependency]);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={dependency}
        validate={values => {
          const errors: FormErrorsType = {};
          if (!values.toCrew) {
            errors.toCrew = 'Crew selection is required';
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          // console.log("submit", values);
          editItem(values);
          actions.setSubmitting(false);
          setEditingDependency(null);
        }}
      >
        <Form className="flex flex-col border-t mt-2 pt-2">
          <div className="w-full flex items-center justify-between">
            <h2 className="text-xl py-2">Edit dependency</h2>
            <XMarkIcon onClick={() => setEditingDependency(null)} className="w-6 h-6" />
          </div>
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor={dependency.fromCrew == crewId ? "toCrew" : "fromCrew"}>Dependent Crew</label>
            {dependency.fromCrew == crewId && <>
              <Field name="fromCrew" type="hidden" />
              <Field name="toCrew" as="select" className="w-full border rounded-md p-1 my-2">
                <option value={0}>None</option>
                {crews.filter((crew: DBCrewType) => crew.id !== crewId).map((crew: DBCrewType) => <option key={crew.id} value={crew.id}>{crew.name}</option>)}
              </Field>
              <ErrorMessage className="text-red-700" name="toCrew" component="div" />
            </>}
            {dependency.toCrew == crewId && <>
              <Field name="toCrew" type="hidden" />
              <Field name="fromCrew" as="select" className="w-full border rounded-md p-1 my-2">
                <option value={0}>None</option>
                {crews.filter((crew: DBCrewType) => crew.id !== crewId).map((crew: DBCrewType) => <option key={crew.id} value={crew.id}>{crew.name}</option>)}
              </Field>
              <ErrorMessage className="text-red-700" name="fromCrew" component="div" />
            </>}
          </div>
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="relation">Relation</label>
            <Field name="relation" placeholder="Relation" className="w-full border rounded-md p-1 my-2" />
          </div>
          <ErrorMessage className="text-red-700" name="name" component="div" />
          <div className="flex items-center justify-end">
            <button
              type="button"
              className="w-1/3 md:w-1/4 p-2 px-4 my-2 border border-teal-500 tex-teal-500 rounded-md"
              onClick={(e) => { e.preventDefault(); setEditingDependency(null) }}
            >
              Cancel
            </button>
            <button type="submit" className="w-1/3 md:w-1/4 p-2 px-4 my-2 bg-teal-500 text-white rounded-md ml-2">Add</button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}
