import { Link } from "react-router-dom";
import { DBForumType } from "../../../../common/types";
import { AdjustmentsHorizontalIcon, UserIcon } from "@heroicons/react/24/outline";
import { Menu } from "@headlessui/react";
import { shadeHexColor } from "../../../../lib/pSBC";
import { forum_type_attributes } from "../../../../common/constants/forumTypes";

type ForumProps = {
  forum: DBForumType;
  deleteItem: (forumId: string) => void;
}

export default function Forum({ forum, deleteItem }: ForumProps) {
  let bgColor = forum.type in forum_type_attributes ? forum_type_attributes[forum.type]['color'] : "";
  const borderColor = shadeHexColor(bgColor, -0.1);

  return (<li className="p-1 mb-1 rounded-md flex items-center justify-between cursor-grab" style={{ backgroundColor: bgColor }}>
    <span className="flex items-center">
      <span className="font-light text-md">{"memberCnt" in forum ? forum.memberCnt : 0}</span>
      <UserIcon className="w-4 h-4 mr-2 cursor-pointer" />
      <span className="">{forum.name}</span>
    </span>

    <div className="relative">
      <Menu>
        <Menu.Button className="flex items-center">
          <AdjustmentsHorizontalIcon className="ml-1 w-6 h-6" />
        </Menu.Button>
        <Menu.Items as="div" className="flex flex-col absolute right-0 top-5 z-10 bg-white rounded-md border-2 text-black" style={{ borderColor: borderColor }}>
          <Menu.Item>
            {({ active }) => (
              <Link
                to={`/base/${forum.base}/forum/${forum.id}`}
                className={`py-2 px-4 whitespace-nowrap`}
                style={active ? { backgroundColor: bgColor } : {}}
              >
                Details
              </Link>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => deleteItem(forum.id)}
                className="py-2 px-4 whitespace-nowrap"
                style={active ? { backgroundColor: bgColor } : {}}
              >
                Delete
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Menu>
    </div>

    {/* <span className="flex items-center">
      <Link to={`/base/${forum.base}/forum/${forum.id}`}>
          <PencilIcon className="w-4 h-4 mr-1 cursor-pointer" />
      </Link>
      <TrashIcon className="w-4 h-4 cursor-pointer" onClick={() => deleteItem(forum.id)} />
    </span> */}
  </li>)
}
