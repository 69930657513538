import { UseMutationResult, UseQueryResult, useQuery, useQueryClient } from "react-query";
import { FetchedBaseType } from "../../common/types"
import { TrashIcon } from "@heroicons/react/24/outline";
import Spinner from "./Spinner";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import { DeleteBaseParamsType, getBase } from "../../api/baseApi";

import { ref, getDownloadURL } from "firebase/storage";
import { thumbnailStorage } from "../../firebase";

type UnfixBaseItemProps = {
  base: FetchedBaseType;
  basesQuery: UseQueryResult<any, unknown>;
  deleteBaseMutation: UseMutationResult<void, unknown, DeleteBaseParamsType, unknown>;
  deleteBaseClicked: (baseId: string) => void;
  editingBase: string;
}

export default function UnfixBaseItem({ base, deleteBaseMutation, basesQuery, deleteBaseClicked, editingBase }: UnfixBaseItemProps) {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext) as AuthContextType;

  const [imageUrl, setImageUrl] = useState<string>("");

  const queryClient = useQueryClient();
  const baseQuery = useQuery(["bases", base.id], () => getBase({ user: user, baseId: base.id }), { enabled: !!base.id && !!user && !base.thumbnail_url });

  const [refetchCount, setRefetchCount] = useState(0);

  // refetch base until there is a thumbnail
  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (!base.thumbnail_url) {
      timer = setTimeout(() => {
        if (baseQuery.data?.thumbnail_url) {
          let newListData = basesQuery.data.map(
            (element: FetchedBaseType) => element.id === base.id ? {...baseQuery.data} : {...element} )
            
          queryClient.setQueryData(["bases"], newListData);
      
        } else {
          setRefetchCount(refetchCount + 1);
          queryClient.invalidateQueries(["bases", base.id]);
        }
      }, 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [basesQuery.isFetching, basesQuery.data, baseQuery.data, base, refetchCount])

  useEffect(() => {
    if (base.thumbnail_url) {
      const imgRef = ref(thumbnailStorage, base.thumbnail_url);

      getDownloadURL(imgRef).then((url) => {
        setImageUrl(url);
      });
    }

  }, [base])

  return (
    <div key={base.id} className="p-2 w-1/2 md:w-1/4 lg:w-1/6">
      <div className="flex flex-col items-center p-2 border border-teal-500 rounded-md cursor-pointer">
        <div className="w-full h-6 flex justify-end">
          {editingBase === base.id && <span></span>}
          {!(editingBase === base.id) &&
            <TrashIcon onClick={(e) => { e.preventDefault(); deleteBaseClicked(base.id); }} className="w-6 h-6 text-red-800" />
          }
        </div>
        <div className="p-0 m-0 w-full flex flex-col items-center" onClick={() => { navigate(`/base/${base.id}#editor`) }}>
          {editingBase === base.id && (
            <Spinner width="w-40" height="h-40" border="border-8" />
          )}
          {editingBase !== base.id && (
            <div>
              {imageUrl && <div className="p-4 w-40 h-40 bg-contain bg-center bg-no-repeat bg-clip-content" style={{ backgroundImage: `url("${imageUrl}")` }} />}
              {!imageUrl && <Spinner width="w-40" height="h-40" border="border-8" />}
            </div>
          )}
          <div className="">
            {base.name}
          </div>
        </div>
      </div>
    </div>
  )
}
