import React, { useContext, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import Main from "./pages/Main";
import BaseList from "./pages/BaseList";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import reportWebVitals from "./reportWebVitals";
import { AuthContext, AuthContextProvider, AuthContextType } from "./context/AuthContext";
import Layout from "./components/layout/Layout";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import CrewEditor from "./pages/CrewEditor";
import ForumEditor from "./pages/ForumEditor";
import ForgottenPassword from "./pages/ForgottenPassword";
import ResetPassword from "./pages/ResetPassword";
import AuthActions from "./pages/AuthActions";
import Account from "./pages/Account";
import TurfEditor from "./pages/TurfEditor";
import ValueStreamEditor from "./pages/ValueStreamEditor";
import Maintenance from "./components/maintenance/Maintenance";
import { bool, boolean } from "yup";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 10,
      cacheTime: 1000 * 60 * 15,
    },
  },
});

export default function App() {

  const { user } = useContext(AuthContext) as AuthContextType;

  if (process.env.REACT_APP_MAINTENANCE_MODE == "1" && (!user || user.email !== "gabor.daher@unfix.com")) {
    return <Maintenance />;
  } else {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<BaseList />} />
            <Route path="sign_in" element={<SignIn />} />
            <Route path="sign_up" element={<SignUp />} />
            <Route path="forgotten_password" element={<ForgottenPassword />} />
            <Route path="reset_password" element={<ResetPassword />} />
            <Route path="auth" element={<AuthActions />} />
            <Route path="account" element={<Account />} />
            <Route path="base/:baseId/crew/:crewId" element={<CrewEditor />} />
            <Route path="base/:baseId/forum/:forumId" element={<ForumEditor />} />
            <Route path="base/:baseId/turf/:turfId" element={<TurfEditor />} />
            <Route path="base/:baseId/value_stream/:valueStreamId" element={<ValueStreamEditor />} />
            <Route path="base/:baseId" element={<Main />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </AuthContextProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
