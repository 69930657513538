import { CrewDependencyType, DBCrewType } from "../../../../common/types";
import { MouseEvent } from "react";
import { Menu } from "@headlessui/react";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";

type CrewDependencyProps = {
  dependency: CrewDependencyType;
  crew: DBCrewType;
  deleteItem: (dependencyId: string) => void;
  setEditingDependency: (dependency: CrewDependencyType|null) => void;
  setShowAddForm: (show: boolean) => void;
};


export default function CrewDependency({ dependency, crew, deleteItem, setEditingDependency, setShowAddForm }: CrewDependencyProps) {

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    var element = event.target as HTMLElement;
    if (element.tagName === "DIV" || element.tagName === "SPAN") {
      setEditingDependency(dependency);
      setShowAddForm(false);
    }
  };

  return (
    <div className="p-1 w-full md:w-1/2 lg:w-1/3 cursor-pointer" onClick={handleClick}>
      <div className="p-1 flex items-center justify-between border-2 rounded-md bg-violet-100">
        <span className="flex items-center">
          <span className="mx-2">{crew.name}</span>
        </span>
        <span className="flex items-center">
          <div className="relative">
            <Menu>
              <Menu.Button className="flex items-center">
                <AdjustmentsHorizontalIcon className="ml-1 w-6 h-6" />
              </Menu.Button>
              <Menu.Items as="div" className="flex flex-col absolute right-0 top-5 z-10 bg-white rounded-md border-2 text-black">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => deleteItem(dependency.id)}
                      className="py-2 px-4 whitespace-nowrap ui-active:bg-teal-500"
                    >
                      Delete
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </div>
        </span>
      </div>
    </div>
  );
}
