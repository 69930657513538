import { useContext, useState } from "react";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import TeamMemberList from "../teamMembers/TeamMemberList";
import Spinner from "../../../baseList/Spinner";
import CrewEditForm from "./CrewEditForm";
import useCrewApi from "../../common/hooks/useCrewApi";
import useCrewMemberApi from "../../common/hooks/useCrewMemberApi";
import useBaseMemberApi from "../../common/hooks/useBaseMemberApi";
import { Menu } from "@headlessui/react";
import { AdjustmentsHorizontalIcon, PlusIcon } from "@heroicons/react/24/outline";
import CrewDependencyList from "../crewDependencies/CrewDependencyList";

type CrewDetailsPropsType = {
  baseId: string;
  crewId: string;
}

export default function CrewDetails({ baseId, crewId }: CrewDetailsPropsType) {
  const { user } = useContext(AuthContext) as AuthContextType;

  const [crewMembersGroupBy, setCrewMembersGroupBy] = useState("participationLevel");

  const { crewQuery, editCrew } = useCrewApi({ baseId, user, crewId });
  const {
    crewMembersQuery,
    addCrewMember,
    removeCrewMember,
    editCrewMember,
  } = useCrewMemberApi({ baseId, user, crewId, crewMembersGroupBy });

  const { baseMembersQuery } = useBaseMemberApi({ baseId, user });


  return (

    <div id="crew_details" className="w-full">
      <div className="py-2 md:px-2 w-full">
        <div className="border rounded-md p-2">
          <div className="flex mb-2">
            {(crewQuery.isFetching) &&
              <Spinner width="w-8" height="h-8" border="border-2" />}
            <h2 className="text-xl">Crew Details</h2>
          </div>

          {crewQuery.isSuccess && crewQuery.data && <CrewEditForm crew={crewQuery.data} editItem={editCrew} />}

        </div>
      </div>


      <TeamMemberList
        teamMembersQuery={crewMembersQuery}
        baseMembersQuery={baseMembersQuery}
        addTeamMember={addCrewMember}
        removeTeamMember={removeCrewMember}
        editTeamMember={editCrewMember}
        groupBy={crewMembersGroupBy}
        setGroupBy={setCrewMembersGroupBy}
      />

      <CrewDependencyList baseId={baseId} crewId={crewId} />


      <div className="w-full h-32"></div>

    </div>
  );
}
