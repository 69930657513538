import { Dialog } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline";

type MiroExportDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function MiroExportDialog({ isOpen, setIsOpen }: MiroExportDialogProps) {

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel className="mx-auto w-full lg:w-1/2 p-4 rounded bg-white">
          <div className="flex items-center justify-between">
            <Dialog.Title>Export to Miro</Dialog.Title>
            <XMarkIcon className="w-6 h-6" onClick={() => setIsOpen(false)} />
          </div>

          <div className="py-2 mt-2 border-t">
            <p className="py-2">Install our Miro application to your Miro team, and simply drag and drop Bases to your boards.</p>
            <p className="py-2">You can install it by clicking this link:</p>
            <div className="flex items-center justify-center">
              <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_MIRO_INSTALL_LINK} className="w-full p-2 flex justify-center bg-teal-500 text-white rounded-md">Install unFIX Plotter to Miro</a>
            </div>
            <p className="py-2">After installation, you can open the application from the <a className="text-teal-500" target="_blank" rel="noreferrer" href="https://help.miro.com/hc/en-us/articles/360017730553-Toolbars#Customizing_the_toolbar">Miro toolbar</a> by searching for unFIX Plotter.</p>
          </div>

          <div className="w-full flex items-center justify-end">
            <button
              onClick={() => setIsOpen(false)}
              className="px-4 py-2 border border-teal-500 rounded-md"
            >
              Cancel
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
