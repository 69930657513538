import { Combobox } from "@headlessui/react"
import { Fragment, useContext, useEffect, useState } from "react"
import { FetchedBaseType } from "../../common/types";
import useBaseApi from "../baseEditor/common/hooks/useBaseApi";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import useAccountApi from "../baseEditor/common/hooks/useAccountApi";

export default function BaseSubscriptionSelector() {
  const { user } = useContext(AuthContext) as AuthContextType;

  const [filteredBases, setFilteredBases] = useState<FetchedBaseType[]>([]);
  const [selectedBase, setSelectedBase] = useState<FetchedBaseType | ''>('');
  const [query, setQuery] = useState<string>('');

  const { basesQuery } = useBaseApi({ user });

  const upgradeSuccessCB = () => {
    setSelectedBase("");
  }
  const { upgradeBaseClick } = useAccountApi({ user, upgradeSuccessCB });

  useEffect(() => {
    const bases = query === ''
      ? basesQuery.data
      : basesQuery.data.filter((base: FetchedBaseType) => {
        return base.name.toLowerCase().includes(query.toLowerCase())
      });

    setFilteredBases(bases);

  }, [basesQuery.data, query])

  return (
    <Combobox value={selectedBase} onChange={setSelectedBase}>
      <div>Upgrade Base</div>
      <div className="flex p-0 w-full">
        <Combobox.Input
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(base: FetchedBaseType) => base.name}
          className="border border-slate-300 rounded-md p-1 my-2 w-full"
          placeholder="Select Base"
        />
        <Combobox.Button>
          <ChevronUpDownIcon className="h-5 w-5" />
        </Combobox.Button>
      </div>
      <div className="relative">
        <Combobox.Options className="absolute bg-white z-10 mt-1 p-2 w-full border border-slate-500 rounded-md">
          {filteredBases.map((base: FetchedBaseType) => (

            <Combobox.Option key={base.id} value={base} as={Fragment}>
              {({ active, selected }) => (
                <li
                  className={`p-1 flex items-center ${active ? 'bg-teal-600 text-white' : 'bg-white text-black'
                    }`}
                >
                  {selected && <CheckIcon className="h-4 w-4 text-teal-500 font-bold" />}
                  {base.name}
                </li>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
      {selectedBase && <div className="flex items-center">
        <button onClick={() => { upgradeBaseClick(selectedBase.id) }} className="px-4 py-2 bg-teal-500 text-white rounded-md">Upgrade Base</button>
        <button onClick={() => { setSelectedBase('') }} className="ml-2 px-4 py-2 border border-teal-500 rounded-md">Cancel</button>
      </div>}
    </Combobox>
  )
}