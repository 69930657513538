import { useContext, useState } from "react";
import { AuthContext, AuthContextType } from "../context/AuthContext";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

type ForgottenPasswordType = {
  email: string;
}

export default function ForgottenPassword() {
  const {user, forgottenPassword} = useContext(AuthContext) as AuthContextType;

  const [showFlash, setShowFlash] = useState(false);

  const initialValues: ForgottenPasswordType = {
    email: "",
  };

  const ForgottenPasswordSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
  });


  return (
    <div className="w-full flex justify-center">
      <div className="flex flex-col py-2 md:px-2 w-full md:w-1/2 lg:w-1/3 m-2 p-2">
        <div className="text-2xl">Reset password</div>
        <Formik
          initialValues={initialValues}
          validationSchema={ForgottenPasswordSchema}
          onSubmit={(values, actions) => {
            forgottenPassword(values.email).catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
            });
            setShowFlash(true);
            actions.setSubmitting(false);
            actions.resetForm();
          }}
        >
          <Form className="flex flex-col border-t mt-2 pt-2">
            {showFlash && <div className="p-2 mb-2 border border-teal-500 rounded-md">
              An email with the instructions was sent to your email address.
            </div>}
            <div className="flex flex-col">
              <label htmlFor="email">Email</label>
              <Field type="email" name="email" placeholder="email@example.com" className="border rounded-md p-1 my-2" />
              <ErrorMessage className="text-red-700" name="email" component="div" />
            </div>
            <button
              type="submit"
              className="p-2 px-4 my-4 bg-teal-500 text-white rounded-md"
            >
              Send
            </button>
            <div className="mb-2">
              <span>Have an account? </span>
              <Link className="text-teal-500" to="/sign_in">Sign in</Link>
            </div>
            <div className="">
              <span>Don't have an account? </span>
              <Link className="text-teal-500" to="/sign_up">Sign up</Link>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}