import { useMutation, useQuery, useQueryClient } from "react-query";
import { createValueStream, deleteValueStream, getValueStream, getValueStreams, patchValueStream } from "../../../../api/valueStreamApi";
import { getValueStreamDefaultMeasurements } from "../../canvas/lib/measurements";
import { DBValueStreamPatchType, DBValueStreamType, FormValueStreamType } from "../../../../common/types";
import { User } from 'firebase/auth';

type useValueStreamApiParams = {
  baseId: string;
  user: User | null;
  addSuccessCB?: () => void;
  removeSuccessCB?: () => void;
  patchSuccessCB?: () => void;
  valueStreamId?: string;
}

export default function useValueStreamApi({ baseId, user, addSuccessCB, removeSuccessCB, patchSuccessCB, valueStreamId }: useValueStreamApiParams) {
  const queryClient = useQueryClient();
  const valueStreamQuery = useQuery(["value_stream", baseId, valueStreamId],
    // @ts-ignore
    () => getValueStream({ user, baseId, valueStreamId }),
    { enabled: !!user && !!baseId && !!valueStreamId });
  const valueStreamsQuery = useQuery(["value_streams", baseId], () => getValueStreams({ user, baseId }), { enabled: !!user && !!baseId });
  const createValueStreamMutation = useMutation(createValueStream);
  const deleteValueStreamMutation = useMutation(deleteValueStream);
  const patchValueStreamMutation = useMutation(patchValueStream);

  const addValueStream = (formData: FormValueStreamType) => {
    let valueStream: DBValueStreamType = {
      ...formData,
      id: "",
      base: baseId,
      measurements: getValueStreamDefaultMeasurements(
        valueStreamsQuery.data?.length,
        valueStreamsQuery.data?.length + 1,
        1280,
        720,
      ),
    };

    createValueStreamMutation.mutate({ user, baseId, valueStream }, {
      onSuccess: (data) => {
        valueStream["id"] = data.id;
        let newList = [...valueStreamsQuery.data, valueStream];
        queryClient.setQueryData(["value_streams", baseId], newList);
        queryClient.invalidateQueries(["bases", baseId]);
        // queryClient.invalidateQueries(["value_streams", baseId]);

        if (addSuccessCB) {
          addSuccessCB();
        }
      },
    });
  }

  const removeValueStream = (valueStreamId: string) => {
    if (window.confirm("Are you sure you want to delete this Value Stream?") === true) {
      deleteValueStreamMutation.mutate({ user, baseId, valueStreamId }, {
        onSuccess: () => {
          const newList = valueStreamsQuery.data.filter((element: DBValueStreamType) => element.id !== valueStreamId);
          queryClient.setQueryData(["value_streams", baseId], newList);
          queryClient.invalidateQueries(["bases", baseId]);
          // queryClient.invalidateQueries(["value_streams", baseId]);

          if (removeSuccessCB) {
            removeSuccessCB();
          }
        }
      });
    }
  }

  const editValueStream = (valueStream: DBValueStreamPatchType) => {
    if (!valueStreamId) {
      return;
    }

    const toSave = {
      ...valueStream,
      id: valueStreamId,
    };
    patchValueStreamMutation.mutate({user, baseId, valueStream: toSave}, {
      onSuccess: () => {
        queryClient.invalidateQueries(["value_stream", baseId, valueStreamId]);
        queryClient.invalidateQueries(["value_streams", baseId]);

        if (patchSuccessCB) {
          patchSuccessCB();
        }
      }
    })
  }

  return {
    valueStreamsQuery,
    valueStreamQuery,
    createValueStreamMutation,
    addValueStream,
    deleteValueStreamMutation,
    removeValueStream,
    patchValueStreamMutation,
    editValueStream,
  }

}
