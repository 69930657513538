import React, { ReactNode, createContext, useEffect, useState } from "react";

interface IPropsWithChildren {
  children: React.ReactNode;
}

export type TooltipContextType = {
  tooltip: ReactNode;
  setTooltip: (tt: ReactNode) => void;
  resetTooltip: () => void;
};

const TooltipContext = createContext<TooltipContextType | null>(null);

const TooltipContextProvider: React.FC<IPropsWithChildren> = ({ children }) => {
  const emptyValue = (<p>Click / tap on an element to see more details here.</p>)

  const [tooltip, setTooltip] = useState<ReactNode>(emptyValue);

  useEffect(() => {
  }, []);


  const resetTooltip = () => {
    setTooltip(emptyValue);
  }

  return (
    <TooltipContext.Provider value={{ tooltip, setTooltip, resetTooltip }}>
      {children}
    </TooltipContext.Provider>
  );
};

export { TooltipContext, TooltipContextProvider };
