import { useContext, useEffect } from "react"
import { useMutation } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { verifyEmail } from "../api/userApi";
import { AuthContext, AuthContextType } from "../context/AuthContext";

export default function AuthActions() {
  const location = useLocation();
  const {user} = useContext(AuthContext) as AuthContextType;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const verifyEmailMutation = useMutation(verifyEmail);

  useEffect(() => {
    const mode = searchParams.get("mode")
    const oobCode = searchParams.get("oobCode")

    if (mode && oobCode) {
      const queryString = `oobCode=${oobCode}`;

      switch (mode) {
        case 'resetPassword':
          // Display reset password handler and UI.
          navigate(`/reset_password?${queryString}`);
          break;
        case 'verifyEmail':
          if (user) {
            verifyEmailMutation.mutate({user}, {
              onSuccess: () => {
                navigate("/")
              }
            });
          }
          break;
        case 'recoverEmail':
        // Display email recovery handler and UI.
        // handleRecoverEmail(auth, actionCode, lang);
        // break;
        default:
        // Error: invalid mode.
      }

      let timer = setTimeout(() => {
        let redirect_url = "/sign_in";
        redirect_url = redirect_url + "?redirect_to=" + encodeURIComponent(location.pathname + location.search);
  
        if (!user) {
          navigate(redirect_url);
        }
      }, 1500);
  
      return () => {
        clearTimeout(timer);
      };
  

    }
  }, [searchParams, user, location, navigate]);

  return (<></>)
}
