// const defaultWidth = 1280;
// const defaultHeight = 720;

// function getDefaultCanvasSize() {
//   return { defaultWidth, defaultHeight };
// }

function getHorizontalCrewDefaultMeasurements(index: number, crewCnt: number, defaultWidth: number, defaultHeight: number) {
  const width = defaultWidth - 200;
  const height = 80;

  const offset = 100;
  // const lastOffset = defaultHeight - offset - height;
  // const stepOffset = (lastOffset - offset) / Math.max(crewCnt - 1, 1);

  const x = 100;
  const y = offset + index * 30;

  return { x, y, width, height };
}

function getVerticalCrewDefaultMeasurements(index: number, crewCnt: number, defaultWidth: number, defaultHeight: number) {
  const width = 150;
  const height = defaultHeight - 200;

  const offset = 150;
  // const lastOffset = defaultWidth - offset - width;
  // const stepOffset = (lastOffset - offset) / Math.max(crewCnt - 1, 1);

  const x = offset + index * 30;
  const y = 100;

  return { x, y, width, height };
}

function getForumDefaultMeasurements(index: number, forumCnt: number, defaultWidth: number, defaultHeight: number) {
  const width = 200;
  const height = defaultHeight - 200;

  const offset = 200;
  // const lastOffset = defaultWidth - offset - width;
  // const stepOffset = (lastOffset - offset) / Math.max(forumCnt - 1, 1);

  const x = offset + index * 30;
  const y = 100;

  return { x, y, width, height };
}

function getTurfDefaultMeasurements(index: number, crewCnt: number, defaultWidth: number, defaultHeight: number) {
  const width = defaultWidth - 200;
  const height = 170;

  const offset = 100;
  // const lastOffset = defaultHeight - offset - height;
  // const stepOffset = (lastOffset - offset) / Math.max(crewCnt - 1, 1);

  const x = 100;
  const y = offset + index * 30;

  return { x, y, width, height };
}

function getValueStreamDefaultMeasurements(index: number, crewCnt: number, defaultWidth: number, defaultHeight: number) {
  const width = defaultWidth - 100;
  const height = 50;

  const offset = 100;
  // const lastOffset = defaultHeight - offset - height;
  // const stepOffset = (lastOffset - offset) / Math.max(crewCnt - 1, 1);

  const x = 50;
  const y = offset + index * 30;

  return { x, y, width, height };
}

export {
  // getDefaultCanvasSize,
  getHorizontalCrewDefaultMeasurements,
  getVerticalCrewDefaultMeasurements,
  getForumDefaultMeasurements,
  getTurfDefaultMeasurements,
  getValueStreamDefaultMeasurements,
};
