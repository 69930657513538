import { Image } from "react-konva";
import { investment_horizon_attributes } from "../../../../common/constants/investmentHorizons";
import useImage from "use-image";
import { useContext } from "react";
import { TooltipContext, TooltipContextType } from "../context/TooltipContext";

type InvestmentHorizonBadgeProps = {
  horizon: number;
  x: number;
  y: number;
}

export default function InvestmentHorizonBadge({ horizon, x, y }: InvestmentHorizonBadgeProps) {
  const iconFile = investment_horizon_attributes[horizon]["icon"];
  const [icon] = useImage(`/unfix/icons/investment_horizon/${iconFile}`);

  const {setTooltip} = useContext(TooltipContext) as TooltipContextType;

  const onElementClicked = (e: any) => {
    e.cancelBubble = true;

    setTooltip(<p>
      This icon is a(n) <a target="_blank" href={`${investment_horizon_attributes[ horizon ]["link"]}`} className="text-teal-500">
        {`${investment_horizon_attributes[ horizon ]["label"]}`} Investment Horizon
      </a></p>
    )
  }

  return (
    <Image
      image={icon}
      onClick={onElementClicked}
      onTap={onElementClicked}
      alt=""
      x={x}
      y={y}
      width={40}
      height={40}
    />
  )
}