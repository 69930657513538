import { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import useBaseApi from "./hooks/useBaseApi";
import useCrewApi from "./hooks/useCrewApi";
import useForumApi from "./hooks/useForumApi";
import useTurfApi from "./hooks/useTurfApi";

export default function Breadcrumbs() {
  const { baseId, crewId, forumId, turfId } = useParams()

  const { user } = useContext(AuthContext) as AuthContextType;

  const {baseQuery} = useBaseApi({user, baseId});
  const {crewQuery} = useCrewApi({baseId: baseId ?? "", user, crewId});
  const {forumQuery} = useForumApi({baseId: baseId ?? "", user, forumId});
  const {turfQuery} = useTurfApi({baseId: baseId ?? "", user, turfId});

  return (
    <div className="flex flex-wrap items-center px-2 pb-2">
      <span className="text-teal-500"><Link to="/">My Bases</Link></span>
      {baseId && <span className="mx-2">/</span>}
      {baseId && (
        <span className="text-teal-500">
          <Link to={`/base/${baseId}#editor`}>{baseQuery?.isSuccess ? `${baseQuery.data?.name}: ${baseQuery.data?.revision_name}` : "Base"}</Link>
        </span>
      )}
      {baseId && crewId && <span className="mx-2">/</span>}
      {baseId && crewId && (
        <span className="text-teal-500">
          <Link to={`/base/${baseId}/crew/${crewId}`}>{crewQuery?.isSuccess ? crewQuery.data?.name : "Crew"}</Link>
        </span>
      )}
      {baseId && forumId && <span className="mx-2">/</span>}
      {baseId && forumId && (
        <span className="text-teal-500">
          <Link to={`/base/${baseId}/forum/${forumId}`}>{forumQuery?.isSuccess ? forumQuery.data?.name : "Forum"}</Link>
        </span>
      )}
      {baseId && turfId && <span className="mx-2">/</span>}
      {baseId && turfId && (
        <span className="text-teal-500">
          <Link to={`/base/${baseId}/turf/${turfId}`}>{turfQuery?.isSuccess ? turfQuery.data?.name : "Turf"}</Link>
        </span>
      )}
    </div>
  );
}

