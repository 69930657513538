import { Fragment, useContext, useState } from "react";
import Spinner from "../../../baseList/Spinner";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import { lifecycle_stage_attributes } from "../../../../common/constants/lifecycleStages";
import { FetchedBaseType } from "../../../../common/types";
import Revision from "./Revision";
import RevisionEditForm from "./RevisionEditForm";
import useRevisionApi from "../../common/hooks/useRevisionApi";
import BasePaidStatus from "../../../account/BasePaidStatus";

type BaseRevisionsPropsType = {
  baseId: string;
}

export default function RevisionList({ baseId }: BaseRevisionsPropsType) {

  const [editingRevision, setEditingRevision] = useState<FetchedBaseType | null>(null);

  const { user } = useContext(AuthContext) as AuthContextType;

  const {
    revisionsQuery,
    duplicateRevisionMutation,
    copyRevision,
    patchRevisionMutation,
    editRevision,
    deleteRevisionMutation,
    removeRevision,
    setDefaultRevisionMutation,
    setDefaultRevision,
  } = useRevisionApi({ user, baseId });

  const getGroupName = (id: number) => {
    if (id === 0) {
      return "Uncategorized";
    }

    return lifecycle_stage_attributes[id].label;
  }

  return (
    <div id="revisions" className="w-full">
      <div className="py-2 md:px-2 w-full">
        <BasePaidStatus />
        <div className="border rounded-md p-2">
          <div className="flex justify-between mb-2">
            <span className="flex items-center">
              {(
                revisionsQuery.isFetching ||
                duplicateRevisionMutation.isLoading ||
                patchRevisionMutation.isLoading ||
                deleteRevisionMutation.isLoading ||
                setDefaultRevisionMutation.isLoading
              ) && <Spinner width="w-8" height="h-8" border="border-2" />}
              <h2 className="text-xl">Revisions</h2>
            </span>
          </div>

          {!revisionsQuery.data && (
            <ul className="my-2">
              <li className="text-slate-500">No items...</li>
            </ul>
          )}

          {revisionsQuery.data && (
            <div className="w-full">
              {Object.keys(revisionsQuery.data).map((group) => (
                <Fragment key={`lifecycle_stage_group_${group}`}>
                  <div className="w-full p-1 font-medium" key={`group_${group}`}>{getGroupName(Number(group))}</div>
                  <div className="flex flex-wrap">
                    {revisionsQuery.data?.[group]?.map((revision: FetchedBaseType) => (
                      <Revision
                        key={revision.id}
                        revision={revision}
                        duplicateItem={copyRevision}
                        deleteItem={removeRevision}
                        setEditingRevision={setEditingRevision}
                        setDefaultRevision={setDefaultRevision}
                      />
                    ))}
                  </div>
                </Fragment>
              ))}
            </div>
          )}
          {editingRevision && <RevisionEditForm revision={editingRevision} setEditingRevision={setEditingRevision} editItem={editRevision} />}

        </div>
      </div>
    </div>
  )
}
