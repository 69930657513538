import { useMutation } from "react-query";
import { createCheckoutSession } from "../../api/accountApi";

import { MouseEventHandler, useContext, useState } from "react";

import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import Spinner from "../baseList/Spinner";
import useBaseApi from "../baseEditor/common/hooks/useBaseApi";
import BaseSubscriptionSelector from "./BaseSubscriptionSelector";
import PaidBaseList from "./PaidBaseList";
import useAccountApi from "../baseEditor/common/hooks/useAccountApi";

export default function Subcriptions() {

  const { user } = useContext(AuthContext) as AuthContextType;

  const [isRedirecting, setRedirecting] = useState(false);

  const checkoutSessionMutation = useMutation(createCheckoutSession);

  const {basesQuery} = useBaseApi({user});
  const { accountQuery } = useAccountApi({ user });

  const addSeatClick: MouseEventHandler<HTMLButtonElement> = async (e) => {

    checkoutSessionMutation.mutate({ user, redirect_to: window.location.href }, {
      onSuccess: (data: any) => {
        if (data?.redirect_url) {
          window.location.assign(data.redirect_url);
        }
      },
      onError: () => {
        alert("An error occured while generating your checkout session. Please try again later!");
      }
    })
  }

  const manageSubscriptionClick: MouseEventHandler<HTMLButtonElement> = async (e) => {

    if (!user?.uid) {
      return;
    }

    setRedirecting(true);

    const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');

    const result = await functionRef({
      returnUrl: window.location.href,
    });

    // @ts-ignore
    if (result.data && result.data?.url) {
      // @ts-ignore
      window.location.assign(result.data.url);
    } else {
      setRedirecting(false);
      alert("An error occured. Please try again later!");
    }
  }

  return (
    <div className="border rounded-md p-2 mb-4">
      <div className="flex mb-2">
        {(accountQuery.isFetching || checkoutSessionMutation.isLoading || isRedirecting) && <Spinner width="w-8" height="h-8" border="border-2" />}
        {!checkoutSessionMutation.isLoading && !isRedirecting && <h2 className="text-xl">Subcriptions</h2>}
        {checkoutSessionMutation.isLoading && <h2 className="text-xl">Generating checkout session... Thanks for your patience!</h2>}
        {isRedirecting && <h2 className="text-xl">Generating billing portal session... Thanks for your patience!</h2>}
      </div>
      <div className="border-t mt-2 pt-2">
        <div className="my-2">
          {!accountQuery?.data && <span className="text-slate-500">Loading...</span>}
          {accountQuery?.data && !accountQuery.data?.sub && <span className="text-slate-500">You own no active subscriptions.</span>}
        </div>
        <div className="my-2">
          {accountQuery?.data && accountQuery.data?.sub && <span>
            You own an active subscription for {accountQuery.data.sub.quantity} Premium Base(s)
            of which {accountQuery.data.sub.empty_seats} {(accountQuery.data.sub.empty_seats > 1) ? 'are' : 'is'} unused.
            {/* Your next billing cycle will begin on {new Date(accountQuery.data.sub.current_period_end).toDateString()}. */}
          </span>}
        </div>
        <PaidBaseList />
        {!!accountQuery.data?.sub?.empty_seats && basesQuery.isSuccess && <BaseSubscriptionSelector />}
        <div className="flex items-center mt-4">
          {user && accountQuery?.data && !accountQuery.data?.sub && <button disabled={checkoutSessionMutation.isLoading} onClick={addSeatClick} className={`px-4 py-2 rounded-md text-white ${checkoutSessionMutation.isLoading ? "bg-teal-300" : "bg-teal-500"}`}>Add seats</button>}
          {user && accountQuery.data?.sub && <>
            <button disabled={isRedirecting} onClick={manageSubscriptionClick} className={`px-4 py-2 rounded-md text-white ${isRedirecting ? "bg-teal-300" : "bg-teal-500"}`}>Manage subscriptions</button>
          </>}
        </div>
      </div>
    </div>
  );
}
