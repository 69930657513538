import { Formik, Field, Form, ErrorMessage } from "formik";

import { DBCrewType, FormCrewDependencyType } from "../../../../common/types";
import { useContext, useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";

type CrewDependencyAddFormParamsType = {
  crewId: string;
  crews: DBCrewType[];
  addItem: (dependency: FormCrewDependencyType) => void;
  setShowAddForm: (showAddForm: boolean) => void;
}

type FormErrorsType = {
  toCrew?: string;
  relation?: string;
}

export default function CrewDependencyAddForm({ crewId, crews, addItem, setShowAddForm }: CrewDependencyAddFormParamsType) {

  const initialValues: FormCrewDependencyType = {
    fromCrew: crewId,
    toCrew: "",
    relation: "",
  };

  const { user } = useContext(AuthContext) as AuthContextType;

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validate={values => {
          const errors: FormErrorsType = {};
          if (!values.toCrew) {
            errors.toCrew = 'Crew selection is required';
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          // console.log("submit", values);
          actions.setFieldValue("fromCrew", crewId, false);
          addItem(values);
          actions.setSubmitting(false);
          actions.resetForm();
          actions.setFieldValue("toCrew", "", false);
          actions.setFieldValue("relation", "", false);
        }}
      >
        <Form className="flex flex-col border-t mt-2 pt-2">
          <div className="w-full flex items-center justify-between">
            <h2 className="text-xl py-2">Add dependency</h2>
            <XMarkIcon onClick={() => setShowAddForm(false)} className="w-6 h-6" />
          </div>
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="toCrew">Dependent Crew</label>
            <Field name="toCrew" as="select" className="w-full border rounded-md p-1 my-2">
              <option value={0}>None</option>
              {crews.filter((crew: DBCrewType) => crew.id !== crewId).map((crew: DBCrewType) => <option key={crew.id} value={crew.id}>{crew.name}</option>)}
            </Field>
          </div>
          <ErrorMessage className="text-red-700" name="toCrew" component="div" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="toCrew">Relation</label>
            <Field name="relation" placeholder="Relation" className="w-full border rounded-md p-1 my-2" />
          </div>
          <ErrorMessage className="text-red-700" name="name" component="div" />
          <div className="flex items-center justify-end">
            <button
              type="button"
              className="w-1/3 md:w-1/4 p-2 px-4 my-2 border border-teal-500 tex-teal-500 rounded-md"
              onClick={(e) => { e.preventDefault(); setShowAddForm(false) }}
            >
              Cancel
            </button>
            <button type="submit" className="w-1/3 md:w-1/4 p-2 px-4 my-2 bg-teal-500 text-white rounded-md ml-2">Add</button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}
