import {
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// import { useElementSize } from "usehooks-ts";
import { Stage, Layer, Transformer, Rect, Text, Group } from "react-konva";
import {
  ChartViewType,
  DBStoreItemType,
} from "../../../common/types";

import BaseElement from "./BaseElement";
// import { getDefaultCanvasSize } from "./lib/measurements";
import Konva from "konva";

import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import useResponsiveCanvas from "../../../hooks/useResponsiveCanvas";
import AddItemsMenu from "./controls/AddItemsMenu";
import EditSelectedElementMenu from "./controls/EditSelectedElementMenu";
import useBaseApi from "../common/hooks/useBaseApi";
import BasePaidStatus from "../../account/BasePaidStatus";
import { TooltipContext, TooltipContextType } from "./context/TooltipContext";
import useMultiSelect from "../../../hooks/useMultiSelect";
import CanvasElement from "./CanvasElement";
import useOrderedElements from "../../../hooks/useOrderedElements";
import ChartMenu from "./controls/ChartMenu";
import CanvasElementsPanel from "./canvasElements/CanvasElementsPanel";
import { useEventListener } from "usehooks-ts";
import DependenciesChart from "./DependenciesChart";

export type SelectedElementType = {
  type: "turf" | "forum" | "crew" | "value_stream" | null;
  id?: string;
};

type UnfixChartPropsType = {
  baseId: string;
}

export default function UnfixChart({ baseId }: UnfixChartPropsType) {

  const { user } = useContext(AuthContext) as AuthContextType;

  const { tooltip } = useContext(TooltipContext) as TooltipContextType;

  const { baseQuery } = useBaseApi({ user, baseId });

  const [defaultWidthOverride, setDefaultWidthOverride] = useState<number | undefined>(undefined);
  const [defaultHeightOverride, setDefaultHeightOverride] = useState<number | undefined>(undefined);

  const { canvasRef, canvasWidth, canvasHeight, canvasScale } = useResponsiveCanvas(baseId, defaultWidthOverride, defaultHeightOverride);

  // export canvas into image
  const stageRef: RefObject<Konva.Stage> = useRef(null);

  //tranformer reference
  const trRef = useRef<any>();
  const selectionRectRef = useRef<any>();

  const { onMouseDown, onMouseMove, onMouseUp, onClickTap, selectedElement, setSelectedElement } = useMultiSelect(
    stageRef, trRef, selectionRectRef, canvasScale);

  const { orderedElements, setOrderedElements } = useOrderedElements(baseId)

  const [showCanvasElementsPanel, setShowCanvasElementsPanel] = useState<boolean>(false);

  const [isPageVisible, setIsPageVisible] = useState<boolean>(true);

  const documentRef = useRef<Document>(document)

  const [tooltipText, setTooltipText] = useState<string>("");
  const [tooltipPosition, setTooltipPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });

  const VIEWS: ChartViewType[] = ['Base', 'Dependencies'];
  const [view, setView] = useState<ChartViewType>(VIEWS[0]);

  useEffect(() => {
    if (view === "Dependencies") {
      setDefaultWidthOverride(1100);
      setDefaultHeightOverride(1020);
    } else {
      setDefaultWidthOverride(undefined);
      setDefaultHeightOverride(undefined);
    }
  }, [view]);

  const onVisibilityChange = (event: Event) => {
    console.log('doc visibility changed!', {
      isVisible: !document.hidden,
      event,
    })

    setIsPageVisible(!document.hidden);
  }

  useEventListener('visibilitychange', onVisibilityChange, documentRef)

  return (
    <div id="unfix_chart" className="w-full">

      <div className="py-2 md:px-2 w-full">
        <BasePaidStatus />
        <ChartMenu
          baseId={baseId}
          stageRef={stageRef}
          canvasScale={canvasScale}
          showCanvasElementsPanel={showCanvasElementsPanel}
          setShowCanvasElementsPanel={setShowCanvasElementsPanel}
          views={VIEWS}
          view={view}
          setView={setView}
        />

        {showCanvasElementsPanel && (
          <CanvasElementsPanel
            orderedElements={orderedElements}
            setOrderedElements={setOrderedElements}
            baseId={baseId} />
        )}

        {baseQuery.data?.display_options?.show_tooltip && <div className="border rounded-md p-2 mt-2">
          {tooltip}
        </div>}

        <div className="border rounded-md p-2 mt-2">
          {/* <p>
            You can select multiple elements by drawing a selection with your mouse while holding the left mouse button or by Shit + Left click.
          </p> */}
          {view === "Base" && <div ref={canvasRef} className="w-full h-screen overflow-scroll mt-2">
            {isPageVisible && <Stage
              width={canvasWidth}
              height={canvasHeight}
              scale={canvasScale}

              onMouseDown={onMouseDown}
              onTouchStart={onMouseDown}
              onMouseUp={onMouseUp}
              onTouchEnd={onMouseUp}
              onMouseMove={onMouseMove}
              onTouchMove={onMouseMove}
              onClick={onClickTap}
              onTap={onClickTap}
              ref={stageRef}
            // draggable={true}
            >
              <Layer id="base-layer">
                {baseQuery.data && baseQuery.data.display_options && <BaseElement
                  base={baseQuery.data}
                  isRectangular={baseQuery.data.display_options.rectangular_base}
                />}
              </Layer>
              <Layer id="elements-layer">
                {orderedElements.filter((element: DBStoreItemType) => (!!element.visible)).map((element: DBStoreItemType) => (
                  <CanvasElement
                    key={element.id}
                    element={element}
                    baseId={baseId}
                    base={baseQuery.data}
                    setTooltipText={setTooltipText}
                    setTooltipPosition={setTooltipPosition}
                  />
                ))}
              </Layer>
              <Layer id="tooltip-layer">
                {tooltipText && tooltipPosition && (<Group
                  x={tooltipPosition.x}
                  y={tooltipPosition.y}
                >
                  <Rect
                    x={0}
                    y={0}
                    width={tooltipText.length * 11}
                    height={24}
                    fill="white"
                    stroke="black"
                    strokeWidth={1}
                    shadowBlur={5}
                  />
                  <Text
                    x={0}
                    y={2}
                    text={tooltipText}
                    fill="black"
                    fontSize={20}
                    align="center"
                    verticalAlign="center"
                  />
                </Group>)}
              </Layer>
              <Layer id="selection-layer">
                <Transformer
                  ref={trRef}
                  rotateEnabled={false}
                  boundBoxFunc={(oldBox, newBox) => {
                    // limit resize
                    if (newBox.width < 50 || newBox.height < 50) {
                      return oldBox;
                    }
                    return newBox;
                  }}
                />
                <Rect fill="rgba(0,0,255,0.5)" ref={selectionRectRef} />
              </Layer>
            </Stage>}
            <div className="flex fixed bottom-4 right-4 p-2 px-4">
              {selectedElement?.id && selectedElement?.type && (
                <EditSelectedElementMenu
                  selectedElement={selectedElement}
                  selectElement={setSelectedElement}
                  orderedElements={orderedElements}
                  setOrderedElements={setOrderedElements}
                  baseId={baseId}
                  user={user}
                />
              )}
              <AddItemsMenu baseId={baseId} user={user} />
            </div>
          </div>}

          {view === "Dependencies" && <div ref={canvasRef} className="w-full h-screen overflow-scroll mt-2">
            {isPageVisible && <Stage
              width={canvasWidth}
              height={canvasHeight}
              scale={canvasScale}

              ref={stageRef}
            // draggable={true}
            >
              <Layer id="dependencies-layer">
                <DependenciesChart
                  baseId={baseId}
                />
              </Layer>
            </Stage>}
          </div>}
        </div>
      </div>
    </div>
  );
}
