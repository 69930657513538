import { Dialog } from '@headlessui/react'
import { User } from 'firebase/auth';
import useTurfApi from '../../common/hooks/useTurfApi';
import TurfForm from '../../editor/turf/TurfForm';

type AddTurfDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  user: User | null;
  baseId: string;
}

export default function AddTurfDialog({ isOpen, setIsOpen, baseId, user }: AddTurfDialogProps) {

  const addSuccessCB = () => {
    setIsOpen(false);
  }
  const {addTurf} = useTurfApi({baseId, user, addSuccessCB});

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel className="mx-auto w-full lg:w-1/2 p-4 rounded bg-white">
          <Dialog.Title>Add Turf</Dialog.Title>

          <TurfForm addItem={addTurf} />

          <button
            onClick={() => setIsOpen(false)}
            className="w-full p-2 border border-teal-500 rounded-md"
          >
            Cancel
          </button>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
