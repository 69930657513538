import { useContext } from "react";

import CrewForm from "./CrewAddForm";
import { Crew } from "./Crew";
import { DBCrewType } from "../../../../common/types";
// import { ReactSortable } from "react-sortablejs";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import Spinner from "../../../baseList/Spinner";
import useCrewApi from "../../common/hooks/useCrewApi";

type CrewListPropsType = {
  baseId: string;
}

export default function CrewList({ baseId }: CrewListPropsType) {

  const { user } = useContext(AuthContext) as AuthContextType;
  const {
    crewsQuery,
    createCrewMutation,
    addCrew,
    deleteCrewMutation,
    removeCrew
  } = useCrewApi({baseId, user});

  return (
    <div className="py-2 md:px-2 w-full md:w-1/2 lg:w-1/3">
      <div className="border rounded-md p-2">
        <div className="flex mb-2">
          {(crewsQuery.isFetching || deleteCrewMutation.isLoading || createCrewMutation.isLoading) &&
            <Spinner width="w-8" height="h-8" border="border-2" />}
          <h2 className="text-xl">Crews</h2>
        </div>

        {crewsQuery.data?.length === 0 && (
          <ul className="my-2">
            <li className="text-slate-500">No items...</li>
          </ul>
        )}

        {crewsQuery.data?.length > 0 && (
          <div>
            {crewsQuery.data?.map((crew: DBCrewType) => (
              <Crew key={crew.id} crew={crew} deleteItem={removeCrew} />
            ))}
          </div>
        )}
        <CrewForm addItem={addCrew} />
      </div>
    </div>
  );
}
