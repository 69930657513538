import { useContext } from "react";
import { Outlet, Link } from "react-router-dom";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { Menu } from "@headlessui/react";

export default function Layout() {
  const { user, logOut } = useContext(AuthContext) as AuthContextType;

  const handleLogout = async () => {
    try {
      await logOut();
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="w-full h-full">
      {/* <div>AAAAAA {process.env.REACT_APP_ENV_NAME}</div> */}
      <nav className="flex justify-between items-center mb-1 py-2 px-4 text-white bg-teal-500">
        <div className="flex items-center">
          <a href="/"><img alt="" className="h-6" src="/favicon.png" /></a>
        </div>
        <div className="flex items-center justify-end">
          {user && <Link to="/" className="mr-2">My Bases</Link>}

          {user && <div className="relative">
            <Menu>
              <Menu.Button className="flex items-center">
                Profile
                <UserCircleIcon className="ml-1 w-6 h-6" />
              </Menu.Button>
              <Menu.Items as="div" className="flex flex-col absolute right-0 top-10 z-10 bg-white rounded-md border-2 border-teal-500 text-black">
                <Menu.Item>
                  <Link
                    to="/account"
                    className="py-2 px-4 whitespace-nowrap ui-active:bg-teal-500 ui-active:text-white"
                  >
                    Account
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={handleLogout}
                    className="py-2 px-4 whitespace-nowrap ui-active:bg-teal-500 ui-active:text-white"
                  >
                    Sign out
                  </button>
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </div>}

          {!user && <Link to="/sign_in" className="mr-2">Sign in</Link>}
        </div>
      </nav>
      <main>
        <Outlet />
      </main>
    </div>
  );
}
