export const FULLY_INTEGRATED_BASE: number = 1;
const STRONGLY_ALIGNED_BASE: number = 2;
const LOOSELY_ALIGNED_BASE: number = 3;
const FULLY_SEGREGATED_BASE: number = 4;

export const BASE_TYPES = [
  { id: FULLY_INTEGRATED_BASE, name: "Fully Integrated Base" },
  { id: STRONGLY_ALIGNED_BASE, name: "Strongly Aligned Base" },
  { id: LOOSELY_ALIGNED_BASE, name: "Loosely Aligned Base" },
  { id: FULLY_SEGREGATED_BASE, name: "Fully Segregated Base" },
];

export const base_type_attributes = {
  [FULLY_INTEGRATED_BASE]: {
    dash: [10, 0],
  },
  [STRONGLY_ALIGNED_BASE]: {
    dash: [30, 10]
  },
  [LOOSELY_ALIGNED_BASE]: {
    dash: [10, 5]
  },
  [FULLY_SEGREGATED_BASE]: {
    dash: [2, 2]
  },
}
