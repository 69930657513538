import { useMutation, useQuery, useQueryClient } from 'react-query';
import { User } from 'firebase/auth';
import { createCrewMember, deleteCrewMember, getCrewMembers, patchCrewMember } from '../../../../api/crewMemberApi';
import { FormTeamMemberType, TeamMemberType } from '../../../../common/types';

type useCrewMemberApiParams = {
  baseId: string;
  user: User | null;
  crewId?: string;
  crewMembersGroupBy: string;
  addSuccessCB?: () => void;
  removeSuccessCB?: () => void;
  patchSuccessCB?: () => void;
}

export default function useCrewMemberApi({
  baseId,
  user,
  crewId,
  crewMembersGroupBy,
  addSuccessCB,
  removeSuccessCB,
  patchSuccessCB,
}: useCrewMemberApiParams) {
  const queryClient = useQueryClient();

  const crewMembersQuery = useQuery(["crew_members", baseId, crewId, crewMembersGroupBy],
    // @ts-ignore
    () => getCrewMembers({ user, baseId, crewId, groupBy: crewMembersGroupBy }),
    { enabled: !!user && !!baseId && !!crewId });
  const createCrewMemberMutation = useMutation(createCrewMember);
  const deleteCrewMemberMutation = useMutation(deleteCrewMember);
  const patchCrewMemberMutation = useMutation(patchCrewMember);

  const addCrewMember = (crewMemberData: FormTeamMemberType, avatarImage: string = "") => {
    if (!crewId) {
      return;
    }

    let crewMember: TeamMemberType = {
      ...crewMemberData,
      id: "",
    };

    createCrewMemberMutation.mutate({ user, baseId, crewId, crewMember, avatarImage }, {
      onSuccess: () => {
        queryClient.invalidateQueries(["crews", baseId]);
        queryClient.invalidateQueries(["crew_members", baseId, crewId]);
        queryClient.invalidateQueries(["base_members", baseId]);
      },
    });
  }


  const removeCrewMember = (crewMemberId: string) => {
    if (!crewId) {
      return;
    }
    
    if (window.confirm("Are you sure you want to delete this member?") === true) {
      deleteCrewMemberMutation.mutate({ user, baseId, crewId, crewMemberId }, {
        onSuccess: () => {
          queryClient.invalidateQueries(["crews", baseId]);
          queryClient.invalidateQueries(["crew_members", baseId, crewId]);
          queryClient.invalidateQueries(["base_members", baseId]);
        }
      });
    }
  }

  const editCrewMember = (crewMember: TeamMemberType, avatarImage: string = "") => {
    if (!crewId) {
      return;
    }
    
    patchCrewMemberMutation.mutate({user, baseId, crewId, crewMember, avatarImage}, {
      onSuccess: () => {
        queryClient.invalidateQueries(["crew_members", baseId, crewId]);
        queryClient.invalidateQueries(["base_members", baseId]);
      }
    })
  }

  return {
    crewMembersQuery,
    createCrewMemberMutation,
    addCrewMember,
    deleteCrewMemberMutation,
    removeCrewMember,
    patchCrewMemberMutation,
    editCrewMember,
  };
}
