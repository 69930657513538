import { User } from "firebase/auth";
import { CanvasElementType, CanvasResizeType } from "../common/types";

type ReorderCanvasElementsParams = {
  user: User | null;
  baseId: string | undefined;
  elements: CanvasElementType[];
};


export async function reorderCanvasElements(params: ReorderCanvasElementsParams) {
  const { user, baseId, elements } = params;

  const data = {
    order: {
      order: elements
    }
  }

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/canvas_elements/reorder/?base=${baseId}`,
    {
      method: "PUT",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't save order");
  }

  // return response.json();
}

type SetCanvasElementVisibilitysParams = {
  user: User | null;
  baseId: string | undefined;
  element: CanvasElementType;
};

export async function setCanvasElementVisibility(params: SetCanvasElementVisibilitysParams) {
  const { user, baseId, element } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/canvas_elements/set_visibility/?base=${baseId}`,
    {
      method: "PATCH",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(element),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't set visibility");
  }
}

type ResizeCanvasParams = {
  user: User | null;
  baseId: string | undefined;
  data: CanvasResizeType;
};

export async function resizeCanvasAndElements(params: ResizeCanvasParams) {
  const { user, baseId, data } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/canvas_elements/resize_canvas/?base=${baseId}`,
    {
      method: "PATCH",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't resize canvas");
  }
}

type AutoLayoutParams = {
  user: User | null;
  baseId: string | undefined;
};

export async function canvasAutoLayout(params: AutoLayoutParams) {
  const { user, baseId } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/canvas_elements/auto_layout/?base=${baseId}`,
    {
      method: "POST",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't resize canvas");
  }
}
