import { Menu } from "@headlessui/react";
// import { DBValueStreamType } from "../../../../common/types";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { DBValueStreamType } from "../../../../common/types";
import { shadeHexColor } from "../../../../lib/pSBC";
import { valueStream_type_attributes } from "../../../../common/constants/valueStreamTypes";

type ValueStreamProps = {
  valueStream: DBValueStreamType;
  deleteItem: (valueStreamId: string) => void;
};

function ValueStream({ valueStream, deleteItem }: ValueStreamProps) {
  const bgColor =
    valueStream.type in valueStream_type_attributes
      ? valueStream_type_attributes[valueStream.type]["color"]
      : "";

  const borderColor = shadeHexColor(bgColor, -0.1);

  return (
    <li className="p-1 mb-1 text-white border rounded-md flex items-center justify-between cursor-grab" style={{ backgroundColor: bgColor }}>
      <span className="">{valueStream.name}</span>

      <div className="relative">
        <Menu>
          <Menu.Button className="flex items-center">
            <AdjustmentsHorizontalIcon className="ml-1 w-6 h-6" />
          </Menu.Button>
          <Menu.Items as="div" className="flex flex-col absolute right-0 top-5 z-10 bg-white rounded-md border-2 text-black" style={{ borderColor: borderColor }}>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to={`/base/${valueStream.base}/value_stream/${valueStream.id}`}
                  className={`py-2 px-4 whitespace-nowrap ui-active:bg-stone-300 ui-active:text-white`}
                  style={active ? { backgroundColor: bgColor } : {}}
                >
                  Details
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (

                <button
                  onClick={() => deleteItem(valueStream.id)}
                  className="py-2 px-4 whitespace-nowrap ui-active:bg-stone-300 ui-active:text-white"
                  style={active ? { backgroundColor: bgColor } : {}}
                >
                  Delete
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
    </li>
  );
}

export { ValueStream };
