import { Tab } from "@headlessui/react";

import Editor from "../components/baseEditor/editor/Editor";
import UnfixChart from "../components/baseEditor/canvas/UnfixChart";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BaseMemberList from "../components/baseEditor/editor/baseMembers/BaseMemberList";
import Breadcrumbs from "../components/baseEditor/common/Breadcrumbs";
import { useContext, useEffect, useState } from "react";
import { AuthContext, AuthContextType } from "../context/AuthContext";
import useIsAuthenticated from "../hooks/useIsAuthenticated";
import usePaidAccountRequired from "../hooks/usePaidAccountRequired";
import RevisionList from "../components/baseEditor/editor/revision/RevisionList";
import { DocumentDuplicateIcon, ListBulletIcon, RectangleGroupIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { TooltipContextProvider } from "../components/baseEditor/canvas/context/TooltipContext";

export default function Main() {

  const { user } = useContext(AuthContext) as AuthContextType;
  const { baseId } = useParams();

  useIsAuthenticated();
  usePaidAccountRequired(baseId);

  const location = useLocation();
  const navigate = useNavigate();
  const tabHashes = ["#editor", "#members", "#chart", "#revisions"];
  const [selectedIndex, setSelectedIndex] = useState(0)

  const tabChanged = (index: number) => {
    setSelectedIndex(index);
    navigate(tabHashes[index]);
  }
  useEffect(() => {
    let index = tabHashes.findIndex((el: string) => el === location.hash);
    if (index === -1) {
      index = 0;
    }
    setSelectedIndex(index);
  }, [location.hash])

  return (
    <div className="w-full h-screen p-2">
      <Breadcrumbs />
      {user && <Tab.Group selectedIndex={selectedIndex} onChange={tabChanged}>
        <Tab.List className="flex text-xl ml-2">
          <Tab className="flex items-center ui-selected:bg-teal-100 ui-not-selected:bg-white w-fit rounded-t-lg py-2.5 px-4 border">
            <ListBulletIcon className="w-6 h-6 md:mr-1" />
            <span className="hidden md:inline-block">Editor</span>
          </Tab>
          <Tab className="flex items-center ui-selected:bg-teal-100 ui-not-selected:bg-white w-fit rounded-t-lg py-2.5 px-4 border">
            <UserGroupIcon className="w-6 h-6 md:mr-1" />
            <span className="hidden md:inline-block">Members</span>
          </Tab>
          <Tab className="flex items-center ui-selected:bg-teal-100 ui-not-selected:bg-white w-fit rounded-t-lg py-2.5 px-4 border">
            <RectangleGroupIcon className="w-6 h-6 md:mr-1" />
            <span className="hidden md:inline-block">Chart</span>
          </Tab>
          <Tab className="flex items-center ui-selected:bg-teal-100 ui-not-selected:bg-white w-fit rounded-t-lg py-2.5 px-4 border">
            <DocumentDuplicateIcon className="w-6 h-6 md:mr-1" />
            <span className="hidden md:inline-block">Revisions</span>
          </Tab>
        </Tab.List>
        <Tab.Panels className="w-full p-2 border-t">
          <Tab.Panel>
            {baseId && <Editor baseId={baseId} />}
          </Tab.Panel>
          <Tab.Panel>
            {baseId && <BaseMemberList baseId={baseId} />}
          </Tab.Panel>
          <Tab.Panel>
            {baseId && <TooltipContextProvider>
              <UnfixChart baseId={baseId} />
            </TooltipContextProvider>}
          </Tab.Panel>
          <Tab.Panel>
            {baseId && <RevisionList baseId={baseId} />}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>}
    </div>
  );
}
