import { useState, useRef, useEffect } from 'react'
import Cropper from 'react-easy-crop'
import { ref, getDownloadURL } from "firebase/storage";
import { avatarStorage } from "../../../../firebase";

// This function returns the cropped image as a Blob
const getCroppedImg = async (imageUrl, croppedAreaPixels) => {
  const canvas = document.createElement('canvas');
  const scaleX = 1; // If the image is scaled, adjust accordingly
  const scaleY = 1; // If the image is scaled, adjust accordingly

  canvas.width = 100;
  canvas.height = 100;
  // canvas.width = croppedAreaPixels.width;
  // canvas.height = croppedAreaPixels.height;
  const ctx = canvas.getContext('2d');

  const image = new Image();
  image.src = imageUrl; // Replace with your image URL

  return new Promise((resolve, reject) => {
    image.onload = () => {
      try {
        ctx.drawImage(
          image,
          croppedAreaPixels.x * scaleX,
          croppedAreaPixels.y * scaleY,
          croppedAreaPixels.width * scaleX,
          croppedAreaPixels.height * scaleY,
          0,
          0,
          100,
          100
          // croppedAreaPixels.width,
          // croppedAreaPixels.height
        );

        const base64 = canvas.toDataURL("image/jpeg");
        resolve(base64);

        // canvas.toBlob((blob) => {
        //   resolve(blob);
        // });
      } catch (error) {
        reject(error);
      }
    };
    image.onerror = reject;
  });

  // return canvas.toBlob();
};

export default function ImageCropper({ croppedImage, setCroppedImage, existingAvatar = "" }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [imageUrl, setImageUrl] = useState();
  const [existingAvatarUrl, setExistingAvatarUrl] = useState();
  //   const [croppedImage, setCroppedImage] = useState(null);
  const cropperRef = useRef();

  useEffect(() => {
    if (existingAvatar) {
      const imgRef = ref(avatarStorage, existingAvatar);

      getDownloadURL(imgRef).then((url) => {
        setExistingAvatarUrl(url);
      });
    } else {
      setExistingAvatarUrl("");
    }

  })

  useEffect(() => {
    if (!croppedImage) {
      setImageUrl("");
    }
  }, [croppedImage])

  const onCropComplete = async (_, croppedAreaPixels) => {
    try {
      const croppedImageBase64 = await getCroppedImg(imageUrl, croppedAreaPixels);
      setCroppedImage(croppedImageBase64);
    } catch (error) {
      console.error('Error cropping image:', error);
    }
  };

  const getImageUrl = (e) => {
    if (e.target.files?.length) {
      setImageUrl(URL.createObjectURL(e.target.files[0]));
    } else {
      setImageUrl("");
    }
  };

  return (
    <div className="w-full py-1">
      {existingAvatarUrl && <div className="w-16 h-16 mr-1 my-1 rounded-lg border bg-contain bg-center bg-no-repeat bg-clip-content" style={{ backgroundImage: `url("${existingAvatarUrl}")` }} />}
      <input
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        onChange={getImageUrl}
        className="my-1"
      />
      {imageUrl && <>
        <div className="w-full h-40 relative">
          <Cropper
            image={imageUrl}
            crop={crop}
            zoom={zoom}
            aspect={1 / 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            ref={cropperRef}
            cropShape="round"
          />
        </div>
        <div className="controls my-1 flex items-center">
          <span className="mr-2">Zoom</span>
          <input
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
              setZoom(e.target.value)
            }}
            className="zoom-range mr-2"
          />
          {/* <button className="px-4 py-2 bg-teal-500 text-white" onClick={handleCrop}>Crop Image</button> */}
          <button className="px-4 py-2 border border-teal-500 rounded-md" onClick={() => { setImageUrl(null); setCroppedImage(""); }}>Cancel</button>
        </div>
      </>}
    </div>
  )
}
