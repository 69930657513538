import { ErrorMessage, Field, Form, Formik } from "formik";
import { DBForumType, DBForumPatchType } from "../../../../common/types";
import { FORUM_TYPES } from "../../../../common/constants/forumTypes";

type ForumEditFormProps = {
  forum: DBForumType,
  editItem: (forum: DBForumPatchType) => void;
}

type FormErrorsType = {
  name?: string;
  type?: string;
}

export default function ForumEditForm({ forum, editItem }: ForumEditFormProps) {

  return (
    <div>
      <Formik
        initialValues={forum}
        validate={values => {
          const errors: FormErrorsType = {};
          if (!values.name) {
            errors.name = 'Name is required';
          }
          if (!values.type) {
            errors.type = 'Type is required';
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          editItem(values);
          actions.setSubmitting(false);
        }}
      >
        <Form className="flex flex-col border-t mt-2 pt-2">
          <div className="flex flex-col md:flex-row md:items-center justify-between">
          <label className="w-full md:w-4/12 lg:w-4/12" htmlFor="name">Name</label>
            <Field name="name" placeholder="Forum name" className="w-full border rounded-md p-1 my-2" />
          </div>
          <ErrorMessage className="text-red-700" name="name" component="div" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
          <label className="w-full md:w-4/12 lg:w-4/12" htmlFor="type">Type</label>
            <Field name="type" as="select" className="w-full border rounded-md p-1 my-2">
              {FORUM_TYPES.map((type) => <option key={type.id} value={type.id}>{type.name}</option>)}
            </Field>
          </div>
          <ErrorMessage className="text-red-700" name="type" component="div" />
          <div className="flex items-center justify-end">
            <button type="submit" className="w-1/3 md:w-1/4 p-2 px-4 my-2 bg-teal-500 text-white rounded-md">Save</button>
          </div>
        </Form>
      </Formik>
    </div>
  );

}
