import { Dialog } from '@headlessui/react'
import { User } from 'firebase/auth';
import useForumApi from '../../common/hooks/useForumApi';
import ForumForm from '../../editor/forum/ForumForm';

type AddForumDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  user: User | null;
  baseId: string;
}

export default function AddForumDialog({ isOpen, setIsOpen, baseId, user }: AddForumDialogProps) {

  const addSuccessCB = () => {
    setIsOpen(false);
  }
  const {addForum} = useForumApi({baseId, user, addSuccessCB});

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel className="mx-auto w-full lg:w-1/2 p-4 rounded bg-white">
          <Dialog.Title>Add Forum</Dialog.Title>

          <ForumForm addItem={addForum} />

          <button
            onClick={() => setIsOpen(false)}
            className="w-full p-2 border border-teal-500 rounded-md"
          >
            Cancel
          </button>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
