import { User } from "firebase/auth";
import { CrewDependencyType, FormCrewDependencyType } from "../common/types";

type GetAllDependeciesParamsType = {
  user: User | null;
  baseId: string;
}

export async function getAllDependecies(params: GetAllDependeciesParamsType) {
  const { user, baseId } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/dependencies/?base=${baseId}`,
    {
      headers: {
        "Authorization": "" + token,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't fetch dependency list");
  }

  return response.json();
}

type GetDependecyParamsType = {
  user: User | null;
  baseId: string;
  dependencyId: string;
}

export async function getDependency(params: GetDependecyParamsType) {
  const { user, baseId, dependencyId } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/dependencies/${dependencyId}?base=${baseId}`,
    {
      headers: {
        "Authorization": "" + token,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't fetch dependency");
  }

  return response.json();
}

type GetCrewDependeciesParamsType = {
  user: User | null;
  baseId: string;
  crewId: string;
}

export async function getCrewDependecies(params: GetCrewDependeciesParamsType) {
  const { user, baseId, crewId } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/dependencies/list_by_crew/?base=${baseId}&crew=${crewId}`,
    {
      headers: {
        "Authorization": "" + token,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't fetch crew dependency list");
  }

  return response.json();
}

type CreateDependencyParamsType = {
  user: User | null;
  baseId: string;
  dependency: FormCrewDependencyType;
};

export async function createDependency(params: CreateDependencyParamsType) {
  const { user, baseId, dependency } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/dependencies/?base=${baseId}`,
    {
      method: "POST",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...dependency }),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't create dependency");
  }

  return response.json();
}

type PatchDependencyParamsType = {
  user: User | null;
  baseId: string;
  dependency: CrewDependencyType;
};


export async function patchDependency(params: PatchDependencyParamsType) {
  const { user, baseId, dependency } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/dependencies/${dependency.id}/?base=${baseId}`,
    {
      method: "PATCH",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...dependency }),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't patch dependency");
  }

  return response.json();
}

type DeleteDependencyParamsType = {
  user: User | null;
  baseId: string;
  dependencyId: string;
};

export async function deleteDependency(params: DeleteDependencyParamsType) {
  const { user, baseId, dependencyId } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/dependencies/${dependencyId}/?base=${baseId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't delete dependency");
  }
}
