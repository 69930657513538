import Spinner from "../../../baseList/Spinner";
import { FormCrewDependencyType, CrewDependencyType, DBCrewType } from "../../../../common/types";
import { useContext, useEffect, useState } from "react";
import { PlusIcon } from "@heroicons/react/24/outline";
import useDependencyApi from "../../common/hooks/useDependencyApi";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import CrewDependency from "./CrewDependency";
import CrewDependencyAddForm from "./CrewDependencyAddForm";
import useCrewApi from "../../common/hooks/useCrewApi";
import CrewDependencyEditForm from "./CrewDependencyEditForm";

type CrewDependencyListProps = {
  baseId: string;
  crewId: string;
}

type CrewDependencyElementType = {
  dependency: CrewDependencyType;
  crew: DBCrewType;
}

export default function CrewDependencyList({ baseId, crewId }: CrewDependencyListProps) {

  const [editingDependency, setEditingDependency] = useState<CrewDependencyType | null>(null);
  const [showAddForm, setShowAddForm] = useState<boolean>(false);

  const { user } = useContext(AuthContext) as AuthContextType;
  const {crewsQuery} = useCrewApi({baseId: baseId, user: user});

  const {
    crewDependenciesQuery,
    dependencyQuery,
    addDependency,
    editDependency,
    removeDependency
  } = useDependencyApi({ baseId, user, crewId, dependencyId: editingDependency?.id, });

  const [crewDependencies, setCrewDependencies] = useState<CrewDependencyElementType[]>([]);

  useEffect(() => {

    if (crewDependenciesQuery.data && crewsQuery.data) {
      const newCrewDependencies: CrewDependencyElementType[] = [];
      crewDependenciesQuery.data.forEach((dependency: CrewDependencyType) => {
        // const crew = crewsQuery.data.find((crew: DBCrewType) => crew.id === dependency.toCrew) as DBCrewType;

        let idToFind;

        if (crewId === dependency.fromCrew) {
          idToFind = dependency.toCrew;
        } else {
          idToFind = dependency.fromCrew;
        }

        for (let crew of crewsQuery.data) {
          if (crew.id === idToFind) {
            newCrewDependencies.push({ dependency, crew });
            break;
          }
        }
      });
      setCrewDependencies(newCrewDependencies);
    }
  }, [crewDependenciesQuery.data, crewsQuery.data])

  return (
    <div className="py-2 md:px-2 w-full">
      <div className="border rounded-md p-2">
        <div className="flex justify-between mb-2">
          <span className="flex items-center">
            {(crewDependenciesQuery.isFetching) &&
              <Spinner width="w-8" height="h-8" border="border-2" />}
            <h2 className="text-xl">Dependencies</h2>
          </span>
          <div className="flex items-center justify-end">
            <div>
              <button
                className="flex items-center px-4 py-2 rounded-md bg-teal-500 text-white"
                onClick={() => { setEditingDependency(null); setShowAddForm(true) }}
              >
                <PlusIcon className="w-6 h-6 mr-1" />
                <span className="hidden md:inline-block">Add</span>
              </button>
            </div>
          </div>
        </div>

        {!crewDependenciesQuery.data && (
          <ul className="my-2">
            <li className="text-slate-500">No items...</li>
          </ul>
        )}

        {crewDependencies && (
          <div className="w-full flex flex-wrap">
              {crewDependencies.map((dependency: CrewDependencyElementType) => (
                <CrewDependency
                  key={dependency.dependency.id}
                  dependency={dependency.dependency}
                  crew={dependency.crew}
                  deleteItem={removeDependency}
                  setEditingDependency={setEditingDependency}
                  setShowAddForm={setShowAddForm}
                />
              ))}
          </div>
        )}
        {editingDependency && <CrewDependencyEditForm dependency={editingDependency} crewId={crewId} crews={crewsQuery.data} setEditingDependency={setEditingDependency} editItem={editDependency} />}
        {showAddForm && <CrewDependencyAddForm crews={crewsQuery.data} crewId={crewId} addItem={addDependency} setShowAddForm={setShowAddForm} />}
      </div>
    </div>
  );

}