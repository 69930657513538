import { KonvaEventObject } from "konva/lib/Node";
import { crew_type_attributes } from "../../../common/constants/crewTypes";
import { DBCrewType, DBForumType, DBStoreItemType, DBTurfType, DBValueStreamType, FetchedBaseType, TeamMemberType } from "../../../common/types"
import HorizontalCrewElement from "./crew/HorizontalCrewElement";
import VerticalCrewElement from "./crew/VerticalCrewElement";
import ForumElement from "./forum/ForumElement";
import TurfElement from "./turf/TurfElement";
import ValueStreamElement from "./valueStream/ValueStreamElement";

type CanvasElementProps = {
  element: DBStoreItemType;
  baseId: string;
  base: FetchedBaseType;
  setTooltipText: (text: string) => void;
  setTooltipPosition: (position: { x: number, y: number }) => void;
};

export default function CanvasElement({element, baseId, base, setTooltipText, setTooltipPosition}: CanvasElementProps) {

  const handleMemberHover = (e: KonvaEventObject<MouseEvent>, member: TeamMemberType) => {
    const pos = e.target.getStage()?.getPointerPosition();
    const scaleX = e.target.getStage()?.scaleX();
    const scaleY = e.target.getStage()?.scaleY();

    if (pos?.x && pos?.y && scaleX && scaleY) {
      setTooltipPosition({ x: (pos.x / scaleX ) + 10, y: (pos.y / scaleY) + 20 });
      setTooltipText(" " + member.name + " ");
    }
  }

  const handleMemberHoverOut = () => {
    setTooltipText("");
  }


  switch (element.elementType) {
    case "forum":
      return <ForumElement
        key={element.id}
        forum={element as DBForumType} 
        baseId={baseId}
        base={base}
        handleMemberHover={handleMemberHover}
        handleMemberHoverOut={handleMemberHoverOut}
      />;
    case "value_stream":
      return <ValueStreamElement valueStream={element as DBValueStreamType} base={base} />;
    case "turf":
      return <TurfElement turf={element as DBTurfType} base={base} />;
    case "crew":
      if (crew_type_attributes[(element as DBCrewType).type]["vertical"]) {
        return <VerticalCrewElement
          crew={element as DBCrewType}
          baseId={baseId}
          base={base}
          handleMemberHover={handleMemberHover}
          handleMemberHoverOut={handleMemberHoverOut}
        />;
      } else {
        return <HorizontalCrewElement
          crew={element as DBCrewType}
          baseId={baseId}
          base={base}
          handleMemberHover={handleMemberHover}
          handleMemberHoverOut={handleMemberHoverOut}
        />;
      }
    default:
      return <></>
  }
}
