import { useContext, useEffect, useState } from "react";
import { useElementSize } from "usehooks-ts";
import { AuthContext, AuthContextType } from "../context/AuthContext";
import useBaseApi from "../components/baseEditor/common/hooks/useBaseApi";

export type ScaleType = {
  x: number;
  y: number;
};

export default function useResponsiveCanvas(
  baseId: string,
  defaultWidthOverride?: number,
  defaultHeightOverride?: number
) {
  // responsive canvas sizing
  const {user} = useContext(AuthContext) as AuthContextType;
  const { baseQuery } = useBaseApi({ user, baseId });

  const [canvasScale, setCanvasScale] = useState<ScaleType>({ x: 1, y: 1 });
  const [canvasWidth, setCanvasWidth] = useState<number>(1);
  const [canvasHeight, setCanvasHeight] = useState<number>(1);

  const [canvasRef, { width: containerWidth, height: containerHeight }] =
    useElementSize();

  useEffect(() => {
    if (containerWidth && containerHeight && baseQuery.data?.display_options) {
      const defaultWidth = defaultWidthOverride || baseQuery.data.display_options.canvas_size.width;
      const defaultHeight = defaultHeightOverride || baseQuery.data.display_options.canvas_size.height;

      const fitContainerX = baseQuery.data.display_options.fit_container_x;
      const fitContainerY = baseQuery.data.display_options.fit_container_y;

      if (fitContainerX && fitContainerY) {
        let minCanvasWidth = Math.min(containerWidth, defaultWidth);
        let minCanvasHeight = Math.min(containerHeight - 100, defaultHeight);

        if (minCanvasWidth / defaultWidth <= minCanvasHeight / defaultHeight) {
          minCanvasHeight = (minCanvasWidth / defaultWidth) * defaultHeight;
        } else {
          minCanvasWidth = (minCanvasHeight / defaultHeight) * defaultWidth;
        }

        const widthScale = minCanvasWidth / defaultWidth;
        const heightScale = minCanvasHeight / defaultHeight;

        const scale = Math.min(widthScale, heightScale);

        setCanvasWidth(minCanvasWidth);
        setCanvasHeight(minCanvasHeight);

        setCanvasScale({ x: scale, y: scale });
      } else if (!fitContainerX && fitContainerY) {
        let minCanvasHeight = Math.min(containerHeight - 100, defaultHeight);
        let minCanvasWidth = (minCanvasHeight / defaultHeight) * defaultWidth;
        const widthScale = minCanvasWidth / defaultWidth;
        const heightScale = minCanvasHeight / defaultHeight;

        setCanvasWidth(minCanvasWidth);
        setCanvasHeight(minCanvasHeight);

        setCanvasScale({ x: widthScale, y: heightScale });
      } else if (fitContainerX && !fitContainerY) {
        let minCanvasWidth = Math.min(containerWidth, defaultWidth);
        let minCanvasHeight = (minCanvasWidth / defaultWidth) * defaultHeight;
        const widthScale = minCanvasWidth / defaultWidth;
        const heightScale = minCanvasHeight / defaultHeight;

        setCanvasWidth(minCanvasWidth);
        setCanvasHeight(minCanvasHeight);

        setCanvasScale({ x: widthScale, y: heightScale });
      } else if (!fitContainerX && !fitContainerY) {
        setCanvasWidth(defaultWidth);
        setCanvasHeight(defaultHeight);
        setCanvasScale({ x: 1, y: 1 });
      }
    }
  }, [
    containerWidth,
    containerHeight,
    // defaultWidth,
    // defaultHeight,
    // fitContainerX,
    // fitContainerY,
    baseQuery.data,
    defaultHeightOverride,
    defaultWidthOverride
  ]);

  return {canvasRef, canvasWidth, canvasHeight, canvasScale};
}