import { Dialog } from '@headlessui/react'
import { User } from 'firebase/auth';
import useCrewApi from '../../common/hooks/useCrewApi';
import CrewEditForm from '../../editor/crew/CrewEditForm';
import Spinner from '../../../baseList/Spinner';

type EditCrewDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  user: User | null;
  baseId: string;
  crewId: string;
}

export default function EditCrewDialog({ isOpen, setIsOpen, user, baseId, crewId }: EditCrewDialogProps) {

  const patchSuccessCB = () => {
    setIsOpen(false);
  }
  const { crewQuery, editCrew } = useCrewApi({ user, baseId, crewId, patchSuccessCB });

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel className="mx-auto w-full lg:w-1/2 p-4 rounded bg-white">
          <Dialog.Title>
            {(crewQuery.isFetching) &&
              <Spinner width="w-8" height="h-8" border="border-2" />}
            Edit Crew
          </Dialog.Title>

          {crewQuery.isSuccess && crewQuery.data && <CrewEditForm crew={crewQuery.data} editItem={editCrew} />}

          <button
            onClick={() => setIsOpen(false)}
            className="w-full p-2 border border-teal-500 rounded-md"
          >
            Cancel
          </button>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
