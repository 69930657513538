import { createContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { saveUser } from "../api/userApi";

import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from "firebase/auth";
import { auth } from "../firebase";

interface IPropsWithChildren {
  children: React.ReactNode;
}

export type AuthContextType = {
  user: User | null;
  googleLogin: () => void;
  emailSignUp: (name: string, email: string, password: string) => Promise<void>;
  emailSignIn: (email: string, password: string) => Promise<void>;
  forgottenPassword: (email: string) => Promise<void>;
  resetPassword: (oobCode: string, password: string) => Promise<void>;
  logOut: () => void;
};

const AuthContext = createContext<AuthContextType | null>(null);

const AuthContextProvider: React.FC<IPropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<any>(null);

  const registerUserMutation = useMutation(saveUser, {});

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const googleLogin = () => {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;
        registerUserMutation.mutate(
          { name: user.displayName, email: user.email, slack_id: null },
          {
            onSuccess: (data, variables, context) => {
              setUser(user);
            },

            onError: () => {
              alert("errror");
            },
          }
        );

        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  const emailSignUp = async (name: string, email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;
        await sendEmailVerification(user);
        registerUserMutation.mutate(
          { name: name, email: user.email, slack_id: null },
          {
            onSuccess: (data, variables, context) => {
              setUser(user);
            },

            onError: () => {
              alert("Couldn't save new user. Please try again later!");
            },
          }
        );
      })
  };

  const emailSignIn = async (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        registerUserMutation.mutate(
          { name: "", email: user.email, slack_id: null },
          {
            onSuccess: (data, variables, context) => {
              setUser(user);
            },

            onError: () => {
              alert("An error occured. Please try again later!");
            },
          }
        );
      })
      .catch((error) => {
        // console.log("login error");
        alert(error.message);
      })
  };

  const forgottenPassword = async (email: string) => {
    // const url = `${baseUrl}/reset_password/?email=${email}`;
    // const actionCodeSettings = {
    //   url: url
    // };
    return sendPasswordResetEmail(auth, email).then(() => {
      console.log("password reset sent")
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;

      console.log(errorCode, errorMessage);
      // ..
    });
  }

  const resetPassword = async (oobCode: string, password: string) => {
    // const action
    return confirmPasswordReset(auth, oobCode, password);
  }

  const logOut = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
      })
      .catch((error) => {});
  };

  return (
    <AuthContext.Provider value={{ user, googleLogin, emailSignUp, emailSignIn, forgottenPassword, resetPassword, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
