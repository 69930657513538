import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { useFormikContext } from "formik";
import { Fragment } from "react";
import { BaseMemberType } from "../../../../common/types";

type MemberComboboxPropsType = {
  baseMembers: BaseMemberType[];
  selectedMember: BaseMemberType | undefined | null;
  setSelectedMember: (bm: BaseMemberType) => void;
  query: string;
  setQuery: (query: string) => void;
}

export default function MemberCombobox({ baseMembers, selectedMember, setSelectedMember, query, setQuery }: MemberComboboxPropsType) {
  const formikProps = useFormikContext();

  let filteredPeople: BaseMemberType[] = [];

  if (baseMembers) {
    filteredPeople =
      query === ''
        ? baseMembers
        : baseMembers.filter((member: BaseMemberType) => {
          return (
            member.email?.toLowerCase().includes(query.toLowerCase()) ||
            member.name.toLowerCase().includes(query.toLowerCase())
          )
        })
  }

  const memberSelected = (member: BaseMemberType) => {
    if (member) {
      setSelectedMember(member);
      formikProps.setFieldValue("baseMemberId", member.id);
      formikProps.setFieldValue("name", member.name);
      formikProps.setFieldValue("email", member.email || "");
    } else {
      // formikProps.setFieldValue("baseMemberId", "");
      formikProps.setFieldValue("name", "");
      formikProps.setFieldValue("email", "");
    }
  }

  return (
    <Combobox value={selectedMember} onChange={memberSelected} nullable>
      <div className="w-full">
        <div className="flex p-0 w-full">
          <Combobox.Input
            name="name"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(member: BaseMemberType) => member?.name}
            className="border border-slate-300 rounded-md p-1 my-2 w-full"
            placeholder="Member name"
          />
          <Combobox.Button>
            <ChevronUpDownIcon className="h-5 w-5" />
          </Combobox.Button>
        </div>
        <div className="relative">
          <Combobox.Options className="absolute bg-white z-10 mt-1 p-2 w-full border border-slate-500 rounded-md">

            {filteredPeople.map((baseMember) => (
              /* Use the `active` state to conditionally style the active option. */
              /* Use the `selected` state to conditionally style the selected option. */
              <Combobox.Option key={baseMember.id} value={baseMember} as={Fragment}>
                {({ active, selected }) => (
                  <li
                    className={`p-1 flex items-center ${active ? 'bg-teal-600 text-white' : 'bg-white text-black'
                      }`}
                  >
                    {selected && <CheckIcon className="h-4 w-4 text-teal-500 font-bold" />}
                    {baseMember.name} {baseMember.email && `(${baseMember.email})`}
                  </li>
                )}
              </Combobox.Option>
            ))}

            {query.length > 0 && (
              <Combobox.Option value={{ id: "", name: query }} as={Fragment}>
                {({ active, selected }) => (
                  <li
                    className={`p-1 flex items-center ${active ? 'bg-teal-600 text-white' : 'bg-white text-black'
                      }`}
                  >
                    Create member "{query}" in base
                  </li>
                )}
              </Combobox.Option>
            )}

          </Combobox.Options>
        </div>
      </div>
    </Combobox>
  )

}
