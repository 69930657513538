import { useMutation, useQuery, useQueryClient } from "react-query";
import { User } from 'firebase/auth';
import { patchBase } from "../../../../api/baseApi";
import { deleteRevision, duplicateRevision, getRevisions, setAsDefaultRevision } from "../../../../api/revisionApi";
import { RevisionFormType } from "../../../../common/types";

type useRevisionApiParams = {
  user: User | null;
  baseId: string;
  addSuccessCB?: () => void;
  patchSuccessCB?: () => void;
  removeSuccessCB?: () => void;
  removeErrorCB?: () => void;
}

export default function useRevisionApi({ user, baseId }: useRevisionApiParams) {
  const queryClient = useQueryClient();
  const revisionsQuery = useQuery(["revisions", baseId], () => getRevisions({user, baseId}), { enabled: !!user && !!baseId });
  const duplicateRevisionMutation = useMutation(duplicateRevision);
  const patchRevisionMutation = useMutation(patchBase);
  const deleteRevisionMutation = useMutation(deleteRevision);
  const setDefaultRevisionMutation = useMutation(setAsDefaultRevision);

  const copyRevision = (revisionId: string) => {
    duplicateRevisionMutation.mutate({user, baseId, revisionId}, {
      onSuccess: () => {
        queryClient.invalidateQueries(["revisions", baseId]);
      }
    })
  }

  const editRevision = (revision: RevisionFormType) => {
    patchRevisionMutation.mutate({user, baseId: revision.id, values: revision}, {
      onSuccess: () => {
        queryClient.invalidateQueries(["revisions", baseId]);
      }
    })
  }

  const setDefaultRevision = (revisionId: string) => {
    setDefaultRevisionMutation.mutate({user, baseId, revisionId}, {
      onSuccess: () => {
        queryClient.invalidateQueries(["revisions", baseId]);
        queryClient.invalidateQueries(["revisions", revisionId]);
        queryClient.invalidateQueries(["bases"]);
      }
    })
  }

  const removeRevision = (revisionId: string) => {
    if (window.confirm("Are you sure you want to delete this Revision?") === true) {
      deleteRevisionMutation.mutate({user, baseId, revisionId},
        {
          onSuccess: (data, variables, context) => {
            // console.log("base deleted");
            queryClient.invalidateQueries(["revisions", baseId]);
          },
          onError: () => {
            console.log("Could not delete Revision. Please try again later!");
          },
        }
      )
    }
  }

  return {
    revisionsQuery,
    duplicateRevisionMutation,
    copyRevision,
    patchRevisionMutation,
    editRevision,
    setDefaultRevisionMutation,
    setDefaultRevision,
    deleteRevisionMutation,
    removeRevision,
  }

}
