import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext, AuthContextType } from "../context/AuthContext";
import { BaseMemberType } from "../common/types";
import { BASE_MEMBER_ROLE_ADMIN } from "../components/baseEditor/editor/baseMembers/BaseMember";
import useAccountApi from "../components/baseEditor/common/hooks/useAccountApi";
import useBaseMemberApi from "../components/baseEditor/common/hooks/useBaseMemberApi";

export default function usePaidAccountRequired(baseId: string|undefined) {

  const { user } = useContext(AuthContext) as AuthContextType;

  const { baseMembersQuery } = useBaseMemberApi({ baseId: baseId ?? "", user }); 
  
  const { basePaidStatusQuery } = useAccountApi({ user, baseId });

  const navigate = useNavigate();

  useEffect(() => {
    let timer = setTimeout(() => {
      if (baseId && baseMembersQuery.isSuccess && basePaidStatusQuery.data) {
        if (!basePaidStatusQuery.data.email) {
          const _adminMembers = baseMembersQuery.data.filter(
            (member: BaseMemberType) => member.role === BASE_MEMBER_ROLE_ADMIN);

          if (_adminMembers.length >= 2) {
            alert("A Premium tier subscription is required to co-edit Bases.")
            navigate("/account")
          }
        }
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [baseMembersQuery.isSuccess, basePaidStatusQuery.data, baseId]);
}
