import { UseQueryResult } from "react-query";
import Spinner from "../../../baseList/Spinner";
import { FormTeamMemberType, TeamMemberType } from "../../../../common/types";
import TeamMember, { timeCommitment_attributes, unfixRole_attributes } from "./TeamMember";
import TeamMemberAddForm from "./TeamMemberAddForm";
import { Fragment, useState } from "react";
import { participationLevel_attributes } from "./TeamMember";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon, PlusIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import TeamMemberEditForm from "./TeamMemberEditForm";

type TeamMemberListProps = {
  teamMembersQuery: UseQueryResult<any, unknown>;
  baseMembersQuery: UseQueryResult<any, unknown>;
  addTeamMember: (member: FormTeamMemberType) => void;
  removeTeamMember: (id: string) => void;
  editTeamMember: (teamMember: TeamMemberType) => void;
  groupBy: string;
  setGroupBy: (groupBy: string) => void;
}

export default function TeamMemberList({
  teamMembersQuery,
  baseMembersQuery,
  addTeamMember,
  removeTeamMember,
  editTeamMember,
  groupBy, setGroupBy
}: TeamMemberListProps) {

  const [editingMember, setEditingMember] = useState<TeamMemberType | null>(null);
  const [showAddForm, setShowAddForm] = useState<boolean>(false);

  const getGroupName = (id: number) => {
    let result = "";

    if (id === 0) {
      return "Uncategorized";
    }

    if (groupBy === "participationLevel") {
      result = participationLevel_attributes[id].label;
    } else if (groupBy === "unfixRoles") {
      result = unfixRole_attributes[id].label;
    } else if (groupBy === "timeCommitment") {
      result = timeCommitment_attributes[id].label;
    }

    return result;
  }

  return (
    <div className="py-2 md:px-2 w-full">
      <div className="border rounded-md p-2">
        <div className="flex justify-between mb-2">
          <span className="flex items-center">
            {(teamMembersQuery.isFetching) &&
              <Spinner width="w-8" height="h-8" border="border-2" />}
            <h2 className="text-xl">Members</h2>
          </span>
          <div className="flex items-center justify-end">
            <div className="relative mr-2">
              <Menu>
                <Menu.Button className="flex items-center">
                  <UserGroupIcon className="w-6 h-6 mr-1" />
                  <span className="hidden md:inline-block">Group by</span>
                  <ChevronDownIcon className="ml-2 w-4 h-4" />
                </Menu.Button>
                <Menu.Items as="div" className="flex flex-col absolute right-0 z-10 bg-white rounded-md border-2 border-teal-500">
                  <Menu.Item>
                    <button
                      onClick={() => setGroupBy("unfixRoles")}
                      className="py-2 px-4 whitespace-nowrap ui-active:bg-teal-500 ui-active:text-white"
                    >
                      Role Attribute
                    </button>
                  </Menu.Item>
                  <Menu.Item>
                    <button
                      onClick={() => setGroupBy("participationLevel")}
                      className="py-2 px-4 whitespace-nowrap ui-active:bg-teal-500 ui-active:text-white"
                    >
                      Participation Level
                    </button>
                  </Menu.Item>
                  <Menu.Item>
                    <button
                      onClick={() => setGroupBy("timeCommitment")}
                      className="py-2 px-4 whitespace-nowrap ui-active:bg-teal-500 ui-active:text-white"
                    >
                      Time Commitment
                    </button>
                  </Menu.Item>
                </Menu.Items>
              </Menu>
            </div>
            <div>
              <button
                className="flex items-center px-4 py-2 rounded-md bg-teal-500 text-white"
                onClick={() =>{setEditingMember(null); setShowAddForm(true)}}
              >
                <PlusIcon className="w-6 h-6 mr-1" />
                <span className="hidden md:inline-block">Add</span>
              </button>
            </div>
          </div>
        </div>

        {!teamMembersQuery.data && (
          <ul className="my-2">
            <li className="text-slate-500">No items...</li>
          </ul>
        )}

        {teamMembersQuery.data && (
          <div className="w-full">
            {Object.keys(teamMembersQuery.data).map((group) => (
              <Fragment key={`team_member_group_${group}`}>
                <div className="w-full p-1 font-medium" key={`group_${group}`}>{getGroupName(Number(group))}</div>
                <div className="flex flex-wrap">
                  {teamMembersQuery.data?.[group]?.map((teamMember: TeamMemberType) => (
                    <TeamMember
                      key={teamMember.id}
                      teamMember={teamMember}
                      deleteItem={removeTeamMember}
                      setEditingMember={setEditingMember}
                      setShowAddForm={setShowAddForm}
                    />
                  ))}
                </div>
              </Fragment>
            ))}
          </div>
        )}
        {editingMember && <TeamMemberEditForm teamMember={editingMember} setEditingMember={setEditingMember} editItem={editTeamMember} />}
        {showAddForm && <TeamMemberAddForm baseMembers={baseMembersQuery.data} addItem={addTeamMember} setShowAddForm={setShowAddForm} />}
      </div>
    </div>
  );

}