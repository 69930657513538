import { User } from "firebase/auth";
import { TeamMemberType } from "../common/types";

type GetCrewMembersParamsType = {
  user: User | null;
  baseId: string;
  crewId: string;
  groupBy: string;
}

export async function getCrewMembers(params: GetCrewMembersParamsType) {
  const {user, baseId, crewId, groupBy} = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/crew_members/?base=${baseId}&crew=${crewId}&group=${groupBy}`,
    {
      headers: {
        "Authorization": "" + token,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't fetch crew member list");
  }

  return response.json();
}

type CreateCrewMemberParamsType = {
  user: User | null;
  baseId: string | undefined;
  crewId: string;
  crewMember: TeamMemberType;
  avatarImage: string | null;
};

export async function createCrewMember(params: CreateCrewMemberParamsType) {
  const { user, baseId, crewId, crewMember, avatarImage } = params;

  const data = {
    baseMemberId: crewMember.baseMemberId ?? "",
    name: crewMember.name,
    email: crewMember.email,
    timeCommitment: crewMember.timeCommitment ?? 0,
    participationLevel: crewMember.participationLevel ?? 0,
    unfixRoles: crewMember.unfixRoles ?? [],
    icon: crewMember.icon ?? "",
    avatarImage: avatarImage,
    base: baseId,
    crew: crewId,
    teamType: 1,
    teamId: crewId,
  };

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/crew_members/?base=${baseId}&crew=${crewId}`,
    {
      method: "POST",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't create crew member");
  }

  return response.json();
}

export async function patchCrewMember(params: CreateCrewMemberParamsType) {
  const { user, baseId, crewId, crewMember, avatarImage } = params;

  const flattenedCrewMember = {
    baseMemberId: crewMember.baseMemberId ?? "",
    name: crewMember.name,
    timeCommitment: crewMember.timeCommitment ?? 0,
    participationLevel: crewMember.participationLevel ?? 0,
    unfixRoles: crewMember.unfixRoles ?? [],
    icon: crewMember.icon ?? "",
    avatarImage: avatarImage,
    base: baseId,
    crew: crewId,
    teamType: 1,
    teamId: crewId,
  };

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/crew_members/${crewMember.id}/?base=${baseId}&crew=${crewId}`,
    {
      method: "PATCH",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...flattenedCrewMember }),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't patch crew member");
  }

  return response.json();
}

type DeleteCrewMemberParamsType = {
  user: User | null;
  baseId: string | undefined;
  crewId: string;
  crewMemberId: string;
};

export async function deleteCrewMember(params: DeleteCrewMemberParamsType) {
  const { user, baseId, crewId, crewMemberId } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/crew_members/${crewMemberId}/?base=${baseId}&crew=${crewId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't delete crew member");
  }
}
