import { useQuery } from "react-query";
import { useContext } from "react";
import { AuthContext, AuthContextType } from "../context/AuthContext";
import useIsAuthenticated from "../hooks/useIsAuthenticated";

import { getCurrentUserAccount } from "../api/accountApi";
import Credits from "../components/account/Credits";
import Subcriptions from "../components/account/Subscriptions";
import useAccountApi from "../components/baseEditor/common/hooks/useAccountApi";

export default function Account() {
  const { user } = useContext(AuthContext) as AuthContextType;

  useIsAuthenticated();

  return (
    <div className="h-screen w-full p-2">
      <div className="flex flex-wrap items-center px-2 pb-2">
        <span className="text-teal-500">Account</span>
      </div>
      {user && <div>
        <div className="py-2 md:px-2 w-full">
          <Subcriptions />
          <Credits />
        </div>
      </div>}
    </div>
  )
}
