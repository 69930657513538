import { Listbox } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { useFormikContext } from "formik";
import { Fragment, useEffect, useState } from "react";

type IconSelectorPropsType = {
  memberIcon: string;
}

const ICONS = [
  "😀", "😁", "😂", "🤣", "😄", "😅", "😆", "😉", "😊", "😋", "😎", "😍", "😘", "🥰", "😗",
  "🤩", "🤔", "🤨", "😐", "😑", "😶", "🙄", "😏", "😣", "😥", "😮", "🤐", "😯", "😪", "😫",
  "😝", "🤤", "😒", "😓", "😔", "😕", "🙃", "🤑", "😲", "🙁", "😖", "😞", "😟", "😤",
  "😩", "😬", "😮‍💨", "😰", "😱", "🥵", "🥶", "😳", "🤪", "😵", "😵‍💫", "🥴", "😠", "🤬", "😷",
  "😇", "🥳", "🥸", "🥺", "🤡", "🤥", "🤫", "🤭", "🧐", "🤓", "😙", "🥲", "🙂", "🤗",
  "🤒", "🤕", "🤢", "🤮", "🤧", "😢", "😭", "😦", "😧", "😨", "🥱", "😴", "😌", "😛", "😜",
];

export default function IconSelector({ memberIcon }: IconSelectorPropsType) {

  const formikProps = useFormikContext();
  const [icon, setIcon] = useState<string>(memberIcon);

  useEffect(() => {
    iconSelected(memberIcon);
  }, [memberIcon])

  const iconSelected = (selectedIcon: string) => {
    setIcon(selectedIcon);
    formikProps.setFieldValue("icon", selectedIcon);
  }

  return (
    <div className="relative w-full">
      <Listbox value={icon} onChange={iconSelected}>
        <Listbox.Button as="div" className="flex items-center justify-between w-full my-2">
          <span className="w-full min-h-[1.75rem] p-1 border border-grey-300 rounded-md">
            {icon}
          </span>
          <ChevronUpDownIcon className="w-4 h-4 ml-1" />
        </Listbox.Button>
        <Listbox.Options as="div" className="absolute flex flex-wrap right-0 top-10 max-h-60 w-full bg-white z-10 overflow-auto rounded-md border-2 border-teal-500">

          <Listbox.Option key="empty_icon" value="" as={Fragment}>
            {({active, selected}) => (
              <span className={`w-8 h-8 p-1 flex items-center justify-center
              ${active ? 'bg-teal-500 text-white': 'bg-white text-black'}
              ${selected ? 'border border-teal-500 rounded-md': ''}`}>
                {" "}
              </span>
            )}

            {/* {role.name} */}
          </Listbox.Option>

          {ICONS.map((iconElement) => (
            <Listbox.Option key={iconElement} value={iconElement} as={Fragment}>
              {({active, selected}) => (
                <span className={`w-8 h-8 p-1 flex items-center justify-center
                ${active ? 'bg-teal-500 text-white': 'bg-white text-black'}
                ${selected ? 'border border-teal-500 rounded-md': ''}`}>
                  {iconElement}
                </span>
              )}

              {/* {role.name} */}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  )

}
