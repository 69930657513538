import { Group, Image, Rect, Text } from "react-konva";
import { DBValueStreamType, FetchedBaseType, MeasurementType } from "../../../../common/types";
import useImage from "use-image";
import { useContext, useEffect, useRef } from "react";
import Konva from "konva";
import { valueStream_type_attributes } from "../../../../common/constants/valueStreamTypes";
import CrewLifecycleStage from "../crew/CrewLifecycleStage";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import useValueStreamApi from "../../common/hooks/useValueStreamApi";
import { TooltipContext, TooltipContextType } from "../context/TooltipContext";

type ValueStreamElementProps = {
  valueStream: DBValueStreamType;
  base: FetchedBaseType;
};

export default function ValueStreamElement({
  valueStream,
  base,
}: ValueStreamElementProps) {

  const { user } = useContext(AuthContext) as AuthContextType;
  const { editValueStream } = useValueStreamApi({ baseId: base.id, user, valueStreamId: valueStream.id });

  const { setTooltip } = useContext(TooltipContext) as TooltipContextType;

  const onElementClicked = () => {
    setTooltip(<p>
      This element is a(n) <a target="_blank" href={`${valueStream_type_attributes[valueStream.type]["link"]}`} className="text-teal-500">
        {`${valueStream_type_attributes[valueStream.type]["label"]}`} Value Stream
      </a></p>
    )
  }

  // icon
  const iconPath = `/unfix/icons/value_stream/${valueStream_type_attributes[valueStream.type]["icon"]}`;
  const [icon] = useImage(iconPath);

  // transformer
  const shapeRef = useRef<any>();

  return (
    <Group
      name="chart_element value_stream_element"
      id={`value_stream_${valueStream.id}`}
      x={valueStream.measurements.x}
      y={valueStream.measurements.y}
      width={valueStream.measurements.width}
      height={valueStream.measurements.height}
      draggable
      onClick={onElementClicked}
      onTap={onElementClicked}
      ref={shapeRef}
      onDragEnd={(e) => {
        const canvas_width = base.display_options?.canvas_size.width ?? 1280;
        const canvas_height = base.display_options?.canvas_size.height ?? 720;
        let newX = (e.target.x() < 0) ? 50 : (e.target.x() > canvas_width - 50) ? canvas_width - 50 : e.target.x();
        let newY = (e.target.y() < 0) ? 50 : (e.target.y() > canvas_height - 50) ? canvas_height - 50 : e.target.y();

        const measurements: MeasurementType = {
          x: newX,
          y: newY,
          width: valueStream.measurements.width,
          height: valueStream.measurements.height,
        };
        editValueStream({
          ...valueStream,
          measurements,
        });
      }}
      onTransformEnd={(e) => {
        // transformer is changing scale of the node
        // and NOT its width or height
        // but in the store we have only width and height
        // to match the data better we will reset scale on transform end
        const node: Konva.Group = shapeRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        // we will reset it back
        node.scaleX(1);
        node.scaleY(1);

        const measurements: MeasurementType = {
          x: node.x(),
          y: node.y(),
          width: valueStream.measurements.width * scaleX,
          height: valueStream.measurements.height * scaleY,
        };
        editValueStream({
          ...valueStream,
          measurements,
        });
      }}
    >
      <Rect
        x={0}
        y={0}
        width={valueStream.measurements.width}
        height={valueStream.measurements.height}
        fill={valueStream_type_attributes[valueStream.type]["color"]}
      />
      <Text
        x={140}
        y={valueStream.measurements.height / 2 - 10}
        width={valueStream.measurements.width - 140}
        height={20}
        fill={"white"}
        align="left"
        text={valueStream.name}
        fontSize={20}
      />
      <Image
        image={icon}
        alt=""
        x={20}
        y={valueStream.measurements.height / 2 - 20}
        width={40}
        height={40}
      />
      {!!valueStream.lifecycleStage && <CrewLifecycleStage
        x={100}
        y={valueStream.measurements.height / 2}
        lifecycleStage={valueStream.lifecycleStage}
      />}
    </Group>
  );
}
