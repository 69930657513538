import { Formik, Field, Form, ErrorMessage } from "formik";

import { TeamMemberType } from "../../../../common/types";
import { CREW_MEMBER_PARTICIPATION_LEVELS, CREW_MEMBER_TIME_COMMITMENTS } from "./TeamMember";
import { XMarkIcon } from "@heroicons/react/24/outline";
import RoleSelector from "./RoleSelector";
import { useEffect, useState } from "react";
import IconSelector from "./IconSelector";
import ImageCropper from "../common/ImageCropper";

type TeamMemberFormType = {
  teamMember: TeamMemberType;
  setEditingMember: (tm: null) => void;
  editItem: (teamMember: TeamMemberType, avatarImage?: string) => void;
}

type FormErrorsType = {
  name?: string;
  email?: string;
  participationLevel?: string;
  timeCommitment?: string;
}

export default function TeamMemberEditForm({ teamMember, setEditingMember, editItem }: TeamMemberFormType) {

  const [roles, setRoles] = useState<number[]>([]);
  const [croppedImage, setCroppedImage] = useState<string>("");

  useEffect(() => {
    if (teamMember.allRoles) {
      setRoles(teamMember.allRoles);
    } else {
      setRoles(teamMember.unfixRoles ?? []);
    }
  }, [teamMember]);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={teamMember}
        validate={values => {
          const errors: FormErrorsType = {};
          if (!values.name) {
            errors.name = 'Name is required';
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          // console.log("submit", values);
          values.participationLevel = Number(values.participationLevel);
          values.timeCommitment = Number(values.timeCommitment);
          editItem(values, croppedImage);
          actions.setSubmitting(false);
          setEditingMember(null);
          // actions.resetForm();
        }}
      >
        <Form className="flex flex-col border-t mt-2 pt-2">
          <div className="w-full flex items-center justify-between">
            <h2 className="text-xl py-2">Edit member</h2>
            <XMarkIcon onClick={() => setEditingMember(null)} className="w-6 h-6" />
          </div>
          <Field type="hidden" name="id" />
          <Field type="hidden" name="baseMemberId" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="name">Name</label>
            <Field name="name" placeholder="Member name" className="w-full border rounded-md p-1 my-2" />
          </div>
          <ErrorMessage className="text-red-700" name="name" component="div" />
          {/* <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="email">Email</label>
            <Field name="email" value={teamMember.email ?? ""} placeholder="Member email" className="w-full border rounded-md p-1 my-2" />
          </div>
          <ErrorMessage className="text-red-700" name="email" component="div" /> */}
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="unfixRoles">Role Attribute</label>
            <RoleSelector memberRoles={roles} />
          </div>
          <ErrorMessage className="text-red-700" name="unfixRoles" component="div" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="participationLevel">Participation Level</label>
            <Field name="participationLevel" as="select" className="w-full border rounded-md p-1 my-2">
              <option value={0}>None</option>
              {CREW_MEMBER_PARTICIPATION_LEVELS.map((participationLevel) => <option key={participationLevel.id} value={participationLevel.id}>{participationLevel.name}</option>)}
            </Field>
          </div>
          <ErrorMessage className="text-red-700" name="participationLevel" component="div" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="timeCommitment">Time Commitment</label>
            <Field name="timeCommitment" as="select" className="w-full border rounded-md p-1 my-2">
              <option value={0}>None</option>
              {CREW_MEMBER_TIME_COMMITMENTS.map((timeCommitment) => <option key={timeCommitment.id} value={timeCommitment.id}>{timeCommitment.name}</option>)}
            </Field>
          </div>
          <ErrorMessage className="text-red-700" name="timeCommitment" component="div" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="icon">Icon</label>
            <IconSelector memberIcon={teamMember.icon ?? ""} />
          </div>
          <ErrorMessage className="text-red-700" name="icon" component="div" />
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <label className="w-full md:w-3/12 lg:w-2/12" htmlFor="avatar">Avatar</label>
            <ImageCropper croppedImage={croppedImage} setCroppedImage={setCroppedImage} existingAvatar={teamMember.avatar ?? ""} />
          </div>
          <ErrorMessage className="text-red-700" name="avatar" component="div" />
          <div className="flex items-center justify-end">
            <button
              type="button"
              className="w-1/3 md:w-1/4 p-2 px-4 my-2 border border-teal-500 tex-teal-500 rounded-md"
              onClick={(e) => { e.preventDefault(); setEditingMember(null) }}
            >
              Cancel
            </button>
            <button type="submit" className="w-1/3 md:w-1/4 p-2 px-4 my-2 bg-teal-500 text-white rounded-md ml-2">Save</button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}
