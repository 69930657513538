import { Dialog } from '@headlessui/react'
import { User } from 'firebase/auth';
import useForumApi from '../../common/hooks/useForumApi';
import ForumEditForm from '../../editor/forum/ForumEditForm';
import Spinner from '../../../baseList/Spinner';

type EditForumDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  user: User | null;
  baseId: string;
  forumId: string;
}

export default function EditForumDialog({ isOpen, setIsOpen, user, baseId, forumId }: EditForumDialogProps) {

  const patchSuccessCB = () => {
    setIsOpen(false);
  }
  const { forumQuery, editForum } = useForumApi({ user, baseId, forumId, patchSuccessCB });

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel className="mx-auto w-full lg:w-1/2 p-4 rounded bg-white">
          <Dialog.Title>
            {(forumQuery.isFetching) &&
              <Spinner width="w-8" height="h-8" border="border-2" />}
            Edit Forum
          </Dialog.Title>

          {forumQuery.isSuccess && forumQuery.data && <ForumEditForm forum={forumQuery.data} editItem={editForum} />}

          <button
            onClick={() => setIsOpen(false)}
            className="w-full p-2 border border-teal-500 rounded-md"
          >
            Cancel
          </button>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
