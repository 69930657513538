import { useContext } from "react";

import ForumForm from "./ForumForm";
import Forum from "./Forum";
import { DBForumType } from "../../../../common/types";
// import { ReactSortable } from "react-sortablejs";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import Spinner from "../../../baseList/Spinner";
import useForumApi from "../../common/hooks/useForumApi";

type ForumListPropsType = {
  baseId: string;
}

export default function ForumList({baseId}: ForumListPropsType) {
  const { user } = useContext(AuthContext) as AuthContextType;

  const {
    forumsQuery,
    createForumMutation,
    addForum,
    deleteForumMutation,
    removeForum
  } = useForumApi({baseId, user});

  return (
    <div className="py-2 md:px-2 w-full md:w-1/2 lg:w-1/3">
      <div className="border rounded-md p-2">
        <div className="flex mb-2">
          {(forumsQuery.isFetching || deleteForumMutation.isLoading || createForumMutation.isLoading) &&
            <Spinner width="w-8" height="h-8" border="border-2" />}
          <h2 className="text-xl">Forums</h2>
        </div>

        {forumsQuery.data?.length === 0 && (
          <ul className="my-2">
            <li className="text-slate-500">No items...</li>
          </ul>
        )}

        {forumsQuery.data?.length > 0 && (
          <div>
            {forumsQuery.data?.map((forum: DBForumType) => (
              <Forum key={forum.id} forum={forum} deleteItem={removeForum} />
            ))}
          </div>
        )}
        <ForumForm addItem={addForum} />
      </div>
    </div>
  );
}
