import { TrashIcon } from "@heroicons/react/24/outline";
import { useContext } from "react";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import useAccountApi from "../baseEditor/common/hooks/useAccountApi";

export default function PaidBaseList() {

  const { user } = useContext(AuthContext) as AuthContextType;
  const { accountQuery, downgradeBaseClick } = useAccountApi({ user });

  return (
    <>
      <div className="w-full font-medium">
        Premium Bases
      </div>
      {accountQuery?.data && accountQuery.data?.bases?.length <= 0 && <div className="my-1">
        <span className="text-slate-500">No items...</span>
      </div>}
      <div className="my-2">
        {accountQuery?.data && accountQuery.data?.bases?.length > 0 && accountQuery.data.bases.map(
          (base: any) => <div key={base.id} className="w-full p-1 border-b flex flex-wrap items-center justify-between">
            <span>{base.name}</span>
            <TrashIcon onClick={() => downgradeBaseClick(base.id)} className="w-4 h-4" />
          </div>)}
      </div>

    </>
  );
}