import { User } from "firebase/auth";
import { DBForumType, DBForumPatchType } from "../common/types";

type GetForumsParamsType = {
  user: User | null;
  baseId: string;
}

export async function getForums(params: GetForumsParamsType) {
  const {user, baseId} = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/forums/?base=${baseId}`,
    {
      headers: {
        "Authorization": "" + token,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't fetch forum list");
  }

  return response.json();
}

type GetForumParamsType = {
  user: User | null;
  baseId: string;
  forumId: string;
};

export async function getForum(params: GetForumParamsType) {
  const { user, baseId, forumId } = params;
  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/forums/${forumId}/?base=${baseId}`,
    {
      headers: {
        "Authorization": "" + token
      }
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't fetch forum");
  }

  return response.json();
}

type CreateForumParamsType = {
  user: User | null;
  baseId: string | undefined;
  forum: DBForumType;
};

export async function createForum(params: CreateForumParamsType) {
  const { user, baseId, forum } = params;

  const flattenedForum = {
    name: forum.name,
    type: forum.type,
    base: baseId,
    measurements: forum.measurements,
  };

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/forums/?base=${baseId}`,
    {
      method: "POST",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...flattenedForum }),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't create forum");
  }

  return response.json();
}

type PatchForumParamsType = {
  user: User | null;
  baseId: string | undefined;
  forum: DBForumPatchType;
};


export async function patchForum(params: PatchForumParamsType) {
  const { user, baseId, forum } = params;

  const flattenedForum = {
    name: forum.name,
    type: forum.type,
    base: baseId,
    measurements: forum.measurements,
  };

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/forums/${forum.id}/?base=${baseId}`,
    {
      method: "PATCH",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...flattenedForum }),
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't patch forum");
  }

  return response.json();
}

type DeleteForumParamsType = {
  user: User | null;
  baseId: string | undefined;
  forumId: string;
};

export async function deleteForum(params: DeleteForumParamsType) {
  const { user, baseId, forumId } = params;

  const token = await user?.getIdToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1/forums/${forumId}/?base=${baseId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: "" + token,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Couldn't delete forum");
  }
}
