import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions"
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const functions = getFunctions(app, "europe-west3");
export const auth = getAuth(app);

if (process.env.REACT_APP_ENV_NAME === "local") {
  console.log("Using Firebase Emulator")
  connectAuthEmulator(auth, "http://localhost:9099");
}

export const defaultStorage = getStorage(app);
export const thumbnailStorage = getStorage(app, `${process.env.REACT_APP_THUMBNAIL_STORAGE_BUCKET}`);
if (process.env.REACT_APP_ENV_NAME === "local") {
  connectStorageEmulator(thumbnailStorage, "localhost", 9199);
}
export const avatarStorage = getStorage(app, `${process.env.REACT_APP_AVATARS_STORAGE_BUCKET}`);
if (process.env.REACT_APP_ENV_NAME === "local") {
  connectStorageEmulator(avatarStorage, "localhost", 9199);
}
