import { RefObject, useEffect, useRef, useState } from "react";
import { ScaleType } from "./useResponsiveCanvas";
import { Node } from "konva/lib/Node";
import { Util } from "konva/lib/Util";
import { SelectedElementType } from "../components/baseEditor/canvas/UnfixChart";

export default function useMultiSelect(
  crewLayerRef: RefObject<any>,
  trRef: RefObject<any>,
  selectionRectRef: RefObject<any>,
  canvasScale: ScaleType
) {

  const selection = useRef<any>({
    visible: false,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0
  });

  // tranformer element select
  const [selectedIds, selectShapes] = useState<string[]>([]);

  // single selected element for context menu BEGIN
  const [selectedElement, setSelectedElement] = useState<SelectedElementType>({ type: null });

  useEffect(() => {
    if (selectedIds.length === 1) {
      const element = selectedIds[0].split("_");
      setSelectedElement({ type: element.slice(0, -1).join("_") as SelectedElementType["type"], id: element[element.length - 1] });
    } else {
      setSelectedElement({ type: null });
    }

  }, [selectedIds]);
  useEffect(() => {
    const nodes = selectedIds.map((id) => crewLayerRef.current.findOne("#" + id));
    trRef.current.nodes(nodes);
  }, [selectedIds]);

  // single selected element for context menu END

  const updateSelectionRect = () => {
    const node = selectionRectRef.current;
    node.setAttrs({
      visible: selection.current.visible,
      x: Math.min(selection.current.x1, selection.current.x2),
      y: Math.min(selection.current.y1, selection.current.y2),
      width: Math.abs(selection.current.x1 - selection.current.x2),
      height: Math.abs(selection.current.y1 - selection.current.y2),
      // fill: "rgba(0, 161, 255, 0.3)"
      fill: "rgba(153, 246, 228, 0.3)"
    });
    node.getLayer().batchDraw();
  };


  const onMouseDown = (e: any) => {
    // const isElement = e.target.findAncestor(".elements-container");
    const isTransformer = e.target.findAncestor("Transformer") || e.target.findAncestor(".chart_element");
    if (isTransformer) {
      return;
    }

    const pos = e.target.getStage().getPointerPosition();
    selection.current.visible = true;
    selection.current.x1 = pos.x / canvasScale.x;
    selection.current.y1 = pos.y / canvasScale.y;
    selection.current.x2 = pos.x / canvasScale.x;
    selection.current.y2 = pos.y / canvasScale.y;
    updateSelectionRect();
  };

  const onMouseMove = (e: any) => {
    if (!selection.current.visible) {
      return;
    }
    const pos = e.target.getStage().getPointerPosition();
    selection.current.x2 = pos.x / canvasScale.x;
    selection.current.y2 = pos.y / canvasScale.y;
    updateSelectionRect();
  };

  const onMouseUp = () => {
    const wasVisible = selection.current.visible;
    selection.current.visible = false;
    const { x1, x2, y1, y2 } = selection.current;
    const moved = x1 !== x2 || y1 !== y2;
    if (!moved) {
      updateSelectionRect();
      return;
    }
    const selBox = selectionRectRef.current.getClientRect();

    if (wasVisible) {
      const elements: Node[] = [];
      crewLayerRef.current.find(".chart_element").forEach((elementNode: Node) => {
        const elBox = elementNode.getClientRect();
        if (Util.haveIntersection(selBox, elBox)) {
          elements.push(elementNode);
        }
      });
      selectShapes(elements.map((el) => el.id()));
      trRef.current.getLayer().batchDraw();
      updateSelectionRect();
    }
  };

  const onClickTap = (e: any) => {
    // if we are selecting with rect, do nothing
    const { x1, x2, y1, y2, visible } = selection.current;
    const moved = x1 !== x2 || y1 !== y2;
    if (moved && selectedIds.length === 0) {
      return;
    }

    let stage = e.target.getStage();
    // let layer = crewLayerRef.current;
    let tr = trRef.current;

    // if click on empty area - remove all selections
    if (e.target === stage || e.target.getLayer().getId() === "base-layer") {
      selectShapes([]);
      return;
    }

    const targetGroup = e.target.findAncestor(".chart_element");

    if (!targetGroup) {
      selectShapes([]);
      return;
    }

    // do we pressed shift or ctrl?
    const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
    const isSelected = tr.nodes().indexOf(targetGroup) >= 0;

    if (!metaPressed && !isSelected) {
      // if no key pressed and the node is not selected
      // select just one
      selectShapes([targetGroup.id()]);
    } else if (metaPressed && isSelected) {
      // if we pressed keys and node was selected
      // we need to remove it from selection:
      selectShapes((oldShapes) => {
        return oldShapes.filter((oldId) => oldId !== targetGroup.id());
      });
    } else if (metaPressed && !isSelected) {
      // add the node into selection
      selectShapes((oldShapes) => {
        return [...oldShapes, targetGroup.id()];
      });
    }
    // layer.draw();
  };

  return {onMouseDown, onMouseMove, onMouseUp, onClickTap, selectedIds, selectedElement, setSelectedElement};

}