import { useContext } from "react";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import Spinner from "../../../baseList/Spinner";
import useTurfApi from "../../common/hooks/useTurfApi";
import TurfEditForm from "./TurfEditForm";

type TurfDetailsPropsType = {
  baseId: string;
  turfId: string;
}

export default function TurfDetails({ baseId, turfId }: TurfDetailsPropsType) {
  const { user } = useContext(AuthContext) as AuthContextType;

  const {turfQuery, editTurf} = useTurfApi({baseId, user, turfId})


  return (

    <div id="turf_details" className="w-full">
      <div className="py-2 md:px-2 w-full">
        <div className="border rounded-md p-2">
          <div className="flex mb-2">
            {(turfQuery.isFetching) &&
              <Spinner width="w-8" height="h-8" border="border-2" />}
            <h2 className="text-xl">Turf Details</h2>
          </div>
          
          {turfQuery.isSuccess && turfQuery.data && <TurfEditForm turf={turfQuery.data} editItem={editTurf} />}

        </div>
      </div>
    </div>
  );
}
