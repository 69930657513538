import { useState } from "react"
import MiroExportDialog from "./MiroExportDialog";
import { ArrowUpOnSquareIcon } from "@heroicons/react/24/outline";

export default function ToMiroButton() {
  const [isMiroExportDialogOpen, setIsMiroExportDialogOpen] = useState(false);


  return (
    <>
      <button
        onClick={() => { setIsMiroExportDialogOpen(true) }}
        className="flex items-center py-2 px-4 bg-teal-500 text-white rounded-md ml-1"
      >
        <ArrowUpOnSquareIcon className="h-6 w-6 mr-1" />
        <span className="hidden md:inline-block">To Miro</span>
      </button>

      <MiroExportDialog
        isOpen={isMiroExportDialogOpen}
        setIsOpen={setIsMiroExportDialogOpen}
      />

    </>
  );
}
