import { AdjustmentsHorizontalIcon, CheckIcon } from "@heroicons/react/24/outline";
import { FetchedBaseType } from "../../../../common/types";

import { Menu } from "@headlessui/react";
import { lifecycle_stage_attributes } from "../../../../common/constants/lifecycleStages";
import { Link } from "react-router-dom";

type RevisionProps = {
  revision: FetchedBaseType;
  duplicateItem: (revisionId: string) => void;
  deleteItem: (revisionId: string) => void;
  setDefaultRevision: (revisionId: string) => void;
  setEditingRevision: (revision: FetchedBaseType) => void;
};

export default function Revision({ revision, duplicateItem, deleteItem, setEditingRevision, setDefaultRevision }: RevisionProps) {
  const borderColor =
    revision.lifecycle_stage in lifecycle_stage_attributes
      ? lifecycle_stage_attributes[revision.lifecycle_stage]["color"]
      : "#AAAAAA";

  const bgColor =
    revision.lifecycle_stage in lifecycle_stage_attributes
      ? lifecycle_stage_attributes[revision.lifecycle_stage]["color"] + "22"
      : "#f1f5f9";

  return (
    <div className="p-1 w-full md:w-1/2 lg:w-1/3 cursor-grab">
      <div className={`p-1 flex items-center justify-between border-2 rounded-md`} style={{ borderColor: borderColor, backgroundColor: bgColor }}>
        <div className="flex items-center">
          {revision.is_default_revision && <CheckIcon className="w-6 h-6" />}
          <span className="mx-2">
            {revision.revision_name}
            {!revision.is_member && <span className="italic ml-1">(Not a member)</span>}
          </span>
        </div>

        <div className="relative">
          {revision.is_member && <Menu>
            <Menu.Button className="flex items-center">
              <AdjustmentsHorizontalIcon className="ml-1 w-6 h-6" />
            </Menu.Button>
            <Menu.Items as="div" className="flex flex-col absolute right-0 top-5 z-10 bg-white rounded-md border-2 text-black" style={{ borderColor: borderColor }}>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={`/base/${revision.id}`}
                    // target="_blank"
                    className="flex items-center justify-center py-2 px-4 whitespace-nowrap ui-active:bg-teal-500"
                    style={active ? { backgroundColor: bgColor } : {}}
                  >
                    View
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => { setDefaultRevision(revision.id); }}
                    className="py-2 px-4 whitespace-nowrap ui-active:bg-teal-500"
                    style={active ? { backgroundColor: bgColor } : {}}
                  >
                    Set as default
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => { duplicateItem(revision.id); }}
                    className="py-2 px-4 whitespace-nowrap ui-active:bg-teal-500"
                    style={active ? { backgroundColor: bgColor } : {}}
                  >
                    Duplicate
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => { setEditingRevision(revision); }}
                    className="py-2 px-4 whitespace-nowrap ui-active:bg-teal-500"
                    style={active ? { backgroundColor: bgColor } : {}}
                  >
                    Edit
                  </button>
                )}
              </Menu.Item>
              {!revision.is_default_revision && <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => { deleteItem(revision.id); }}
                    className="py-2 px-4 whitespace-nowrap ui-active:bg-teal-500"
                    style={active ? { backgroundColor: bgColor } : {}}
                  >
                    Delete
                  </button>
                )}
              </Menu.Item>}
            </Menu.Items>
          </Menu>}
        </div>
      </div>
    </div>
  );
}
