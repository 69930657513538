import { Tab } from "@headlessui/react"
import { ArrowsPointingOutIcon, ListBulletIcon, PencilIcon, RectangleGroupIcon, UserGroupIcon } from "@heroicons/react/24/outline"
import { ReactNode } from "react"
import { Link } from "react-router-dom"

type TabLayoutProps = {
  baseId: string;
  firstLabel: string;
  children: ReactNode;
}

export default function TabLayout({ baseId, firstLabel, children }: TabLayoutProps) {
  return (
    <Tab.Group>
      <Tab.List className="flex text-xl ml-2">
        <Tab className="flex items-center ui-selected:bg-teal-100 w-fit rounded-t-lg py-2.5 px-4 border">
          <Link className="flex items-center" to={`/base/${baseId}#editor`}>
            <PencilIcon className="w-6 h-6 md:mr-1" />
            <span className="hidden md:inline-block">{firstLabel}</span>
          </Link>
        </Tab>
        <Tab className="flex items-center ui-not-selected:bg-white w-fit rounded-t-lg py-2.5 px-4 border">
          <Link className="flex items-center" to={`/base/${baseId}#editor`}>
            <ListBulletIcon className="w-6 h-6 md:mr-1" />
            <span className="hidden md:inline-block">Editor</span>
          </Link>
        </Tab>
        <Tab className="flex items-center ui-not-selected:bg-white w-fit rounded-t-lg py-2.5 px-4 border">
          <Link className="flex items-center" to={`/base/${baseId}#members`}>
            <UserGroupIcon className="w-6 h-6 md:mr-1" />
            <span className="hidden md:inline-block">Members</span>
          </Link>
        </Tab>
        <Tab className="flex items-center ui-not-selected:bg-white w-fit rounded-t-lg py-2.5 px-4 border">
          <Link className="flex items-center" to={`/base/${baseId}#chart`}>
            <RectangleGroupIcon className="w-6 h-6 md:mr-1" />
            <span className="hidden md:inline-block">Chart</span>
          </Link>
        </Tab>
        <Tab className="flex items-center ui-not-selected:bg-white w-fit rounded-t-lg py-2.5 px-4 border">
          <Link className="flex items-center" to={`/base/${baseId}#revisions`}>
            <ArrowsPointingOutIcon className="w-6 h-6 md:mr-1" />
            <span className="hidden md:inline-block">Revisions</span>
          </Link>
        </Tab>
      </Tab.List>
      {children}
    </Tab.Group>
  )
}
