export function getInitials(fullName: string) {
  let initials;
  const allNames = fullName.trim().split(' ');
  if (allNames.length === 1 && allNames[0].length > 1) {
    initials = `${allNames[0].charAt(0).toUpperCase()}${allNames[0].charAt(1)}`
  } else {
    initials = allNames.reduce((acc, curr, index) => {
      if (index === 0 || index === allNames.length - 1) {
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
  }
  return initials;
}
