import { XMarkIcon } from "@heroicons/react/24/outline";
import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { newsletterDontAskMe, newsletterRemindMeLater, newsletterSubscribe } from "../../api/userApi";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

export default function NewsletterDialog() {
  const {user} = useContext(AuthContext) as AuthContextType;
  const queryClient = useQueryClient();
  const remindMeLaterMutation = useMutation(newsletterRemindMeLater);
  const dontAskMeMutation = useMutation(newsletterDontAskMe);
  const subscribeMutation = useMutation(newsletterSubscribe);

  const [showDialog, setShowDialog] = useState(true);

  const remindMeLater = () => {
    setShowDialog(false);
    remindMeLaterMutation.mutate({user}, {
      onSuccess: () => {
        queryClient.invalidateQueries(["currentUser"]);
      }
    });
  }

  const dontAskMe = () => {
    setShowDialog(false);
    dontAskMeMutation.mutate({user}, {
      onSuccess: () => {
        queryClient.invalidateQueries(["currentUser"]);
      }
    });
  }

  const subscribe = (email: string) => {
    setShowDialog(false);
    subscribeMutation.mutate({user, email}, {
      onSuccess: () => {
        queryClient.invalidateQueries(["currentUser"]);
      }
    });
  }

  const SubscribeSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),  
  });

  return (
    <>
      {showDialog && <div className="fixed h-2/3 max-h-96 z-50 top-30 w-full md:left-1/4 md:w-1/2 p-4 bg-white">
        <div className="h-full w-full p-4 border-2 border-teal-500 rounded-md bg-red overflow-scroll">
          <div className="flex justify-between items-center">
            <h2 className="text-xl">Follow us!</h2>
            <XMarkIcon onClick={() => remindMeLater()} className="cursor-pointer h-7 w-7" />
          </div>
          <div className="">
            <p className="my-2">You are just ONE step away from awesomeness!</p>

            <p className="my-2">
              Each Wednesday, we will send you free articles, chapters, and downloads.
              And we will keep you informed about our latest work in the areas of agility,
              innovation, experience, and the unFIX model!
            </p>

            <div>
              <Formik
                initialValues={{email: ""}}
                validationSchema={SubscribeSchema}
                onSubmit={(values, actions) => {
                  subscribe(values.email);
                  actions.setSubmitting(false);
                }}
              >
                <Form className="py-2 mt-2 flex flex-col border-t">
                  <label className="py-2" htmlFor="email">Email</label>
                  <Field name="email" placeholder="Email address" className="border rounded-md p-1 my-2" />
                  <ErrorMessage className="text-red-700" name="email" component="div" />
                  <button type="submit" className="p-2 px-4 my-2 bg-teal-500 rounded-md text-white">Subscribe</button>
                  <p className="text-xs">
                    By signing up, you agree that we can reach out to you with our weekly newsletter.
                    You can unsubscribe anytime. For more details, review our Privacy Policy.
                  </p>
                  <div className="py-2 flex justify-center">
                    <a onClick={() => remindMeLater()} className="cursor-pointer text-teal-500">Remind me later</a>
                  </div>
                  <div className="py-2 flex justify-center">
                    <a onClick={() => dontAskMe()} className="cursor-pointer text-red-500">Don't ask me again</a>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>}
    </>
  )
}