import { Image } from "react-konva";
import { teaming_option_attributes } from "../../../../common/constants/teamingOptions";
import useImage from "use-image";
import { useContext } from "react";
import { TooltipContext, TooltipContextType } from "../context/TooltipContext";

type TeamingOptionBadgeProps = {
  x: number;
  y: number;
  teamingOption: number;
}

export default function TeamingOptionBadge({x, y, teamingOption}: TeamingOptionBadgeProps) {
  // icon
  const teamingOptionIconFile = teaming_option_attributes[teamingOption]["icon"];
  const [teamingOptionIcon] = useImage(`/unfix/icons/teaming_option/${teamingOptionIconFile}`);

  const {setTooltip} = useContext(TooltipContext) as TooltipContextType;

  const onElementClicked = (e: any) => {
    e.cancelBubble = true;

    setTooltip(<p>
      This icon is the <a target="_blank" href={`${teaming_option_attributes[ teamingOption ]["link"]}`} className="text-teal-500">
        {`${teaming_option_attributes[ teamingOption ]["label"]}`} Teaming Option
      </a></p>
    )
  }

  return (
    <Image
      onClick={onElementClicked}
      onTap={onElementClicked}
      image={teamingOptionIcon}
      alt=""
      x={x}
      y={y}
      width={40}
      height={40}
    />

  )
}