import { Circle, Shape, Text } from "react-konva";
import useCrewApi from "../common/hooks/useCrewApi";
import { Fragment, useContext, useEffect, useState } from "react";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { CrewDependencyType, DBCrewType } from "../../../common/types";
import { crew_type_attributes } from "../../../common/constants/crewTypes";
import useDependencyApi from "../common/hooks/useDependencyApi";
import { TooltipContext, TooltipContextType } from "./context/TooltipContext";

type DependenciesChartProps = {
  baseId: string;
}

type CoordinateType = {
  x: number;
  y: number;
  name: string;
}

type CoordinateTypeDictionary = {
  [key: string]: CoordinateType;
}

export default function DependenciesChart({ baseId }: DependenciesChartProps) {

  const { user } = useContext(AuthContext) as AuthContextType;
  const { crewsQuery } = useCrewApi({ baseId, user })

  const { allDependenciesQuery } = useDependencyApi({ baseId, user });

  const [crewCoordinates, setCrewCoordinates] = useState<CoordinateTypeDictionary>({});

  const {setTooltip} = useContext(TooltipContext) as TooltipContextType;

  const dependencyClicked = (dependency: CrewDependencyType) => {
    setTooltip(<p>
        The relation between these two crews is: {dependency.relation}
      </p>
    )
  }


  useEffect(() => {
    if (crewsQuery.data) {
      const newCrewCoordinates: CoordinateTypeDictionary = {};
      crewsQuery.data.forEach((crew: DBCrewType, index: number) => {
        newCrewCoordinates[crew.id] = {
          x: 550 + 440 * Math.cos(2 * Math.PI * index / crewsQuery.data.length),
          y: 520 + 440 * Math.sin(2 * Math.PI * index / crewsQuery.data.length),
          name: crew.name
        }
      });
      console.log("newCrewCoordinates", newCrewCoordinates);
      setCrewCoordinates(newCrewCoordinates);
    }
  }, [crewsQuery.data])

  return (
    <>
      <Circle
        x={550}
        y={520}
        radius={440}
        stroke={"black"}
      />

      {allDependenciesQuery.data?.map((dependency: CrewDependencyType, index: number) => crewCoordinates[dependency.fromCrew] ? (
        <Shape
          onClick={() => dependencyClicked(dependency)}
          onTap={() => dependencyClicked(dependency)}
          key={`dep_${dependency.id}`}
          sceneFunc={(context, shape) => {
            context.beginPath();
            context.moveTo(crewCoordinates[dependency.fromCrew].x, crewCoordinates[dependency.fromCrew].y);
            context.quadraticCurveTo(500, 500, crewCoordinates[dependency.toCrew].x, crewCoordinates[dependency.toCrew].y);
            context.strokeShape(shape);
          }}
          stroke={"black"}
          strokeWidth={5}
        />
      ) : null)}


      {crewCoordinates && Object.keys(crewCoordinates).map((crewId: string) => (
        <Fragment key={`dep_crew_${crewId}`}>
          <Circle
            x={crewCoordinates[crewId].x}
            y={crewCoordinates[crewId].y}
            radius={50}
            stroke={"black"}
            fill={crew_type_attributes[crewsQuery.data.find((crew: DBCrewType) => crew.id === crewId)?.type].color}
          />
          <Text
            x={crewCoordinates[crewId].x - crewCoordinates[crewId].name.length * 15 / 2}
            y={crewCoordinates[crewId].y - 70}
            width={crewCoordinates[crewId].name.length * 13}
            height={20}
            fontSize={20}
            fill="black"
            text={crewCoordinates[crewId].name}
            align="center"
            verticalAlign="center"
          />
        </Fragment>
      ))}

    </>
  );
}