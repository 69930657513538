import { Link, useParams } from "react-router-dom";
import CrewDetails from "../components/baseEditor/editor/crew/CrewDetails";
import Spinner from "../components/baseList/Spinner";
import Breadcrumbs from "../components/baseEditor/common/Breadcrumbs";
import { useContext } from "react";
import { AuthContext, AuthContextType } from "../context/AuthContext";
import useIsAuthenticated from "../hooks/useIsAuthenticated";
import usePaidAccountRequired from "../hooks/usePaidAccountRequired";
import { ArrowsPointingOutIcon, ListBulletIcon, RectangleGroupIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { Tab } from "@headlessui/react";
import TabLayout from "../components/layout/TabLayout";

export default function CrewEditor() {

  const { baseId, crewId } = useParams();

  const { user } = useContext(AuthContext) as AuthContextType;

  useIsAuthenticated();
  usePaidAccountRequired(baseId);

  return (
    <div className="w-full h-screen p-2">
      <Breadcrumbs />
      {user && <>
        {!(baseId && crewId) && <Spinner width="w-8" height="h-8" border="border-2" />}
        {baseId && crewId && <TabLayout baseId={baseId} firstLabel="Crew">
          <CrewDetails baseId={baseId} crewId={crewId} />
        </TabLayout>}
      </>}
    </div>
  );
}
