import { useContext } from "react";
import { AuthContext, AuthContextType } from "../context/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form, Formik, Field, ErrorMessage } from "formik";

type ResetPasswordType = {
  password: string;
  repeat_password: string;
}

type ResetPasswordErrorType = {
  password?: string;
  repeat_password?: string;
}

export default function ResetPassword() {
  const { user, resetPassword } = useContext(AuthContext) as AuthContextType;
  const navigate = useNavigate();
  const [searchParams, getSearchParams] = useSearchParams();

  const initialValues: ResetPasswordType = {
    password: "",
    repeat_password: "",
  };

  return (
    <div className="w-full flex justify-center">
      <div className="flex flex-col py-2 md:px-2 w-full md:w-1/2 lg:w-1/3 m-2 p-2">
        <div className="text-2xl">Reset password</div>
        <Formik
          initialValues={initialValues}
          validate={values => {
            const errors: ResetPasswordErrorType = {};
            if (!values.password) {
              errors.password = 'Password is required';
            }
            if (!values.repeat_password || values.repeat_password !== values.password) {
              errors.repeat_password = 'Passwords must match';
            }
            return errors;
          }}
          onSubmit={(values, actions) => {
            const oobCode = searchParams.get("oobCode");
            if (oobCode) {
              resetPassword(oobCode, values.password).then(() => {
                console.log("new password saved")
                navigate("/sign_in");
              }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
              });;
            }
            actions.setSubmitting(false);
            actions.resetForm();
          }}
        >
          <Form className="flex flex-col border-t mt-2 pt-2">
            <div className="flex flex-col">
              <label htmlFor="password">Password</label>
              <Field type="password" name="password" className="border rounded-md p-1 my-2" />
              <ErrorMessage className="text-red-700" name="password" component="div" />
            </div>
            <div className="flex flex-col">
              <label htmlFor="repeat_password">Confirm password</label>
              <Field type="password" name="repeat_password" className="border rounded-md p-1 my-2" />
              <ErrorMessage className="text-red-700" name="repeat_password" component="div" />
            </div>
            <button
              type="submit"
              className="p-2 px-4 my-4 bg-teal-500 text-white rounded-md"
            >
              Save
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  );
}