import { Group, Image, Rect, Text } from "react-konva";
import { MeasurementType, DBCrewType, FetchedBaseType, TeamMemberType } from "../../../../common/types";
import useImage from "use-image";

import { crew_type_attributes } from "../../../../common/constants/crewTypes";
import { useContext, useEffect, useRef, useState } from "react";

import Konva from "konva";
import { AuthContext, AuthContextType } from "../../../../context/AuthContext";
import { getCrewMembers } from "../../../../api/crewMemberApi";
import { useQuery } from "react-query";
import HorizontalCrewMembers from "./HorizontalCrewMembers";
import InvestmentHorizonBadge from "./InvestmentHorizonBadge";
import CrewLifecycleStage from "./CrewLifecycleStage";
import useCrewApi from "../../common/hooks/useCrewApi";
import TeamingOptionBadge from "./TeamingOptionBadge";
import { TooltipContext, TooltipContextType } from "../context/TooltipContext";
import { KonvaEventObject } from "konva/lib/Node";

type CrewElementProps = {
  crew: DBCrewType;
  baseId: string;
  base: FetchedBaseType;
  handleMemberHover: (e: KonvaEventObject<MouseEvent>, member: TeamMemberType) => void;
  handleMemberHoverOut: () => void;
};

export default function HorizontalCrewElement({
  crew,
  baseId,
  base,
  handleMemberHover,
  handleMemberHoverOut,
}: CrewElementProps) {
  const { user } = useContext(AuthContext) as AuthContextType;
  
  const {setTooltip} = useContext(TooltipContext) as TooltipContextType;

  const onElementClicked = (e: any) => {
    setTooltip(<p>
      This element is a(n) <a target="_blank" href={`${crew_type_attributes[ crew.type ]["link"]}`} className="text-teal-500">
        {`${crew_type_attributes[ crew.type ]["label"]}`}
      </a></p>
    )
  }

  const [isTransforming, setTransforming] = useState(false);

  const {editCrew} = useCrewApi({baseId: base.id, user, crewId: crew.id});

  const crewMembersQuery = useQuery(["crew_members", baseId, crew.id],
    () => getCrewMembers({ user, baseId, crewId: crew.id, groupBy: "" }),
    { enabled: !!user && !!baseId && !!crew.id });

  // icon
  const iconFile = crew_type_attributes[crew.type]["icon"];
  const [icon] = useImage(`/unfix/icons/crew/${iconFile}`);

  // transformer
  const shapeRef = useRef<any>();

  const [investmentHorizonSize, setInvestmentHorizonSize] = useState<number>(0);
  const [lifecycleStageSize, setLifecycleStageSize] = useState<number>(0);
  const [teamingOptionSize, setTeamingOptionSize] = useState<number>(0);
  const [sumPaddingLeft, setSumPaddingLeft] = useState<number>(0);

  
  useEffect(() => {
    if (crew.investmentHorizons?.length) {
      setInvestmentHorizonSize(crew.investmentHorizons.length * 40);
    } else {
      setInvestmentHorizonSize(0);
    }

    if (crew.lifecycleStage) {
      setLifecycleStageSize(50);
    } else {
      setLifecycleStageSize(0);
    }

    if (crew.teamingOption) {
      setTeamingOptionSize(50);
    } else {
      setTeamingOptionSize(0);
    }

    setSumPaddingLeft(investmentHorizonSize + lifecycleStageSize + teamingOptionSize);
  });

  return (
      <Group
        x={crew.measurements.x}
        y={crew.measurements.y}
        width={crew.measurements.width}
        height={crew.measurements.height}
        name="chart_element crew_element"
        id={`crew_${crew.id}`}
        draggable
        onClick={onElementClicked}
        onTap={onElementClicked}
        ref={shapeRef}
        onDragEnd={(e) => {
          const canvas_width = base.display_options?.canvas_size.width ?? 1280;
          const canvas_height = base.display_options?.canvas_size.height ?? 720;
          let newX = (e.target.x() < 0) ? 50 : (e.target.x() > canvas_width - 50) ? canvas_width - 50 : e.target.x();
          let newY = (e.target.y() < 0) ? 50 : (e.target.y() > canvas_height - 50) ? canvas_height - 50 : e.target.y();

          const measurements: MeasurementType = {
            x: newX,
            y: newY,
            width: crew.measurements.width,
            height: crew.measurements.height,
          };

          editCrew({
            ...crew,
            measurements,
          });
        }}
        onTransformStart={(e) => {
          setTransforming(true);
        }}
        onTransformEnd={(e) => {
          setTransforming(false);
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node: Konva.Group = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);

          const measurements: MeasurementType = {
            x: node.x(),
            y: node.y(),
            width: crew.measurements.width * scaleX,
            height: crew.measurements.height * scaleY,
          };

          editCrew({
            ...crew,
            measurements,
          });
        }}
      >
        <Rect
          x={0}
          y={0}
          width={crew.measurements.width}
          height={crew.measurements.height}
          fill={crew_type_attributes[crew.type]["color"]}
          cornerRadius={20}
          stroke={"black"}
        />
        {crew.measurements.width > (300 + sumPaddingLeft) &&
          !!crew.investmentHorizons?.length && crew.investmentHorizons.map((horizon: number, index: number) =>
            <InvestmentHorizonBadge
              key={`${crew.id}_${horizon}`}
              horizon={horizon}
              x={10 + (index * 40)}
              y={crew.measurements.height / 2 - 20}
            />
          )
        }
        {!!crew.lifecycleStage && crew.measurements.width > (300 + sumPaddingLeft) &&
          <CrewLifecycleStage
            x={10 + investmentHorizonSize + 10 + 20}
            y={crew.measurements.height / 2}
            lifecycleStage={crew.lifecycleStage}
          />
        }
        {!!crew.teamingOption && crew.measurements.width > (300 + sumPaddingLeft) &&
          <TeamingOptionBadge
            x={10 + investmentHorizonSize + 10 + lifecycleStageSize}
            y={crew.measurements.height / 2 - 20}
            teamingOption={crew.teamingOption}
          />
        }
        <Text
          x={crew.measurements.width > (300 + sumPaddingLeft) ? sumPaddingLeft + 30 : 20}
          y={(crew.measurements.height / 2) - 10}
          width={crew.measurements.width > (300 + sumPaddingLeft) ? crew.measurements.width - sumPaddingLeft - 60 : crew.measurements.width - 60}
          height={20}
          fontSize={20}
          fill={"black"}
          text={crew.name}
        />
        {
          crew.measurements.width > (450 + investmentHorizonSize + lifecycleStageSize) && crewMembersQuery.isSuccess && !isTransforming && (
            <HorizontalCrewMembers
              baseX={90}
              crew={crew}
              members={crewMembersQuery.data}
              handleMemberHover={handleMemberHover}
              handleMemberHoverOut={handleMemberHoverOut}
            />
          )
        }
        {crew.measurements.width > 200 &&
          <Image
            image={icon}
            alt=""
            x={crew.measurements.width - 60}
            y={crew.measurements.height / 2 - 20}
            width={40}
            height={40}
          />
        }
      </Group>
  );
}
